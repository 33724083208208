import React from "react";
import { useSelector } from "react-redux";

import GalleryCard from "../components/cards/GalleryCard";
import ArtMasonry from "../components/layout/ArtMasonry";
import urls from "../utility/urls";
import GalleryDetail from "../components/details/GalleryDetail";

export default function MyGalleries() {
  const user = useSelector((state) => state.user);

  return (
    <div style={{ display: "block", width: "100%" }}>
      <ArtMasonry
        title="My Galleries"
        url={urls.gallery(null, { creator: user.id })}
        objectType={GalleryCard}
        modalType={GalleryDetail}
        owner
        gridEdit
      />
    </div>
  );
}
