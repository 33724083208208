import React, { useMemo, useState, useEffect } from "react";
import { Typography, Card, Image, Popconfirm } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  EyeOutlined,
  PushpinOutlined,
} from "@ant-design/icons";
import formatter from "../../utility/formatter";
import urls from "../../utility/urls";
import ArtworkFormModal from "../modals/ArtworkFormModal";
import ArtistFormModal from "../modals/ArtistFormModal";
import api from "../../utility/api";
import SmartGallery from "../layout/react-smart-gallery/SmartGallery";
import TagFormModal from "../modals/TagFormModal";
import NationalityFormModal from "../modals/NationalityFormModal";
import LocationFormModal from "../modals/LocationFormModal";

const { Text } = Typography;

export default function EditCard(props) {
  const [images, setImages] = useState([]);
  const [count, setCount] = useState(0);

  const getTitle = () => {
    if (props.options.model === "Artwork") {
      return formatter.title(props.data.title, props.data.year);
    } else if (props.options.model === "Artist") {
      return formatter.artist(props.data);
    } else if (props.options.model === "Tag") {
      return props.data.description;
    } else if (props.options.model === "Nationality") {
      return props.data.name;
    } else if (props.options.model === "Location") {
      return formatter.location(props.data);
    } else {
      return "Not Implemented";
    }
  };

  const getSubTitle = () => {
    if (props.options.model === "Artwork") {
      return formatter.artist(props.data.artist);
    } else if (props.options.model === "Artist") {
      return props.data.nationality ? props.data.nationality.name : null;
    } else if (props.options.model === "Tag") {
      return props.data.typename;
    } else {
      return null;
    }
  };

  const getImage = () => {
    if (props.options.model === "Artwork") {
      return (
        <Image
          className="image"
          alt={props.data.title}
          src={formatter.selectImage(props.data.images, "thumbnail")}
        />
      );
    } else if (props.options.model === "Artist" && props.data.photo) {
      return (
        <Image className="image" alt={props.data.name} src={props.data.photo} />
      );
    } else {
      return (
        <>
          {images.length > 0 && (
            <SmartGallery
              total={count}
              width="100%"
              height={450}
              images={images}
              onLoad={() => props.updateGrid()}
            />
          )}
        </>
      );
    }
  };

  const getEditButton = () => {
    if (props.options.model === "Artwork") {
      return (
        <ArtworkFormModal
          initial={props.data}
          updateArtwork={props.updateObject}
        />
      );
    } else if (props.options.model === "Artist") {
      return (
        <ArtistFormModal
          title="Edit Artist"
          initial={props.data}
          updateObject={props.updateObject}
        />
      );
    } else if (props.options.model === "Tag") {
      return (
        <TagFormModal
          title="Edit Tag"
          initial={props.data}
          updateObject={props.updateObject}
        />
      );
    } else if (props.options.model === "Nationality") {
      return (
        <NationalityFormModal
          title="Edit Nationality"
          initial={props.data}
          updateObject={props.updateObject}
        />
      );
    } else if (props.options.model === "Location") {
      return (
        <LocationFormModal
          title="Edit Location"
          initial={props.data}
          updateObject={props.updateObject}
        />
      );
    }
  };

  const viewObject = () => {
    if (props.options.model === "Artwork") {
      urls.openInNewTab(urls.getLink("/artworks", props.data.id));
    } else if (props.options.model === "Artist") {
      urls.openInNewTab(urls.getLink("/artists", props.data.id));
    } else if (props.options.model === "Tag") {
      urls.openInNewTab(urls.getLink("/tags", props.data.id));
    } else if (props.options.model === "Nationality") {
      urls.openInNewTab(urls.getLink("/nationalities", props.data.id));
    } else if (props.options.model === "Location") {
      urls.openInNewTab(urls.getLink("/locations", props.data.id));
    }
  };

  const selected = useMemo(() => {
    return props.options.selected.some((obj) => obj.id === props.data.id);
  }, [props.options.selected, props.data.id]);

  useEffect(() => {
    if (
      ["Artist", "Tag", "Nationality", "Location"].includes(props.options.model)
    ) {
      api
        .get(
          urls.artwork(null, {
            [props.options.model.toLowerCase()]: props.data.id,
          })
        )
        .then((response) => {
          if (response) {
            setCount(response.count);
            setImages(formatter.galleryImages(response.results));
          }
        });
    }
  }, [props.data.id, props.options.model]);

  return (
    <div
      style={selected ? { border: "3px solid #fa3c3c" } : null}
      className="card scaler"
    >
      <div
        style={{
          background: "black",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        {getImage()}
      </div>
      <Card
        actions={[
          getEditButton(),
          selected ? (
            <CloseOutlined
              onClick={() => props.options.updateSelected(props.data, false)}
            />
          ) : (
            <CheckOutlined
              onClick={() => props.options.updateSelected(props.data, true)}
            />
          ),
          <EyeOutlined onClick={() => viewObject()} />,
          props.options.selected.length > 1 && (
            <Popconfirm
              title="Merge all selections here?"
              onConfirm={() => props.options.submitMerge(props.data.id)}
            >
              <PushpinOutlined />
            </Popconfirm>
          ),
        ].filter(Boolean)}
      >
        <div className="text-box">
          <Text className="main-text">{getTitle()}</Text>
          <Text className="sub-text">{getSubTitle()}</Text>
        </div>
      </Card>
    </div>
  );
}
