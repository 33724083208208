import React, { useState, useMemo, useEffect } from "react";
import { animated, useSpring } from "react-spring";
import { useHistory, useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Button,
  Select,
  Input,
  InputNumber,
  Form,
  Switch,
  Modal,
  Progress,
  message,
} from "antd";
import Masonry from "react-masonry-component";

import ImageUploadCard from "../components/cards/ImageUploadCard";
import ArtSelect from "../components/layout/ArtSelect";
import urls from "../utility/urls";
import api from "../utility/api";

const { Option } = Select;
const FILETYPES = ["image/jpeg", "image/png"];
const FIELDS = [
  "none",
  "title",
  "year",
  "location",
  "dimensions",
  "description",
  "license_type",
  "nudity",
  "public",
  "tags",
  "artist",
  "source",
];
var completedProgress = 0;

export default function Create() {
  const user = useSelector((state) => state.user);
  const [objects, setObjects] = useState([]);
  const [dragging, setDragging] = useState(0);
  const [edit, setEdit] = useState(0);
  const [value, setValue] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [mode, setMode] = useState(null);
  let history = useHistory();
  let location = useLocation();

  const border = useSpring({
    display: objects.length === 0 ? "flex" : "block",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderWidth: "1px",
    borderStyle: dragging > 0 ? "solid" : "dashed",
    borderColor: "#fa3c3c",
    boxShadow:
      dragging > 0 ? "0px 0px 5px 5px #fa3c3c" : "0px 0px 0px 0px #fa3c3c",
    minHeight: "80vh",
    paddingBottom: "15px",
    config: { duration: 75 },
  });

  const cards = useMemo(() => {
    return objects.map((obj, i) => (
      <div key={i} className="outer-card grid-sizer">
        <ImageUploadCard
          file={obj}
          removeFile={() => removeFile(i)}
          editFile={(data) => editFile(i, data)}
          mode={mode}
        />
      </div>
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objects, mode]);

  const totalProgress = useMemo(() => {
    return objects.reduce((a, b) => (a = a + b.file.size), 0);
  }, [objects]);

  const handleUpload = (files) => {
    var temp = [...objects];
    for (var file of files) {
      if (FILETYPES.includes(file.type)) {
        temp.push({
          file: file,
          preview: URL.createObjectURL(file),
          title: file.name.replace(/\.[^/.]+$/, ""),
          year: new Date(file.lastModified).getFullYear(),
          artist: mode === "artist" ? { id: user.artist, name: "You" } : null,
          source: mode === "source" ? { id: user.source, name: "You" } : null,
          nudity: false,
          tags: [],
          location: null,
          public: true,
          dimensions: null,
          description: null,
          license_type: mode === "basic" ? "P" : "CC0",
          selected: false,
        });
      }
    }
    setObjects(temp);
  };

  const openBrowser = () => {
    var input = document.createElement("input");
    input.type = "file";
    input.multiple = true;
    input.accept = FILETYPES.join();
    input.onchange = (e) => handleUpload(e.target.files);
    input.click();
  };

  const removeFile = (id) => {
    setObjects(objects.filter((item, i) => i !== id));
  };

  const editFile = (id, data) => {
    var temp = [...objects];
    temp[id] = data;
    setObjects(temp);
  };

  const quickEdit = (all) => {
    var item;
    var result;
    var temp = [...objects];
    var field = FIELDS[edit];

    if (value === "") {
      if (field === "title") {
        message.error("Please provide a title");
        return;
      }
    }

    if (value === null) {
      if (field === "title") {
        message.error("Please provide a title");
        return;
      } else if (field === "year") {
        result = parseInt(new Date().getFullYear());
      } else if (field === "license_type") {
        result = "CC0";
      } else if (field === "nudity") {
        result = false;
      } else if (field === "public") {
        result = true;
      }
    } else {
      result = value;
    }

    if (all) {
      for (item of temp) {
        item[field] = result;
      }
    } else {
      for (item of temp) {
        if (item.selected) {
          item[field] = result;
        }
      }
    }
    setObjects(temp);
    message.success("Field updated");
  };

  const getSelectedCount = () => {
    return objects.filter((item) => item.selected).length;
  };

  const changeSelection = (selection) => {
    var item;
    var temp = [...objects];

    for (item of temp) {
      item.selected = selection;
    }
    setObjects(temp);
  };

  const progressUpdate = (event) => {
    setUploadProgress(
      Math.ceil(
        Math.min(((completedProgress + event.loaded) / totalProgress) * 100),
        100
      )
    );
  };

  const submit = async () => {
    setUploadProgress(1);
    var count = 0;
    for await (var item of objects) {
      var formData = new FormData();
      formData.append("title", item.title);
      formData.append("year", item.year === 0 ? null : item.year);
      formData.append("artist", item.artist ? item.artist.id : null);
      formData.append("source", item.source ? item.source.id : null);
      formData.append("location", item.location ? item.location.id : null);
      formData.append("dimensions", item.dimensions);
      formData.append("description", item.description);
      formData.append("license_type", item.license_type);
      formData.append("nudity", item.nudity);
      formData.append("public", item.public);
      formData.append(
        "tags",
        item.tags.map((obj) => obj.id)
      );
      formData.append("file", item.file);
      var response = await api.post(urls.artwork(), formData, progressUpdate);
      if (response) {
        completedProgress += item.file.size;
        count++;
      } else {
        setUploadStatus(false);
        setObjects(objects.slice(count));
        return;
      }
    }
    setUploadStatus(true);
  };

  useEffect(() => {
    if (!user.artist && !user.source && !user.admin) {
      history.push("myaccount/artworks");
    }

    var search = urls.getParams(location.search);
    if (search.mode) {
      if (search.mode === "source") {
        if (user.source) {
          setMode("source");
        } else {
          setMode("select");
        }
      } else if (search.mode === "admin") {
        if (user.admin) {
          setMode("admin");
        } else {
          setMode("select");
        }
      } else if (search.mode === "artist") {
        if (user.artist) {
          setMode("artist");
        } else {
          setMode("select");
        }
      } else {
        setMode("select");
      }
    } else {
      if (user.artist) {
        setMode("select");
      } else {
        setMode("select");
      }
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <div
      className="flex-row-collapse"
      style={{
        width: "100%",
        padding: "15px",
        marginTop: "15px",
      }}
    >
      <animated.div
        className="flex-row-container-lg"
        style={border}
        onDrop={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setDragging(0);
          if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleUpload(e.dataTransfer.files);
          }
        }}
        onDragOver={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onDragEnter={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setDragging(dragging + 1);
          }
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setDragging(dragging - 1);
        }}
      >
        {objects.length === 0 && (
          <>
            <div className="sub-text">Drag and drop images here </div>
            <div style={{ height: "20px" }} />
            <div className="sub-text">or</div>
            <div style={{ height: "20px" }} />
          </>
        )}
        <Masonry
          disableImagesLoaded={false}
          updateOnEachImageLoad={true}
          options={{ transitionDuration: "0.2s", percentPosition: true }}
        >
          <div className="grid-sizer"></div>
          {cards}
        </Masonry>
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button type="primary" shape="round" onClick={openBrowser}>
            Upload Files
          </Button>
        </div>
      </animated.div>
      <div
        className="flex-row-container-sm"
        style={{ border: "1px solid #fa3c3c", padding: "15px" }}
      >
        <Button type="primary" style={{ width: "100%" }} onClick={openBrowser}>
          Upload Files
        </Button>
        {objects.length > 0 && (
          <>
            <div
              className="footer-border"
              style={{ marginBottom: "25px", marginTop: "25px" }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {objects.length !== getSelectedCount() && (
                <Button
                  type="primary"
                  style={{ width: getSelectedCount() === 0 ? "100%" : "45%" }}
                  onClick={() => changeSelection(true)}
                >
                  Select All
                </Button>
              )}
              {getSelectedCount() !== 0 && (
                <Button
                  type="primary"
                  style={{
                    width:
                      getSelectedCount() === objects.length ? "100%" : "45%",
                  }}
                  onClick={() => changeSelection(false)}
                >
                  Unselect All
                </Button>
              )}
            </div>
            <div
              className="footer-border"
              style={{ marginBottom: "25px", marginTop: "25px" }}
            />
            <div className="sub-text">Quick Edit</div>
            <Select
              style={{ width: "100%" }}
              value={edit}
              onChange={(val) => {
                setEdit(val);
                setValue(null);
              }}
              virtual={false}
            >
              <Option value={0}>Select a field to edit</Option>
              <Option value={1}>Title</Option>
              <Option value={2}>Year</Option>
              <Option value={3}>Location</Option>
              <Option value={4}>Dimensions</Option>
              <Option value={5}>Description</Option>
              {mode !== "basic" && <Option value={6}>Licensing</Option>}
              <Option value={7}>Nudity</Option>
              {mode !== "basic" && mode !== "admin" && (
                <Option value={8}>Public</Option>
              )}
              <Option value={9}>Tags</Option>
              {mode !== "artist" && <Option value={10}>Artist</Option>}
              {mode === "admin" && <Option value={11}>Source</Option>}
            </Select>
            <div style={{ marginTop: "15px" }}>
              {edit === 1 && (
                <Form.Item label="Title">
                  <Input
                    onChange={(e) => setValue(e.target.value)}
                    placeholder="Enter a Title"
                    maxLength={256}
                  />
                </Form.Item>
              )}
              {edit === 2 && (
                <Form.Item label="Year">
                  <InputNumber
                    defaultValue={parseInt(new Date().getFullYear())}
                    onChange={setValue}
                  />
                </Form.Item>
              )}
              {edit === 3 && (
                <Form.Item label="Location">
                  <ArtSelect
                    name="location"
                    title="Location"
                    display="location"
                    url={urls.location()}
                    search="name"
                    updateValue={(key, val) => setValue(val)}
                  />
                </Form.Item>
              )}
              {edit === 4 && (
                <Form.Item label="Dimensions">
                  <Input
                    placeholder="Enter Dimensions"
                    onChange={(e) => setValue(e.target.value)}
                    maxLength={128}
                  />
                </Form.Item>
              )}
              {edit === 5 && (
                <Form.Item label="Description">
                  <Input.TextArea
                    maxLength={2000}
                    placeholder="Enter a Description"
                    onChange={(e) => setValue(e.target.value)}
                  />
                </Form.Item>
              )}
              {edit === 6 && (
                <Form.Item label="Licensing">
                  <Select defaultValue="CC0" onChange={setValue}>
                    <Option value="CC0">Creative Commons</Option>
                    <Option value="RF">Royalty Free</Option>
                    <Option value="P">Public Domain</Option>
                    <Option value="E">Editorial Use</Option>
                  </Select>
                </Form.Item>
              )}
              {edit === 7 && (
                <Form.Item label="Nudity">
                  <Switch defaultChecked={false} onChange={setValue} />
                </Form.Item>
              )}
              {edit === 8 && (
                <Form.Item label="Public">
                  <Switch
                    defaultChecked={true}
                    onChange={(val) => {
                      setValue(val);
                      if (!val) {
                        message.warn(
                          "WARNING: Private Artworks are currently free, but will require payment in a future update",
                          5
                        );
                      }
                    }}
                  />
                </Form.Item>
              )}
              {edit === 9 && (
                <Form.Item label="Tags">
                  <ArtSelect
                    name="tags"
                    title="Tags"
                    display="description"
                    url={urls.tag()}
                    updateValue={(key, val) => setValue(val)}
                    search="name"
                    many
                  />
                </Form.Item>
              )}
              {edit === 10 && (
                <Form.Item label="Artist">
                  <ArtSelect
                    name="artist"
                    title="Artist"
                    display="name"
                    url={urls.artist(null, { status: "selectable" })}
                    search="name"
                    updateValue={(key, val) => setValue(val)}
                    create
                  />
                </Form.Item>
              )}
              {edit === 11 && (
                <Form.Item label="Source">
                  <ArtSelect
                    name="source"
                    title="Source"
                    display="name"
                    url={urls.source()}
                    search="name"
                    updateValue={(key, val) => setValue(val)}
                  />
                </Form.Item>
              )}
              {edit > 0 && (
                <div
                  style={{
                    marginTop: "15px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {getSelectedCount() > 0 && (
                    <Button
                      type="primary"
                      style={{ width: "45%" }}
                      onClick={() => quickEdit(false)}
                    >
                      Apply to Selected
                    </Button>
                  )}
                  <Button
                    type="primary"
                    style={{ width: getSelectedCount() > 0 ? "45%" : "100%" }}
                    onClick={() => quickEdit(true)}
                  >
                    Apply to All
                  </Button>
                </div>
              )}
            </div>
            <div
              className="footer-border"
              style={{ marginBottom: "25px", marginTop: "25px" }}
            />
          </>
        )}
        {objects.length > 0 && (
          <Button type="primary" style={{ width: "100%" }} onClick={submit}>
            Submit Artwork
          </Button>
        )}
        <Modal
          visible={uploadProgress > 0}
          title="Uploading Artwork"
          closable={false}
          footer={null}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Progress
              percent={Math.min(uploadProgress, 99)}
              type="circle"
              status={
                uploadStatus === false
                  ? "exception"
                  : uploadStatus
                  ? "success"
                  : "normal"
              }
            />
            <div style={{ height: "20px" }} />
            {uploadStatus && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Button
                  type="primary"
                  style={{ width: "45%" }}
                  onClick={() => {
                    if (mode === "admin" || mode === "basic") {
                      history.push("/artworks/?ordering=latest");
                    } else if (mode === "source") {
                      history.push("/myaccount/curations");
                    } else {
                      history.push("/myaccount/artworks");
                    }
                  }}
                >
                  View Artwork
                </Button>
                <Button
                  type="primary"
                  style={{ width: "45%" }}
                  onClick={() => {
                    setUploadProgress(0);
                    setObjects([]);
                    setUploadStatus(null);
                    completedProgress = 0;
                  }}
                >
                  Upload Another
                </Button>
              </div>
            )}
            {uploadStatus === false && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Button
                  type="primary"
                  style={{ width: "45%" }}
                  onClick={() => {
                    setUploadProgress(0);
                    setUploadStatus(null);
                    completedProgress = 0;
                    submit();
                  }}
                >
                  Retry Remaining
                </Button>
                <Button
                  type="primary"
                  style={{ width: "45%" }}
                  onClick={() => {
                    setUploadProgress(0);
                    setUploadStatus(null);
                    completedProgress = 0;
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
            <div
              className="footer-border"
              style={{ width: "100%", marginTop: "15px", marginBottom: "15px" }}
            />
            <div className="sub-description">
              Reminder: All public uploads will be tagged for review and will be
              hidden to other users until approved.
            </div>
          </div>
        </Modal>
      </div>
      <Modal
        visible={mode === "select"}
        footer={null}
        title="Select Upload Mode"
        bodyStyle={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        closable={false}
      >
        <Button type="primary" onClick={() => setMode("basic")}>
          Upload Public Domain Artwork
        </Button>
        {user.artist && (
          <>
            <div style={{ height: "15px" }} />
            <Button type="primary" onClick={() => setMode("artist")}>
              Upload Your Artwork
            </Button>
          </>
        )}
        {user.source && (
          <>
            <div style={{ height: "15px" }} />
            <Button type="primary" onClick={() => setMode("source")}>
              Upload As Curator
            </Button>
          </>
        )}
        {user.admin && (
          <>
            <div style={{ height: "15px" }} />
            <Button type="primary" onClick={() => setMode("admin")}>
              Upload As Admin
            </Button>
          </>
        )}
        {!user.artist && (
          <>
            <div
              className="footer-border"
              style={{ width: "100%", marginTop: "15px" }}
            />
            <div className="sub-description">
              Sign up to upload your own artwork{" "}
              <Link to="/myaccount/artworks">
                <Button type="link" style={{ paddingLeft: 0 }}>
                  here
                </Button>
              </Link>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
}
