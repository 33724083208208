import React, { useState, useEffect } from "react";
import { animated, useSpring } from "react-spring";
import {
  Button,
  Typography,
  Tag,
  Modal,
  Form,
  Input,
  message,
  Checkbox,
} from "antd";
import { FilterOutlined, LeftOutlined } from "@ant-design/icons";

import FilterDropdown from "./FilterDropdown";
import urls from "../../utility/urls";
import api from "../../utility/api";

const { Text } = Typography;

export default function FilterDrawer(props) {
  const [visible, setVisible] = useState(false);
  const [filters, setFilters] = useState([]);
  const [form] = Form.useForm();

  const overlay = useSpring({
    opacity: visible ? 1 : 0,
    display: visible ? "flex" : "none",
    zIndex: 10,
  });
  const drawer = useSpring({ left: visible ? "0%" : "-100%" });

  const getFilterObject = (filterArr) => {
    var obj = {};
    for (var param of filterArr) {
      var name = param.name.replace(/\d/g, "");
      if (name in obj) {
        obj[name] = obj[name] + `,${param.value}`;
      } else {
        obj[name] = param.value;
      }
    }
    return obj;
  };

  const removeFilter = (name) => {
    var tempFilter = filters.filter((obj) => obj.name !== name);
    setFilters(tempFilter);
    props.updateQuery(getFilterObject(tempFilter));
  };

  const updateFilter = (name, value, display) => {
    var tempFilter;
    if (value === null) {
      // filter has been removed from dropdown
      tempFilter = filters.filter((obj) => obj.name !== name);
      setFilters(tempFilter);
    } else {
      // check if an existing filter exists
      tempFilter = [...filters];
      var filterExists = false;
      for (var obj of tempFilter) {
        if (obj.name === name) {
          // update existing filter
          obj.value = value;
          obj.display = display;
          filterExists = true;
        }
      }
      if (!filterExists) {
        // create a new filter
        tempFilter.push({ name: name, value: value, display: display });
      }
      setFilters(tempFilter);
    }
  };

  const onFinish = () => {
    form.validateFields().then((values) => {
      api
        .post(urls.gallery(), {
          name: values.name,
          filters: getFilterObject(filters),
          public: values.public,
        })
        .then((response) => {
          if (response) {
            message.success("Gallery Created");
            props.setGalleryCreate(false);
          }
        });
    });
  };

  useEffect(() => {
    if (props.query) {
      // get filters from parent
      for (var query of Object.entries(props.query)) {
        if (query[0] === "search") {
          updateFilter(
            query[0],
            query[1],
            `${query[0].charAt(0).toUpperCase() + query[0].slice(1)}: ${
              query[1]
            }`
          );
        }
        if (query[0] === "nudity" && query[1] === "true") {
          updateFilter(query[0], query[1], "Nudity: Only Nudity");
        }
        if (query[0] === "ordering" && query[1] === "latest") {
          updateFilter(query[0], query[1], "Sorting: Most Recent");
        }
      }
    }
    // eslint-disable-next-line
  }, [props.query]);

  useEffect(() => {
    if (!visible) {
      // only set filters when filter drawer closes
      props.updateQuery(getFilterObject(filters));
    }
    // eslint-disable-next-line
  }, [visible]);

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Modal
          visible={props.galleryCreate}
          title="Create Gallery"
          onCancel={() => props.setGalleryCreate(false)}
          onOk={onFinish}
        >
          {filters.map((obj, i) => (
            <Tag
              visible={true}
              key={i}
              closable
              onClose={() => removeFilter(obj.name)}
              style={{ marginBottom: "5px" }}
            >
              {obj.display}
            </Tag>
          ))}
          <Form form={form} style={{ marginTop: "25px" }}>
            <Form.Item name="name" required label="Gallery Name">
              <Input maxLength={128} />
            </Form.Item>
            <Form.Item label="Public" name="public" valuePropName="checked">
              <Checkbox style={{ marginTop: "5px" }} />
            </Form.Item>
          </Form>
        </Modal>
        <Button
          icon={<FilterOutlined style={{ fontSize: "16" }} />}
          type="primary"
          onClick={() => setVisible(true)}
          style={{ margin: "20px" }}
        >
          Filters
        </Button>
        <div style={{ marginTop: "auto", marginBottom: "auto" }}>
          {filters.map((obj, i) => (
            <Tag
              visible={true}
              key={i}
              closable
              onClose={() => removeFilter(obj.name)}
              style={{ marginBottom: "5px" }}
            >
              {obj.display}
            </Tag>
          ))}
          {filters.length > 0 && (
            <Button
              type="link"
              onClick={() => {
                setFilters([]);
                props.updateQuery({});
              }}
            >
              Clear All
            </Button>
          )}
        </div>
      </div>
      <animated.div
        onClick={() => setVisible(false)}
        className="drawer-overlay"
        style={overlay}
      />
      <animated.div className="drawer background" style={drawer}>
        <Button
          type="primary"
          onClick={() => setVisible(false)}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            height: "95px",
          }}
        >
          <FilterOutlined style={{ fontSize: "20px" }} />
          <Text className="special-text light">Filters</Text>
          <LeftOutlined style={{ fontSize: "22px" }} />
        </Button>
        <div style={{ overflowY: "auto", width: "100%", height: "100%" }}>
          {props.options.model === "Artwork" && (
            <>
              <FilterDropdown
                title="Full-Text Search"
                name="search"
                type="text"
                default={null}
                filters={filters}
                updateFilter={updateFilter}
              />
              <FilterDropdown
                title="Title"
                name="title"
                type="text"
                default={null}
                filters={filters}
                updateFilter={updateFilter}
              />
              <FilterDropdown
                title="Artist"
                name="artist"
                type="list"
                url={urls.artist()}
                default={[]}
                filters={filters}
                updateFilter={updateFilter}
              />
              <FilterDropdown
                title="Nationality"
                name="nationality"
                type="list"
                url={urls.nationality()}
                default={[]}
                filters={filters}
                updateFilter={updateFilter}
              />
              <FilterDropdown
                title="Sorting"
                name="ordering"
                type="radio"
                default="default"
                options={[
                  { title: "Best Match", value: "default" },
                  { title: "Most Recent", value: "latest" },
                  { title: "Most Popular", value: "popular" },
                  { title: "Alphabetical", value: "title" },
                ]}
                filters={filters}
                updateFilter={updateFilter}
              />
              <FilterDropdown
                title="Year"
                name="year"
                type="slider"
                default="0"
                filters={filters}
                updateFilter={updateFilter}
              />
              <FilterDropdown
                title="Location"
                name="location"
                type="list"
                url={urls.location()}
                default={[]}
                filters={filters}
                updateFilter={updateFilter}
              />
              <FilterDropdown
                title="Tags"
                name="tag"
                type="list"
                url={urls.tag()}
                default={[]}
                filters={filters}
                updateFilter={updateFilter}
              />
              <FilterDropdown
                title="Source"
                name="source"
                type="list"
                url={urls.source()}
                default={[]}
                filters={filters}
                updateFilter={updateFilter}
              />
              <FilterDropdown
                title="Nudity"
                name="nudity"
                type="radio"
                default="default"
                options={[
                  { title: "Allow Nudity", value: "default" },
                  { title: "Exclude Nudity", value: "false" },
                ]}
                filters={filters}
                updateFilter={updateFilter}
              />
            </>
          )}
          {props.options.model === "Artist" && (
            <>
              <FilterDropdown
                title="Name"
                name="name"
                type="text"
                default={null}
                filters={filters}
                updateFilter={updateFilter}
              />
              <FilterDropdown
                title="Origin"
                name="birth_place"
                type="list"
                url={urls.location()}
                default={[]}
                filters={filters}
                updateFilter={updateFilter}
              />
              <FilterDropdown
                title="Year"
                name="birth_year"
                type="slider"
                default="0"
                filters={filters}
                updateFilter={updateFilter}
              />
            </>
          )}
          {props.options.model === "Tag" && (
            <>
              <FilterDropdown
                title="Description"
                name="name"
                type="text"
                default={null}
                filters={filters}
                updateFilter={updateFilter}
              />
              <FilterDropdown
                title="Type"
                name="typename"
                type="radio"
                default="default"
                options={[
                  { title: "All", value: "default" },
                  { title: "Category", value: "CA" },
                  { title: "Media", value: "ME" },
                  { title: "Style", value: "ST" },
                  { title: "Genre", value: "GE" },
                  { title: "Field", value: "FI" },
                  { title: "Movement", value: "MO" },
                  { title: "Period", value: "PE" },
                  { title: "Other", value: "OT" },
                ]}
                filters={filters}
                updateFilter={updateFilter}
              />
              <FilterDropdown
                title="Sorting"
                name="ordering"
                type="radio"
                default="default"
                options={[
                  { title: "Best Match", value: "default" },
                  { title: "Most Recent", value: "latest" },
                  { title: "Most Used", value: "most" },
                  { title: "Least Used", value: "least" },
                ]}
                filters={filters}
                updateFilter={updateFilter}
              />
            </>
          )}
          {props.options.model === "Nationality" && (
            <>
              <FilterDropdown
                title="Name"
                name="name"
                type="text"
                default={null}
                filters={filters}
                updateFilter={updateFilter}
              />
            </>
          )}
          {props.options.model === "Location" && (
            <>
              <FilterDropdown
                title="Full-Text Search"
                name="name"
                type="text"
                default={null}
                filters={filters}
                updateFilter={updateFilter}
              />
              <FilterDropdown
                title="Country"
                name="country"
                type="text"
                default={null}
                filters={filters}
                updateFilter={updateFilter}
              />
              <FilterDropdown
                title="State"
                name="state"
                type="text"
                default={null}
                filters={filters}
                updateFilter={updateFilter}
              />
              <FilterDropdown
                title="City"
                name="city"
                type="text"
                default={null}
                filters={filters}
                updateFilter={updateFilter}
              />
              <FilterDropdown
                title="Verified"
                name="verified"
                type="radio"
                default="default"
                options={[
                  { title: "Show All", value: "default" },
                  { title: "Unverified", value: "false" },
                  { title: "Verified", value: "true" },
                ]}
                filters={filters}
                updateFilter={updateFilter}
              />
            </>
          )}
        </div>
      </animated.div>
    </>
  );
}
