import React from "react";

import GalleryCard from "../components/cards/GalleryCard";
import ArtMasonry from "../components/layout/ArtMasonry";
import urls from "../utility/urls";
import GalleryDetail from "../components/details/GalleryDetail";

export default function GallerySearch() {
  return (
    <div>
      <ArtMasonry
        title="Public Galleries"
        url={urls.gallery(null, { public: true })}
        objectType={GalleryCard}
        modalType={GalleryDetail}
      />
    </div>
  );
}
