import React, { useState, useMemo, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Typography,
  Button,
  Modal,
  message,
  Radio,
  Select,
  Popconfirm,
  Form,
  Switch,
  Input,
  InputNumber,
  Spin,
} from "antd";
import Masonry from "react-masonry-component";

import urls from "../utility/urls";
import ArtMasonry from "../components/layout/ArtMasonry";
import EditCard from "../components/cards/EditCard";
import api from "../utility/api";
import ArtSelect from "../components/layout/ArtSelect";

const { Text } = Typography;
const { Option } = Select;
const FIELDS = [
  "none",
  "title",
  "year",
  "location",
  "dimensions",
  "description",
  "license_type",
  "nudity",
  "published",
  "tags",
  "artist",
  "source",
  "birth_year",
  "birth_place",
  "death_year",
  "death_place",
  "bio",
  "description",
  "typename",
];

export default function Editor() {
  const user = useSelector((state) => state.user);
  const [selected, setSelected] = useState([]);
  const [visible, setVisible] = useState(false);
  const [model, setModel] = useState("Artwork");
  const [masonry, setMasonry] = useState(null);
  const [mode, setMode] = useState(0);
  const [value, setValue] = useState(null);
  const [edit, setEdit] = useState(0);
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  const artGrid = useRef();

  const border = {
    display: "block",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#fa3c3c",
    boxShadow: "0px 0px 0px 0px #fa3c3c",
    minHeight: "80vh",
    paddingBottom: "15px",
  };

  const updateObject = (data) => {
    const id = data.id;
    let arr = [...selected];
    arr.find((obj, i) => {
      if (obj.id === id) {
        arr[i] = data;
        setSelected(arr);
        return true;
      }
      return false;
    });
  };

  const updateSelected = (data, value) => {
    var temp = [...selected];
    if (value) {
      temp.push(data);
      setSelected(temp);
    } else {
      setSelected(temp.filter((obj) => obj.id !== data.id));
    }
  };

  const submitMerge = (id) => {
    setLoading(true);
    var data = {
      source: selected.filter((obj) => obj.id !== id).map((el) => el.id),
      destination: id,
      model: model,
    };
    api.put(urls.duplicate, data).then((response) => {
      if (response) {
        artGrid.current.removeSelected(data.source);
        setSelected([]);
        setVisible(false);
        message.success("Merge Successful");
      }
      setLoading(false);
      setValue(null);
    });
  };

  const getUrl = (id) => {
    if (model === "Artwork") {
      return urls.artwork(id);
    } else if (model === "Artist") {
      return urls.artist(id);
    } else if (model === "Tag") {
      return urls.tag(id);
    } else if (model === "Nationality") {
      return urls.nationality(id);
    } else if (model === "Location") {
      return urls.location(id);
    } else {
      return null;
    }
  };

  const deleteSelected = async () => {
    setLoading(true);
    var deleted = [];
    for await (var obj of selected) {
      var response = api.delete(getUrl(obj.id));
      if (response !== null) {
        deleted.push(obj.id);
      }
    }
    artGrid.current.removeSelected(deleted);
    setSelected([]);
    setLoading(false);
    message.success("Selections Deleted");
  };

  const quickEdit = async () => {
    var result;
    var temp = [...selected];
    var field = FIELDS[edit];

    setLoading(true);

    if (value === "") {
      if (field === "title") {
        message.error("Please provide a title");
        return;
      }
    }

    if (value === null) {
      if (field === "title" || field === "description") {
        message.error("Please provide a title");
        return;
      } else if (field.includes("year")) {
        result = parseInt(new Date().getFullYear());
      } else if (field === "license_type") {
        result = "CC0";
      } else if (field === "nudity") {
        result = false;
      } else if (field === "published") {
        result = true;
      }
    } else if (Array.isArray(value)) {
      result = value.map((obj) => obj.id);
    } else if (typeof value === "object") {
      result = value.id;
    } else {
      result = value;
    }

    for await (var item of temp) {
      var response = await api.patch(getUrl(item.id), { [field]: result });
      if (response) {
        item[field] = value;
      }
    }
    setSelected(temp);
    setLoading(false);
    message.success("Field updated");
  };

  const cards = useMemo(() => {
    return selected.map((obj, i) => (
      <div key={i} className="outer-card grid-sizer">
        <EditCard
          data={obj}
          updateObject={updateObject}
          updateGrid={() => masonry.performLayout()}
          options={{
            selected: selected,
            model: model,
            updateSelected: updateSelected,
            submitMerge: submitMerge,
          }}
        />
      </div>
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, model]);

  useEffect(() => {
    if (!user.admin) {
      history.push("/");
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    setSelected([]);
    setValue(null);
    setEdit(0);
  }, [model]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "15px",
          marginTop: "25px",
        }}
      >
        <Radio.Group
          size="large"
          buttonStyle="solid"
          defaultValue="Artwork"
          onChange={(e) => setModel(e.target.value)}
        >
          <Radio.Button
            style={{ width: "10vw", textAlign: "center" }}
            value="Artwork"
          >
            Artwork
          </Radio.Button>
          <Radio.Button
            style={{ width: "10vw", textAlign: "center" }}
            value="Artist"
          >
            Artist
          </Radio.Button>
          <Radio.Button
            style={{ width: "10vw", textAlign: "center" }}
            value="Tag"
          >
            Tag
          </Radio.Button>
          <Radio.Button
            style={{ width: "10vw", textAlign: "center" }}
            value="Nationality"
          >
            Nationality
          </Radio.Button>
          <Radio.Button
            style={{ width: "10vw", textAlign: "center" }}
            value="Location"
          >
            Location
          </Radio.Button>
        </Radio.Group>
      </div>
      <div
        className="flex-row-collapse"
        style={{
          width: "100%",
          padding: "15px",
          marginTop: "15px",
        }}
      >
        <div className="flex-row-container-lg" style={border}>
          {model === "Artwork" && (
            <ArtMasonry
              ref={artGrid}
              title="All Objects"
              url={urls.artwork()}
              objectType={EditCard}
              cardOptions={{
                selected: selected,
                updateSelected: updateSelected,
                model: model,
                submitMerge: submitMerge,
              }}
              filter={{ model: model }}
              gridEdit
            />
          )}
          {model === "Artist" && (
            <ArtMasonry
              ref={artGrid}
              title="All Objects"
              url={urls.artist()}
              objectType={EditCard}
              cardOptions={{
                selected: selected,
                updateSelected: updateSelected,
                model: model,
                submitMerge: submitMerge,
              }}
              filter={{ model: model }}
            />
          )}
          {model === "Tag" && (
            <ArtMasonry
              ref={artGrid}
              title="All Objects"
              url={urls.tag()}
              objectType={EditCard}
              cardOptions={{
                selected: selected,
                updateSelected: updateSelected,
                model: model,
                submitMerge: submitMerge,
              }}
              filter={{ model: model }}
            />
          )}
          {model === "Nationality" && (
            <ArtMasonry
              ref={artGrid}
              title="All Objects"
              url={urls.nationality()}
              objectType={EditCard}
              cardOptions={{
                selected: selected,
                updateSelected: updateSelected,
                model: model,
                submitMerge: submitMerge,
              }}
              filter={{ model: model }}
            />
          )}
          {model === "Location" && (
            <ArtMasonry
              ref={artGrid}
              title="All Objects"
              url={urls.location()}
              objectType={EditCard}
              cardOptions={{
                selected: selected,
                updateSelected: updateSelected,
                model: model,
                submitMerge: submitMerge,
              }}
              filter={{ model: model }}
            />
          )}
        </div>
        <div className="flex-row-container-sm" style={{ position: "relative" }}>
          <div
            style={{
              border: "1px solid #fa3c3c",
              padding: "15px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              height: "auto",
              position: "fixed",
              width: "24%",
            }}
          >
            {selected.length === 0 ? (
              <Text className="sub-text">Make selections to begin</Text>
            ) : (
              <Button
                type="primary"
                style={{ width: "100%" }}
                onClick={() => setVisible(true)}
              >
                {`View ${selected.length} Selections`}
              </Button>
            )}
            <div
              className="footer-border"
              style={{ marginBottom: "25px", marginTop: "25px", width: "100%" }}
            />
            {selected.length > 0 && !loading && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Select
                  style={{ width: "100%", marginBottom: "15px" }}
                  value={mode}
                  onChange={(val) => setMode(val)}
                >
                  <Option value={0}>Select an Edit Mode</Option>
                  <Option value={1}>Merge</Option>
                  <Option value={2}>Edit</Option>
                  <Option value={3}>Delete</Option>
                </Select>
                {mode === 1 && (
                  <>
                    {model === "Artwork" && (
                      <ArtSelect
                        name="artwork"
                        title="Artwork"
                        display="title"
                        url={urls.artwork()}
                        search="title"
                        updateValue={(key, val) => setValue(val)}
                      />
                    )}
                    {model === "Artist" && (
                      <ArtSelect
                        name="artist"
                        title="Artist"
                        display="name"
                        url={urls.artist()}
                        search="name"
                        updateValue={(key, val) => setValue(val)}
                      />
                    )}
                    {model === "Tag" && (
                      <ArtSelect
                        name="tag"
                        title="Tag"
                        display="description"
                        url={urls.tag()}
                        search="name"
                        updateValue={(key, val) => setValue(val)}
                      />
                    )}
                    {model === "Nationality" && (
                      <ArtSelect
                        name="nationality"
                        title="Nationality"
                        display="name"
                        url={urls.nationality()}
                        search="name"
                        updateValue={(key, val) => setValue(val)}
                      />
                    )}
                    {model === "Location" && (
                      <ArtSelect
                        name="location"
                        title="Location"
                        display="location"
                        url={urls.location()}
                        search="name"
                        updateValue={(key, val) => setValue(val)}
                      />
                    )}
                    {value && (
                      <Button
                        type="primary"
                        style={{ marginTop: "15px" }}
                        onClick={() => submitMerge(value.id)}
                        loading={loading}
                      >
                        Merge Selections
                      </Button>
                    )}
                  </>
                )}
                {mode === 2 && (
                  <>
                    <Select
                      style={{ width: "100%" }}
                      value={edit}
                      onChange={(val) => {
                        setEdit(val);
                        setValue(null);
                      }}
                      virtual={false}
                    >
                      <Option value={0}>Select a field to edit</Option>
                      {model === "Artwork" && (
                        <>
                          <Option value={1}>Title</Option>
                          <Option value={2}>Year</Option>
                          <Option value={3}>Location</Option>
                          <Option value={4}>Dimensions</Option>
                          <Option value={5}>Description</Option>
                          <Option value={6}>Licensing</Option>
                          <Option value={7}>Nudity</Option>
                          <Option value={8}>Published</Option>
                          <Option value={9}>Tags</Option>
                          <Option value={10}>Artist</Option>
                          <Option value={11}>Source</Option>
                        </>
                      )}
                      {model === "Artist" && (
                        <>
                          <Option value={12}>Birth Year</Option>
                          <Option value={13}>Birth Place</Option>
                          <Option value={14}>Death Year</Option>
                          <Option value={15}>Death Place</Option>
                          <Option value={16}>Bio</Option>
                          <Option value={8}>Published</Option>
                        </>
                      )}
                      {model === "Tag" && (
                        <>
                          <Option value={17}>Description</Option>
                          <Option value={18}>Typename</Option>
                        </>
                      )}
                    </Select>
                    <div
                      style={{
                        marginTop: "15px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {edit === 1 && (
                        <Form.Item label="Title">
                          <Input
                            onChange={(e) => setValue(e.target.value)}
                            placeholder="Enter a Title"
                            maxLength={256}
                          />
                        </Form.Item>
                      )}
                      {edit === 2 && (
                        <Form.Item label="Year">
                          <InputNumber
                            defaultValue={parseInt(new Date().getFullYear())}
                            onChange={setValue}
                          />
                        </Form.Item>
                      )}
                      {edit === 3 && (
                        <Form.Item label="Location">
                          <ArtSelect
                            name="location"
                            title="Location"
                            display="location"
                            url={urls.location()}
                            search="name"
                            updateValue={(key, val) => setValue(val)}
                          />
                        </Form.Item>
                      )}
                      {edit === 4 && (
                        <Form.Item label="Dimensions">
                          <Input
                            placeholder="Enter Dimensions"
                            onChange={(e) => setValue(e.target.value)}
                            maxLength={128}
                          />
                        </Form.Item>
                      )}
                      {edit === 5 && (
                        <Form.Item label="Description">
                          <Input.TextArea
                            maxLength={2000}
                            placeholder="Enter a Description"
                            onChange={(e) => setValue(e.target.value)}
                          />
                        </Form.Item>
                      )}
                      {edit === 6 && (
                        <Form.Item label="Licensing">
                          <Select defaultValue="CC0" onChange={setValue}>
                            <Option value="CC0">Creative Commons</Option>
                            <Option value="RF">Royalty Free</Option>
                            <Option value="P">Public Domain</Option>
                            <Option value="E">Editorial Use</Option>
                          </Select>
                        </Form.Item>
                      )}
                      {edit === 7 && (
                        <Form.Item label="Nudity">
                          <Switch defaultChecked={false} onChange={setValue} />
                        </Form.Item>
                      )}
                      {edit === 8 && (
                        <Form.Item label="Published">
                          <Switch
                            defaultChecked={false}
                            onChange={(val) => setValue(val)}
                          />
                        </Form.Item>
                      )}
                      {edit === 9 && (
                        <Form.Item label="Tags">
                          <ArtSelect
                            name="tags"
                            title="Tags"
                            display="description"
                            url={urls.tag()}
                            updateValue={(key, val) => setValue(val)}
                            search="name"
                            many
                          />
                        </Form.Item>
                      )}
                      {edit === 10 && (
                        <Form.Item label="Artist">
                          <ArtSelect
                            name="artist"
                            title="Artist"
                            display="name"
                            url={urls.artist()}
                            search="name"
                            updateValue={(key, val) => setValue(val)}
                            create
                          />
                        </Form.Item>
                      )}
                      {edit === 11 && (
                        <Form.Item label="Source">
                          <ArtSelect
                            name="source"
                            title="Source"
                            display="name"
                            url={urls.source()}
                            search="name"
                            updateValue={(key, val) => setValue(val)}
                          />
                        </Form.Item>
                      )}
                      {edit === 12 && (
                        <Form.Item label="Birth Year">
                          <InputNumber
                            defaultValue={parseInt(new Date().getFullYear())}
                            onChange={setValue}
                          />
                        </Form.Item>
                      )}
                      {edit === 13 && (
                        <Form.Item label="Birth Place">
                          <ArtSelect
                            name="birth_place"
                            title="Birth Place"
                            display="location"
                            url={urls.location()}
                            search="name"
                            updateValue={(key, val) => setValue(val)}
                          />
                        </Form.Item>
                      )}
                      {edit === 14 && (
                        <Form.Item label="Death Year">
                          <InputNumber
                            defaultValue={parseInt(new Date().getFullYear())}
                            onChange={setValue}
                          />
                        </Form.Item>
                      )}
                      {edit === 15 && (
                        <Form.Item label="Death Place">
                          <ArtSelect
                            name="death_place"
                            title="Death Place"
                            display="location"
                            url={urls.location()}
                            search="name"
                            updateValue={(key, val) => setValue(val)}
                          />
                        </Form.Item>
                      )}
                      {edit === 16 && (
                        <Form.Item label="Bio">
                          <Input.TextArea
                            maxLength={2000}
                            placeholder="Enter a Bio"
                            onChange={(e) => setValue(e.target.value)}
                          />
                        </Form.Item>
                      )}
                      {edit === 17 && (
                        <Form.Item label="Description">
                          <Input
                            onChange={(e) => setValue(e.target.value)}
                            placeholder="Enter a Description"
                            maxLength={256}
                          />
                        </Form.Item>
                      )}
                      {edit === 18 && (
                        <Form.Item label="Typename">
                          <Select defaultValue="OT" onChange={setValue}>
                            <Option value="CA">Category</Option>
                            <Option value="ME">Media</Option>
                            <Option value="ST">Style</Option>
                            <Option value="GE">Genre</Option>
                            <Option value="FI">Field</Option>
                            <Option value="MO">Movement</Option>
                            <Option value="PE">Period</Option>
                            <Option value="OT">Other</Option>
                          </Select>
                        </Form.Item>
                      )}
                      {edit > 0 && (
                        <Button
                          type="primary"
                          style={{ marginLeft: "auto", marginRight: "auto" }}
                          onClick={() => quickEdit()}
                          loading={loading}
                        >
                          Apply to Selected
                        </Button>
                      )}
                    </div>
                  </>
                )}
                {mode === 3 && (
                  <Popconfirm
                    title="Delete all Selections?"
                    onConfirm={() => deleteSelected()}
                    loading={loading}
                  >
                    <Button type="primary">Delete Selected</Button>
                  </Popconfirm>
                )}
              </div>
            )}
            {loading && <Spin size="large" />}
          </div>
        </div>
        <Modal
          width="85%"
          title="Selections"
          visible={visible && selected.length > 0}
          onCancel={() => setVisible(false)}
          footer={null}
        >
          <Button
            type="primary"
            onClick={() => {
              setSelected([]);
              setVisible(false);
            }}
          >
            Clear All
          </Button>
          <Masonry
            ref={(c) => setMasonry(c)}
            disableImagesLoaded={false}
            updateOnEachImageLoad={true}
            options={{ transitionDuration: "0.2s", percentPosition: true }}
          >
            <div className="grid-sizer"></div>
            {cards}
          </Masonry>
        </Modal>
      </div>
    </div>
  );
}
