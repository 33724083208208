import React, { useState, useEffect } from "react";
import {
  Typography,
  Radio,
  Switch,
  Slider,
  Input,
  Button,
  InputNumber,
} from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import _ from "lodash";
import formatter from "../../utility/formatter";
import ArtSelect from "./ArtSelect";

const { Text } = Typography;
const { Search } = Input;
const year = new Date().getFullYear();

export default function FilterDropdown(props) {
  const startingVal = props.default || null;
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(startingVal);
  const [slider, setSlider] = useState(0);

  const updateValue = (val) => {
    if (!_.isEqual(val, startingVal)) {
      var display;
      if (props.type === "radio") {
        setValue(val);
        // get radios display form options
        for (var option of props.options) {
          if (val === option.value) {
            display = getDisplay(option.title);
          }
        }
        if (props.title === "Nudity" && val === "true") {
          display = getDisplay("Only Nudity");
        }
        props.updateFilter(props.name, val, display);
      } else if (props.type === "slider") {
        setValue(val);
        if (Array.isArray(slider)) {
          // slider range format
          display = getDisplay(
            `${sliderFormat(slider[0])} - ${sliderFormat(slider[1])}`
          );
        } else {
          // slider single format
          display = getDisplay(sliderFormat(val));
        }
        props.updateFilter(props.name, val, display);
      } else if (props.type === "list") {
        if (val.length > 0) {
          setValue(val);
          props.updateFilter(
            props.name + val[val.length - 1].id,
            val[val.length - 1].id,
            getDisplay(val[val.length - 1].name)
          );
        }
      } else {
        setValue(val);
        props.updateFilter(props.name, val, getDisplay(val));
      }
    } else {
      if (!Array.isArray(val)) {
        setValue(props.default || null);
        props.updateFilter(props.name, null, null);
      }
    }
  };

  const updateSlider = (val) => {
    if (Array.isArray(val)) {
      updateValue(`${val[0]},${val[1]}`);
    } else {
      updateValue(val.toString());
    }
  };

  const updateRange = (val, side = null) => {
    if (side === "l") {
      updateValue(`${val},${slider[1]}`);
      setSlider([val, slider[1]]);
    } else if (side === "r") {
      updateValue(`${slider[0]},${val}`);
      setSlider([slider[0], val]);
    } else if (val) {
      setSlider([-1000, year]);
      setValue("0");
    } else {
      setSlider("0");
      setValue("0");
    }
  };

  const sliderFormat = (val) => {
    if (val === 0 || val === "0") {
      return "N/A";
    } else if (val <= 0) {
      return `${Math.abs(val)} BC`;
    } else if (val >= 0) {
      return val;
    } else {
      return val;
    }
  };

  const sliderParse = (val) => {
    if (val.includes("BC")) {
      val = -Math.abs(parseInt(val.replace(/\D/g, "")));
    } else {
      val = parseInt(val.replace(/\D/g, ""));
    }

    if (isNaN(val)) {
      return 0;
    } else {
      return val;
    }
  };

  const getDisplay = (display) => {
    return `${props.title}: ${display}`;
  };

  //update value when filter changes
  useEffect(() => {
    // check if filter exists in parent
    var filterExists = false;
    if (props.type === "list") {
      var tempList = [...value];
    }
    for (var filter of props.filters) {
      if (filter.name.includes(props.name)) {
        filterExists = true;
        // add filter
        var temp;
        if (props.type === "slider") {
          console.log(filter);
          temp = filter.value.split(",");
          if (temp.length > 1) {
            setValue(`${temp[0]},${temp[1]}`);
            setSlider(temp);
          } else {
            setValue(temp[0].toString());
            setSlider(temp[0]);
          }
        } else if (props.type === "list") {
          // eslint-disable-next-line
          if (!value.some((x) => x.id === filter.value)) {
            tempList.push({
              id: filter.value,
              name: filter.display.slice(filter.display.indexOf(":") + 2),
            });
          }
        } else if (props.type === "radio") {
          if (filter.value !== value) {
            updateValue(filter.value);
          }
        } else {
          setValue(filter.value);
        }
      }
    }
    if (filterExists && props.type === "list") {
      setValue(tempList);
    }
    if (!filterExists || props.type === "list") {
      // delete removed filter
      if (props.type === "slider") {
        setValue("0");
        setSlider(0);
      } else if (props.type === "list") {
        if (
          value.length >
          props.filters.filter((item) => item.name.includes(props.name)).length
        ) {
          var tempFilter = value.filter((item) =>
            props.filters.find((obj) => item.id === obj.value)
          );
          setValue(tempFilter);
        }
      } else {
        if (!_.isEqual(value, startingVal)) {
          setValue(props.default || null);
        }
      }
    }
    // eslint-disable-next-line
  }, [props.filters]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        paddingLeft: "10%",
        paddingRight: "10%",
        paddingTop: "25px",
      }}
    >
      <div
        onClick={() => setOpen(!open)}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
      >
        <Text className="special-text">{props.title}</Text>
        {open ? (
          <MinusOutlined className="special-text" />
        ) : (
          <PlusOutlined className="special-text" />
        )}
      </div>
      <div style={{ paddingLeft: "5px", marginTop: "5px" }}>
        {props.type === "text" && open && (
          <>
            <Search
              size="small"
              enterButton
              allowClear
              placeholder={props.title}
              onSearch={(val) => (val !== "" ? updateValue(val) : null)}
            />
            {props.default !== value && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Text className="main-description">
                  Current Filter:{" "}
                  <Text className="sub-description">{value}</Text>
                </Text>{" "}
                <Button type="link" onClick={() => updateValue(props.default)}>
                  Clear
                </Button>
              </div>
            )}
          </>
        )}
        {props.type === "list" && open && (
          <ArtSelect
            name={props.name}
            title={props.title}
            display={
              ["artist", "nationality", "source"].includes(props.name)
                ? "name"
                : ["location", "birth_place"].includes(props.name)
                ? "location"
                : "description"
            }
            url={props.url}
            initial={value}
            search="name"
            updateValue={(key, val) => {
              var tempFilter;
              if (val.length > value.length) {
                tempFilter = [...value];
                tempFilter.push({
                  id: val[val.length - 1].id,
                  name:
                    val[val.length - 1].name ||
                    val[val.length - 1].description ||
                    formatter.location(val[val.length - 1]),
                });
                updateValue(tempFilter);
              } else {
                var item = value.find(
                  (el) => !val.some((obj) => obj.id === el.id)
                );
                tempFilter = value.filter((obj) => obj.id !== item.id);
                setValue(tempFilter);
                props.updateFilter(props.name + item.id, null, null);
              }
            }}
            many
          />
        )}
        {props.type === "radio" && open && (
          <Radio.Group
            value={value}
            onChange={(val) => updateValue(val.target.value)}
          >
            {props.options.map((obj, i) => (
              <div key={i}>
                {!obj.hidden && (
                  <Radio
                    value={obj.value}
                    className="sub-description"
                    style={{
                      display: "block",
                      height: "30px",
                      lineHeight: "30px",
                    }}
                  >
                    {obj.title}
                  </Radio>
                )}
              </div>
            ))}
          </Radio.Group>
        )}
        {props.type === "slider" && open && (
          <>
            <Text className="sub-description">Range: </Text>
            <Switch
              checked={Array.isArray(slider)}
              size="small"
              onChange={updateRange}
            />
            <Slider
              min={-1000}
              max={year}
              range={Array.isArray(slider) ? { draggableTrack: true } : false}
              value={slider}
              tipFormatter={sliderFormat}
              onChange={(val) => setSlider(val)}
              onAfterChange={updateSlider}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {Array.isArray(slider) ? (
                <>
                  <InputNumber
                    style={{ width: "75px", height: "25px" }}
                    size="small"
                    min={-1000}
                    max={year}
                    formatter={sliderFormat}
                    parser={sliderParse}
                    value={slider[0]}
                    onChange={(val) => updateRange(val, "l")}
                  />
                  <Button
                    type="link"
                    onClick={() => {
                      updateValue("0");
                      setSlider(0);
                    }}
                  >
                    Reset
                  </Button>
                  <InputNumber
                    style={{ width: "75px", height: "25px" }}
                    size="small"
                    min={-1000}
                    max={year}
                    formatter={sliderFormat}
                    parser={sliderParse}
                    value={slider[1]}
                    onChange={(val) => updateRange(val, "r")}
                  />
                </>
              ) : (
                <>
                  <Button
                    type="link"
                    onClick={() => {
                      updateValue("0");
                      setSlider(0);
                    }}
                  >
                    Reset
                  </Button>
                  <InputNumber
                    style={{ width: "75px", height: "25px" }}
                    size="small"
                    min={-1000}
                    max={year}
                    value={slider}
                    formatter={sliderFormat}
                    parser={sliderParse}
                    onChange={(val) => {
                      setSlider(val);
                      updateValue(val.toString());
                    }}
                  />
                </>
              )}
            </div>
          </>
        )}
      </div>
      <div
        className="divider-color"
        style={{
          height: "1px",
          width: "100%",
          marginTop: "20px",
        }}
      />
    </div>
  );
}
