import React, { useState, useEffect } from "react";
import { Statistic } from "antd";
import { EyeOutlined, DownloadOutlined } from "@ant-design/icons";

import api from "../utility/api";
import urls from "../utility/urls";

export default function Stats() {
  const [data, setData] = useState({});

  useEffect(() => {
    api.get(urls.tracker).then((response) => {
      if (response) {
        setData(response);
      }
    });
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "50px",
        height: "100%",
      }}
    >
      <div className="header-text">MyArtDrive Daily Stats</div>
      <div style={{ height: "20px" }} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-evenly",
          width: "100%",
          height: "25vh",
        }}
      >
        <Statistic
          className="sub-text"
          title="Total Views"
          value={data.total_views}
          prefix={<EyeOutlined className="stat-icon" />}
        />
        <Statistic
          className="sub-text"
          title="Downloads"
          value={data.total_downloads}
          prefix={<DownloadOutlined className="stat-icon" />}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-evenly",
          width: "100%",
          height: "25vh",
        }}
      >
        <Statistic
          className="sub-text"
          title="Artwork Views"
          value={data.artwork_views}
          prefix={<EyeOutlined className="stat-icon" />}
        />
        <Statistic
          className="sub-text"
          title="Artist Views"
          value={data.artist_views}
          prefix={<EyeOutlined className="stat-icon" />}
        />
        <Statistic
          className="sub-text"
          title="Gallery Views"
          value={data.gallery_views}
          prefix={<EyeOutlined className="stat-icon" />}
        />
      </div>
    </div>
  );
}
