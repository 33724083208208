import { createStore } from "redux";

const initialState = {
  user: null,
  width: 0,
  scenic: localStorage.getItem("scenic") === "true",
  favorites: [],
  darkMode: localStorage.getItem("theme") === "true",
  quickPick: localStorage.getItem("quickPick"),
  order: localStorage.getItem("order"),
  reviewBar: localStorage.getItem("reviewBar") === "true",
  notificationCount: 0,
};

function artState(state = initialState, action) {
  switch (action.type) {
    case "SET_USER":
      return Object.assign({}, state, {
        user: action.user,
      });
    case "SET_WIDTH":
      return Object.assign({}, state, {
        width: action.width,
      });
    case "SET_SCENIC":
      localStorage.setItem("scenic", action.scenic);
      return Object.assign({}, state, {
        scenic: action.scenic,
      });
    case "SET_FAVORITES":
      return Object.assign({}, state, {
        favorites: action.favorites,
      });
    case "SET_DARKMODE":
      localStorage.setItem("theme", action.darkMode);
      return Object.assign({}, state, {
        darkMode: action.darkMode,
      });
    case "SET_QUICKPICK":
      if (action.quickPick) {
        localStorage.setItem("quickPick", action.quickPick);
      } else {
        localStorage.removeItem("quickPick");
      }
      return Object.assign({}, state, {
        quickPick: action.quickPick,
      });
    case "SET_ORDER":
      if (action.quickPick !== "null") {
        localStorage.setItem("order", action.order);
      } else {
        localStorage.removeItem("order");
      }
      return Object.assign({}, state, {
        order: action.order,
      });
    case "SET_REVIEWBAR":
      localStorage.setItem("reviewBar", action.reviewBar);
      return Object.assign({}, state, {
        reviewBar: action.reviewBar,
      });
      case "SET_NOTIFICATIONCOUNT":
      return Object.assign({}, state, {
        notificationCount: action.notificationCount,
      });
    default:
      return state;
  }
}

const store = createStore(artState);

export default store;
