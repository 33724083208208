import React, { useState } from "react";
import { useSpring, animated } from "react-spring";

export default function CardIcon(props) {
  const [hover, setHover] = useState(false);

  const box = useSpring({ width: hover ? 175 : 35, background: props.color });
  const text = useSpring({
    opacity: hover ? 1 : 0,
    display: hover ? "flex" : "none",
    marginRight: "25px",
    color: 'white'
  });

  return (
    <animated.div
      className="icon-button"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={box}
      onClick={props.onClick}
    >
      {props.icon}
      <animated.div className="icon-text sub-description" style={text}>
        {props.text}
      </animated.div>
    </animated.div>
  );
}
