import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import { Layout, BackTop } from "antd";

import AppRoutes from "../router/AppRoutes";
import Navbar from "./Navbar";
import api from "../utility/api";
import urls from "../utility/urls";

const { Content } = Layout;

var prev = window.pageYOffset;

export default function Base() {
  const user = useSelector((state) => state.user);
  const darkMode = useSelector((state) => state.darkMode);
  const [googleAuth, setGoogleAuth] = useState(null);
  const [loginAttempt, setLoginAttempt] = useState(false);
  const dispatch = useDispatch();
  let location = useLocation();

  const login = () => {
    // try basic login
    if (location.pathname !== "/login") {
      api.refresh().then(() => {
        setLoginAttempt(true);
      });
    } else {
      setLoginAttempt(true);
    }

    // load google api
    window.gapi.load("auth2", function () {
      var auth2;
      auth2 = window.gapi.auth2.init({
        client_id:
          "362207335019-5bpnphf8ia54fee3ebi0o5iontul12n8.apps.googleusercontent.com",
      });

      // listen to google user changes
      auth2.currentUser.listen((val) => {
        if (val.isSignedIn()) {
          api
            .post(urls.login, { id_token: val.getAuthResponse().id_token })
            .then((response) => {
              if (response) {
                dispatch({ type: "SET_USER", user: response });
              }
            });
        }
      });

      setGoogleAuth(auth2);
    });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(login, []);

  const logout = () => {
    if (user.iss === "accounts.google.com") {
      googleAuth.signOut();
      dispatch({ type: "SET_USER", user: null });
    } else {
      api.delete(urls.login).then((response) => {
        if (response) {
          dispatch({ type: "SET_USER", user: null });
        }
      });
    }
    localStorage.removeItem("lastGallery");
    localStorage.removeItem("order");
  };

  useEffect(() => {
    try {
      if (darkMode) {
        document.body.classList.replace("light-theme", "dark-theme");
      } else {
        document.body.classList.replace("dark-theme", "light-theme");
      }
    } catch (e) {
      console.log(e);
    }
  }, [darkMode]);

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      if (window.pageYOffset <= 100) {
        document.getElementById("Navbar").style.top = "0px";
      } else if (prev >= window.pageYOffset) {
        document.getElementById("Navbar").style.top = "0px";
      } else {
        document.getElementById("Navbar").style.top = "-100px";
      }
      prev = window.pageYOffset;
    });
  }, []);

  return (
    <Layout
      className="background"
      style={{
        position: "relative",
        minHeight: "100vh",
        paddingBottom: "4rem",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Navbar logout={logout} />
      <div style={{ marginTop: "75px" }} />
      {loginAttempt && (
        <Content style={{ width: "100%" }}>
          <AppRoutes />
          <BackTop />
        </Content>
      )}
      <div
        className="flex-row-collapse footer-border"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          position: "absolute",
          bottom: "0",
          width: "90%",
          height: "4rem",
          paddingLeft: "15px",
          paddingRight: "15px",
        }}
      >
        <a href="https://www.xsponse.com">
          Copyright © XSPONSE {new Date().getFullYear()}
        </a>
        {/*<Link to="/about-us">About Us</Link>*/}
        <Link to="/contact-us">Contact Us</Link>
        <a href="https://docs.xsponse.com/digital-displays/artdrive-quickstart.html">
          Quick Start
        </a>
        <a href="https://docs.xsponse.com">Support</a>
        <Link to="/privacy-policy">Privacy</Link>
        <Link to="/terms-of-service">Terms</Link>
      </div>
    </Layout>
  );
}
