import { useState } from "react";

export default function useGalleryModal() {
  const [visible, setVisible] = useState(false);
  const [artworkId, setArtworkId] = useState(null);

  const setGalleryModal = (id = null) => {
    if (id) {
      setVisible(true);
      setArtworkId(id);
    } else {
      setVisible(false);
      setArtworkId(null);
    }
  };

  return {
    visible,
    artworkId,
    setGalleryModal,
  };
}
