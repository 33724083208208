import React from "react";

import GalleryModal from "../components/modals/GalleryModal";
import useGalleryModal from "../hooks/useGalleryModal";

let GalleryContext;
let { Provider } = (GalleryContext = React.createContext());

let GalleryProvider = ({ children }) => {
  let { visible, artworkId, setGalleryModal } = useGalleryModal();
  return (
    <Provider value={{ visible, artworkId, setGalleryModal }}>
      <GalleryModal />
      {children}
    </Provider>
  );
};

export { GalleryContext, GalleryProvider }
