import React, { useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import { Typography } from "antd";

const { Text } = Typography;

export default function NavButton(props) {
  const [toggle, setToggle] = useState(false);
  let location = useLocation();
  const selected = useMemo(() => {
    if (props.routes) {
      return props.routes.includes(location.pathname);
    } else {
      return false;
    }
  }, [location.pathname, props.routes]);

  const underline = useSpring({
    position: "relative",
    height: "3px",
    opacity: toggle || selected ? "1" : "0",
    width: toggle || selected ? "100%" : "0%",
    background: "#fa3c3c",
  });

  return (
    <div
      style={{ marginTop: "auto", marginBottom: "auto", cursor: "pointer" }}
      onMouseEnter={() => setToggle(true)}
      onMouseLeave={() => setToggle(false)}
      onClick={props.onClick}
    >
      <Text className={props.colored ? "special-text-red" : "special-text"}>{props.name}</Text>
      <animated.div style={underline}></animated.div>
    </div>
  );
}
