import React from "react";

export default function About() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "50px",
      }}
    >
      <div className="header-text">About Us</div>
      <br />
    </div>
  );
}
