import React from "react";
import {
  EnvironmentOutlined,
  //PhoneOutlined,
  MailOutlined,
} from "@ant-design/icons";

const circle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "75px",
  height: "75px",
  borderRadius: "75px",
  background: "#fa3c3c",
  marginBottom: "5px",
};

const icon = {
  fontSize: "28px",
  color: "white",
};

const box = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

export default function Contact() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "50px",
      }}
    >
      <div className="header-text">Contact Us</div>
      <div style={{ height: "20px" }} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          width: "100%",
        }}
      >
        <div style={box}>
          <div style={circle}>
            <EnvironmentOutlined style={icon} />
          </div>
          <div className="main-text">Location</div>
          <div
            className="footer-border"
            style={{ width: "100%", marginTop: "5px" }}
          />
          <div style={{ height: "20px" }} />
          <div className="main-description">Xsponse</div>
          <div className="sub-description">10 1/2 E Washington Ave,</div>
          <div className="sub-description">Yakima WA 98903</div>
        </div>
        <div style={box}>
          <div style={circle}>
            <MailOutlined style={icon} />
          </div>
          <div className="main-text">E-Mail</div>
          <div
            className="footer-border"
            style={{ width: "100%", marginTop: "5px" }}
          />
          <div style={{ height: "20px" }} />
          <div className="main-description">Support</div>
          <div className="sub-description">support@xsponse.com</div>
          <div style={{ height: "20px" }} />
          <div className="main-description">Business</div>
          <div className="sub-description">john@xsponse.com</div>
        </div>
        {/*
          <div style={box}>
            <div style={circle}>
              <PhoneOutlined style={icon} />
            </div>
            <div className="main-text">Phone</div>
          </div>
        */}
      </div>
    </div>
  );
}
