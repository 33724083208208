import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Typography, Button, message, Space, Statistic, Skeleton } from "antd";
import Icon, {
  StarOutlined,
  FacebookOutlined,
  TwitterOutlined,
  EyeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";

import api from "../../utility/api";
import urls from "../../utility/urls";
import formatter from "../../utility/formatter";
import ArtMasonry from "../layout/ArtMasonry";
import ImageCard from "../cards/ImageCard";
import ArtworkDetail from "../details/ArtworkDetail";
import { LoginContext } from "../../wrappers/LoginContext";

import { ReactComponent as Pinterest } from "../../static/img/pinterest.svg";
import ReportModal from "../modals/ReportModal";
import ArtistFormModal from "../modals/ArtistFormModal";
const { Text, Paragraph } = Typography;

export default function ArtistDetail(props) {
  const user = useSelector((state) => state.user);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [favoriteHover, setFavoriteHover] = useState(false);
  let { setLoginVisible } = useContext(LoginContext);
  let history = useHistory();

  const PinterestIcon = (props) => <Icon component={Pinterest} {...props} />;

  const handleFavorite = () => {
    if (user) {
      if (data.favorite) {
        api.delete(urls.favorite(data.favorite)).then((response) => {
          if (response != null) {
            message.success("Artist Removed from Favorites");
            var temp = Object.assign({}, data);
            temp.favorite = null;
            temp.total_favorites -= 1;
            setData(temp);
            if (props.options !== "gallery") {
              props.removeObject(data.id);
            }
          }
        });
      } else {
        api
          .post(urls.favorite(), { model: "Artist", object_id: data.id })
          .then((response) => {
            if (response) {
              message.success("Artist Added to Favorites");
              var temp = Object.assign({}, data);
              temp.favorite = response.id;
              temp.total_favorites += 1;
              setData(temp);
              if (props.options !== "gallery") {
                props.addObject(response.object_data);
              }
            }
          });
      }
    } else {
      setLoginVisible(true);
    }
  };

  useEffect(() => {
    if (props.id) {
      api.get(urls.artist(props.id)).then((response) => {
        if (response) {
          setData(response);
          setLoading(false);
        }
      });
      api.post(urls.tracker, {
        object_id: props.id,
        model: "Artist",
        tracker_type: "V",
      });
    }
  }, [props.id, user]);

  return (
    <Skeleton
      active
      paragraph={{ rows: 10 }}
      loading={loading}
      style={{ display: "flex", flexDirection: "column", width: "100%" }}
    >
      <div className="flex-row-collapse" style={{ width: "100%" }}>
        {data.photo && (
          <img
            src={data.photo}
            alt={data.name}
            style={{
              maxWidth: "175px",
              border: "2px solid #fa3c3c",
              marginLeft: "15px",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          />
        )}
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <Text className="header-text" style={{ paddingLeft: "15px" }}>
            {data.name}
          </Text>
          <Space style={{ marginLeft: "20px" }} size={35}>
            <Statistic
              className="sub-text"
              prefix={<EyeOutlined className="stat-icon" />}
              title="Views"
              value={data.total_views}
            />
            <Statistic
              className="sub-text"
              prefix={<StarOutlined className="stat-icon" />}
              title="Favorites"
              value={data.total_favorites}
            />
          </Space>
          <div
            className="flex-row-collapse"
            style={{
              marginTop: "10px",
              marginBottom: "10px",
              marginLeft: "15px",
            }}
          >
            <div>
              <Button
                className="flex-row-item"
                onClick={handleFavorite}
                onMouseEnter={() => setFavoriteHover(true)}
                onMouseLeave={() => setFavoriteHover(false)}
                icon={
                  <StarOutlined
                    style={
                      data.favorite || favoriteHover ? { color: "#ffd700" } : {}
                    }
                  />
                }
              >
                Favorite
              </Button>
            </div>
            <div>
              <Button
                className="flex-row-item"
                icon={<FacebookOutlined style={{ color: "#4267B2" }} />}
                onClick={() =>
                  urls.openInNewTab(
                    `https://www.facebook.com/sharer/sharer.php?u=https://myartdrive.com/artists/${data.id}/`
                  )
                }
              ></Button>
              <Button
                className="flex-row-item"
                icon={<TwitterOutlined style={{ color: "#1DA1F2" }} />}
                onClick={() =>
                  urls.openInNewTab(
                    `https://twitter.com/share/?url=https://myartdrive.com/artists/${data.id}/`
                  )
                }
              ></Button>
              {data.photo && (
                <Button
                  className="flex-row-item"
                  icon={<PinterestIcon />}
                  onClick={() =>
                    urls.openInNewTab(
                      `https://pinterest.com/pin/create/button/?url=https://myartdrive.com/artworks/${data.id}/&media=${data.photo}`
                    )
                  }
                ></Button>
              )}
            </div>
          </div>
          {user && (data.user === user.id || user.admin || user.source) && (
            <div
              style={{
                marginBottom: "15px",
                marginLeft: "15px",
              }}
            >
              {data.user === user.id && (
                <Button
                  className="flex-row-item"
                  icon={<PlusOutlined />}
                  onClick={() => history.push("/create/?mode=artist")}
                >
                  Add
                </Button>
              )}
              {(data.user === user.id || user.admin || user.source) && (
                <ArtistFormModal
                  title="Edit Artist Profile"
                  initial={data}
                  updateObject={setData}
                  button
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div
        className="artwork-detail"
        style={{
          marginLeft: "15px",
          marginRight: "15px",
          marginBottom: "15px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {data.nationality && (
          <Text className="main-description">
            Nationality:{" "}
            <Text className="sub-description">{data.nationality.name}</Text>
          </Text>
        )}
        {formatter.artistLife(data) !== null && (
          <Text className="main-description">
            {" "}
            Origin:{" "}
            <Text className="sub-description">
              {formatter.artistLife(data)}
            </Text>
          </Text>
        )}
        {data.death_cause && (
          <Text className="main-description">
            {" "}
            Cause of Death:{" "}
            <Text className="sub-description">{data.death_cause}</Text>
          </Text>
        )}
        {data.bio && (
          <Paragraph
            className="sub-description"
            ellipsis={{ rows: 3, expandable: true, symbol: "more" }}
            style={{ margin: 0 }}
          >
            <Text className="main-description">Bio:</Text> {data.bio}
          </Paragraph>
        )}
        {data.official_site && (
          <Text className="main-description">
            {" "}
            Website:{" "}
            <a className="sub-description" href={data.official_site}>
              {data.official_site}
            </a>
          </Text>
        )}
        {data.wikipedia && (
          <Text className="main-description">
            {" "}
            Wikipedia:{" "}
            <a className="sub-description" href={data.wikipedia}>
              {data.wikipedia}
            </a>
          </Text>
        )}
      </div>
      <div style={{ paddingLeft: "15px" }}>
        <ReportModal model="Artist" object_id={props.id} />
      </div>
      <ArtMasonry
        url={urls.artwork(null, { artist: props.id })}
        customScroll={props.customScroll}
        lightbox
        objectType={ImageCard}
        modalType={ArtworkDetail}
        owner={data.user}
        gridEdit
      />
      <div style={{ height: "20px" }} />
    </Skeleton>
  );
}
