import React, { useState, useEffect, useContext } from "react";
import ReactDOM from "react-dom";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Modal, Typography, Space, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

import api from "../../utility/api";
import urls from "../../utility/urls";
import { LoginContext } from "../../wrappers/LoginContext";

const { Text } = Typography;

export default function LoginModal() {
  const [signup, setSignup] = useState(false);
  const [verify, setVerify] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  let { loginVisible, setLoginVisible } = useContext(LoginContext);
  let history = useHistory();
  const closeModal = () => {
    setLoginVisible(false);
    setSignup(false);
    setVerify(false);
  };

  const onFinish = (values) => {
    if (signup) {
      api.post(urls.signup, values).then((response) => {
        if (response) {
          dispatch({ type: "SET_USER", user: response });
          setVerify(true);
          form.resetFields();
        } else {
          form.resetFields(["password"]);
        }
      });
    } else {
      api.post(urls.login, values).then((response) => {
        if (response) {
          dispatch({ type: "SET_USER", user: response });
          if (response.active) {
            closeModal();
          } else {
            setVerify(true);
          }
          form.resetFields();
        } else {
          form.resetFields(["password"]);
        }
      });
    }
  };

  const checkVerifacation = () => {
    api.refresh().then((response) => {
      if (response) {
        dispatch({ type: "SET_USER", user: response });
        if (response.active) {
          message.success("Account Verified");
          closeModal();
        } else {
          message.error("Account has not been Verified");
        }
      } else {
        message.error("Cannot Connect to Server");
      }
    });
  };

  const sendEmail = () => {
    api.put(urls.signup, { id: user.id }).then((response) => {
      if (response) {
        message.success("Email Sent");
      } else {
        message.error("An Error Occurred");
      }
    });
  };

  useEffect(() => {
    if (loginVisible === "signup") {
      setLoginVisible(true);
      setVerify(false);
      setSignup(true);
    }
    if (loginVisible === "verify") {
      setLoginVisible(true);
      setSignup(false);
      setVerify(true);
    }
    if (loginVisible && !verify) {
      window.gapi.signin2.render("my-signin2", {
        scope: "profile email",
        width: 300,
        height: 35,
        longtitle: true,
        theme: "dark",
        onsuccess: closeModal,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginVisible]);

  if (loginVisible) {
    return ReactDOM.createPortal(
      <Modal
        visible={true}
        title={verify ? "Verify Account" : null}
        onCancel={closeModal}
        bodyStyle={{padding: 0, paddingTop: '15px'}}
        footer={
          verify
            ? [
                <Button onClick={sendEmail}>Resend Email</Button>,
                <Button onClick={checkVerifacation} type="primary">
                  Check verifaction
                </Button>,
              ]
            : null
        }
      >
        {verify ? (
          <Space
            direction="vertical"
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: '15px'
            }}
          >
            <Text className="sub-description">
              Thanks for signing up! Please check your inbox to verify your
              email address
            </Text>
            <Text className="sub-description">
              Email sent to: {user ? user.email : ""}
            </Text>
            <Text className="sub-description">
              Site access may be limited until verifaction is complete
            </Text>
          </Space>
        ) : (
          <Form form={form} style={{ padding: "25px" }} onFinish={onFinish}>
            <Form.Item
              name="email"
              label="E-mail"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <Input prefix={<UserOutlined />} placeholder="Email" />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
              hasFeedback={signup}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="Password"
              />
            </Form.Item>
            {signup && (
              <>
                <Form.Item
                  name="confirm"
                  label="Confirm Password"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject();
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    placeholder="Confirm Password"
                  />
                </Form.Item>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Name!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="display_name"
                  label="Display Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Display Name!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </>
            )}
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                {signup ? "Sign Up" : "Log In"}
              </Button>
            </Form.Item>
            <Form.Item>
              <div
                className="divider-color"
                style={{ width: "100%", height: "1px" }}
              />
              <Space
                direction="vertical"
                style={{
                  marginTop: "15px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div id="my-signin2" />
              </Space>
            </Form.Item>
            <Form.Item>
              <div>
              {signup ? "Already have an account?" : "Don't have an account?"}{" "}
              <Button
                onClick={() => setSignup(!signup)}
                type="link"
                style={{ paddingLeft: "5px" }}
              >
                {signup ? "Login here!" : "Signup now!"}
              </Button>
              </div>
              <Button type="link" style={{paddingLeft: 0}} onClick={() => {
                closeModal()
                history.push('/password-reset')
              }}>Forgot Password?</Button>
            </Form.Item>
          </Form>
        )}
      </Modal>,
      document.querySelector("#login-root")
    );
  } else {
    return null;
  }
}
