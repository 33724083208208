import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Radio } from "antd";

import ArtList from "../components/layout/ArtList";
import urls from "../utility/urls";
import ReportItems from "../components/layout/ReportItems";

export default function Report() {
  const user = useSelector((state) => state.user);
  const [status, setStatus] = useState("S");
  const [model, setModel] = useState(true);
  let history = useHistory();

  useEffect(() => {
    if (!user.admin) {
      history.push("/");
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "15px",
          marginTop: "25px",
        }}
      >
        <Radio.Group
          size="large"
          buttonStyle="solid"
          defaultValue={true}
          onChange={(e) => setModel(e.target.value)}
        >
          <Radio.Button
            style={{ width: "25vw", textAlign: "center" }}
            value={true}
          >
            Artwork
          </Radio.Button>
          <Radio.Button
            style={{ width: "25vw", textAlign: "center" }}
            value={false}
          >
            Artist
          </Radio.Button>
        </Radio.Group>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "15px",
        }}
      >
        <Radio.Group
          size="large"
          buttonStyle="solid"
          defaultValue="S"
          onChange={(e) => setStatus(e.target.value)}
        >
          <Radio.Button value="S">Submitted</Radio.Button>
          <Radio.Button value="I">In-Review</Radio.Button>
          <Radio.Button value="R">Reviewed</Radio.Button>
        </Radio.Group>
      </div>
        <ArtList
          url={urls.report(null, { model: model ? "Artwork" : "Artist", status: status })}
          component={ReportItems}
          title="All Reports"
          results
          pagination
          settings
        />
    </div>
  );
}
