import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Modal, Form, Select, Input, message } from "antd";

import api from "../../utility/api";
import urls from "../../utility/urls";

const { Option } = Select;
const { TextArea } = Input;

export default function ReportModal(props) {
  const user = useSelector((state) => state.user);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        api
          .post(urls.report(), {
            model: props.model,
            object_id: props.object_id,
            category: values.category,
            reason: values.reason,
            message: values.message,
          })
          .then((response) => {
            if (response) {
              message.success("Report Sent");
              form.resetFields();
              setVisible(false);
            }
          });
      })
      .catch((info) => {});
  };

  return (
    <div>
      {user && user.report && (
        <Button type="primary" onClick={() => setVisible(true)}>
          Report
        </Button>
      )}
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={onFinish}
      >
        <Form form={form} style={{ marginTop: "25px" }}>
          <Form.Item
            name="category"
            label="Category"
            rules={[{ required: true, message: "Please select a category" }]}
          >
            <Select>
              <Option value="L">Licensing</Option>
              <Option value="I">Inapropiate Content</Option>
              <Option value="M">Missing Content</Option>
              <Option value="W">Incorrect Information</Option>
              <Option value="D">Duplicate</Option>
              <Option value="O">Other</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="reason"
            label="Description"
            rules={[
              { required: true, message: "Please write a quick description" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="message" label="Extra Info">
            <TextArea />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
