import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Input, Typography } from "antd";

import ImageCard from "../components/cards/ImageCard";
import ArtworkDetail from "../components/details/ArtworkDetail";
import urls from "../utility/urls";
import ArtMasonry from "../components/layout/ArtMasonry";

import banner from "../static/img/wheatfield.jpg";

const { Search } = Input;
const { Text } = Typography;

export default function Home() {
  const [visible, setVisible] = useState(true);
  let history = useHistory();
  const order = useSelector((state) => state.order);

  const getPreference = () => {
    if (order === "null" || order === null) {
      return null;
    } else {
      return { preference: order };
    }
  };

  return (
    <div>
      {visible && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "50vh",
            backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) ), url(${banner})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            textAlign: "center",
          }}
        >
          <Text
            className="header-text light"
            style={{
              marginBottom: "50px",
              background: "rgba(0, 0, 0, 0.55)",
              borderRadius: "10px",
              padding: "5px",
            }}
          >
            Artwork is a World of Imagination
          </Text>
          <Search
            placeholder="Search All Artworks"
            size="large"
            enterButton
            style={{ width: "75%", marginBottom: "45px" }}
            onSearch={(text) => history.push(`/artworks/?search=${text}`)}
          />
        </div>
      )}
      <div>
        <ArtMasonry
          title="All Artworks"
          url={urls.artwork(null, getPreference())}
          collapseHero={() => setVisible(false)}
          objectType={ImageCard}
          modalType={ArtworkDetail}
          filter={{ model: "Artwork" }}
          lightbox
        />
      </div>
    </div>
  );
}
