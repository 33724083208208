import { useState } from "react";

export default function useGalleryModal() {
  const [loginVisible, setLoginVisible] = useState(false);

  return {
    loginVisible,
    setLoginVisible,
  };
}
