import React from "react";

export default function Privacy() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "50px",
      }}
    >
      <div className="header-text">MyArtDrive Privacy Policy</div>
      <br />
      <div className="sub-description">
        Your privacy is critically important to us. At NRS Systems, we have a
        few fundamental principles:
        <ul>
          <li>
            We are thoughtful about the personal information we ask you to
            provide and the personal information that we collect about you
            through the operation of our services.
          </li>
          <li>
            We store personal information for only as long as we have a reason
            to keep it.
          </li>
          <li>
            We aim to make it as simple as possible for you to control what
            information on your website is shared publicly (or kept private),
            indexed by search engines, and permanently deleted.
          </li>
          <li>
            We help protect you from overreaching government demands for your
            personal information.
          </li>
          <li>
            {" "}
            We aim for full transparency on how we gather, use, and share your
            personal information.
          </li>
        </ul>
        Below is our Privacy Policy, which incorporates and clarifies these
        principles.
        <br />
        <br />
        <h3 className="text-color">Who We Are and What This Policy Covers</h3>
        We are NRS Systems. We build cloud-connected devices that allow you to
        monitor whatever you want to and when something happens, we provide the
        tools you need to respond intelligently. This Privacy Policy applies to
        information that we collect about you when you use:
        <ul>
          <li>
            {" "}
            Our websites (including nrssystems.com, nrsalert.com and
            xsponse.com).
          </li>
          <li>
            Other users’ websites that use our Services, while you are logged in
            to your account with us.
          </li>
        </ul>
        Throughout this Privacy Policy we’ll refer to our websites, and other
        products and services collectively as “Services.” Please note that this
        Privacy Policy does not apply to any of our products or services that
        have a separate privacy policy. Below we explain how we collect, use,
        and share information about you, along with the choices that you have
        with respect to that information.
        <br />
        <br />
        <h3 className="text-color">Information We Collect</h3>
        We only collect information about you if we have a reason to do so–for
        example, to provide our Services, to communicate with you, or to make
        our Services better. We collect information in three ways: if and when
        you provide information to us, automatically through operating our
        Services, and from outside sources. Let’s go over the information that
        we collect.
        <h4 className="text-color">
          <em>Information You Provide to Us</em>
        </h4>
        It’s probably no surprise that we collect information that you provide
        to us. The amount and type of information depends on the context and how
        we use the information. Here are some examples:
        <ul>
          <li>
            {" "}
            <b>Basic Account Information:</b> We ask for basic information from
            you in order to set up your account. For example, we require
            individuals who sign up for a NRS Systems account to provide an
            email address along with a username or name. You may provide us with
            more information – like your address and other information you want
            to share – but we don’t require that information to create an NRS
            Systems or XSPONSE account.
          </li>
          <li>
            <b>Transaction and Billing Information:</b> If you buy something
            from us – a sensing device, an IoT button or a display device – you
            will provide additional personal and payment information that is
            required to process the transaction and your payment, such as your
            name, credit card information, and contact information.
          </li>
          <li>
            <b>Communications with Us:</b> You may also provide us information
            when you respond to surveys, communicate with our Engineers about a
            support question, post a question in our public forums, sign up to
            receive our educational materials or sign up for a newsletter. When
            you communicate with us via form, email, phone, forum, or otherwise,
            we store a copy of our communications (including any call recordings
            as permitted by applicable law).
          </li>
        </ul>
        <h4 className="text-color">
          <em>Information We Collect Automatically</em>
        </h4>
        We also collect some information automatically:
        <ul>
          <li>
            <b>Log Information:</b> Like most online service providers, we
            collect information that web browsers, mobile devices, and servers
            typically make available, such as the browser type, IP address,
            unique device identifiers, language preference, referring site, the
            date and time of access, operating system, and mobile network
            information. We collect log information when you use our
            Services–for example, when you create or make changes to your
            account on XSPONSE.com.
          </li>
          <li>
            <b>Usage Information:</b> We collect information about your usage of
            our Services. For example, we collect information about the actions
            that site administrators and users perform on our site for tracking
            and audit purposes. We use this information to, for example, provide
            our Services to you, as well as get insights on how people use our
            Services, so we can make our Services better.
          </li>
          <li>
            <b>Location Information:</b> We may determine the approximate
            location of your device from your IP address. We collect and use
            this information to, for example, calculate how many people visit
            our Services from certain geographic regions. We may also collect
            information about your precise location via our mobile apps (when,
            for example, you post a photograph with location information) if you
            allow us to do so through your mobile device operating system’s
            permissions. For some of our services, you may enter the exact
            location of one of our devices so that we may tailor the messages
            sent by it appropriately.
          </li>
          <li>
            <b>Stored Information:</b> We may access information stored on your
            mobile device via our mobile apps. We access this stored information
            through your device operating system’s permissions. For example, if
            you give us permission to access the photographs on your mobile
            device’s camera roll, our Services may access the photos stored on
            your device when you upload a really amazing photograph of the
            sunrise to your website.
          </li>
          <li>
            <b>Information from Cookies & Other Technologies:</b> A cookie is a
            string of information that a website stores on a visitor’s computer,
            and that the visitor’s browser provides to the website each time the
            visitor returns.
          </li>
        </ul>
        <br />
        <h3 className="text-color">How and Why We Use Information</h3>
        <h4 className="text-color">
          <em>Purposes for Using Information</em>
        </h4>
        We use information about you as mentioned above and for the purposes
        listed below:
        <ul>
          <li>
            To provide our Services–for example, to set up and maintain your
            account, or charge you for any of our paid Services;
          </li>
          <li>
            To further develop and improve our Services–for example, by adding
            new features that we think our users will enjoy or will help them to
            create and manage their devices and action plans more efficiently;
          </li>
          <li>
            To monitor and analyze trends and better understand how users
            interact with our Services, which helps us improve our Services and
            make them easier to use;
          </li>
          <li>
            To measure, gauge, and improve the effectiveness of our advertising,
            and better understand user retention and attrition — for example, we
            may analyze how many individuals purchased a paid plan after
            receiving a marketing message or the features used by those who
            continue to use our Services after a certain length of time;
          </li>
          <li>
            To monitor and prevent any problems with our Services, protect the
            security of our Services, detect and prevent fraudulent transactions
            and other illegal activities, fight spam, and protect the rights and
            property of NRS Systems and others, which may result in us declining
            a transaction or the use of our Services;
          </li>
          <li>
            To communicate with you, for example through an email, about offers
            and promotions offered by NRS Systems and others we think will be of
            interest to you, solicit your feedback, or keep you up to date on
            NRS Systems and our products (which you can unsubscribe from at any
            time); and
          </li>
          <li>
            To personalize your experience using our Services, provide content
            recommendations, target our marketing messages to groups of our
            users (for example, those who have a particular plan with us or have
            been our user for a certain length of time), and serve relevant
            advertisements.
          </li>
        </ul>
        <h4 className="text-color">
          <em>Legal Bases for Collecting and Using Information</em>
        </h4>
        A note here for those in the European Union about our legal grounds for
        processing information about you under EU data protection laws, which is
        that our use of your information is based on the grounds that: (1) The
        use is necessary in order to fulfill our commitments to you under the
        applicable terms of service or other agreements with you or is necessary
        to administer your account — for example, in order to enable access to
        our website on your device or charge you for a paid plan; or (2) The use
        is necessary for compliance with a legal obligation; or (3) The use is
        necessary in order to protect your vital interests or those of another
        person; or (4) We have a legitimate interest in using your information —
        for example, to provide and update our Services; to improve our Services
        so that we can offer you an even better user experience; to safeguard
        our Services; to communicate with you; to measure, gauge, and improve
        the effectiveness of our advertising; and to understand our user
        retention and attrition; to monitor and prevent any problems with our
        Services; and to personalize your experience; or (5) You have given us
        your consent.
        <br />
        <br />
        <h3 className="text-color">Sharing Information</h3>
        <h4 className="text-color">
          <em>How We Share Information</em>
        </h4>
        We do not sell our users’ private personal information. We share
        information about you in the limited circumstances spelled out below and
        with appropriate safeguards on your privacy:
        <ul>
          <li>
            <b>Subsidiaries, Employees, and Independent Contractors:</b> We may
            disclose information about you to our subsidiaries, our employees,
            and individuals who are our independent contractors that need to
            know the information in order to help us provide our Services or to
            process the information on our behalf. We require our subsidiaries,
            employees, and independent contractors to follow this Privacy Policy
            for personal information that we share with them.
          </li>
          <li>
            <b>Third Party Vendors:</b> We may share information about you with
            third party vendors who need to know information about you in order
            to provide their services to us, or to provide their services to you
            or your site. This group includes vendors that help us provide our
            Services to you (like payment providers that process your credit and
            debit card information, payment providers you use for your ecommerce
            operations, fraud prevention services that allow us to analyze
            fraudulent payment transactions, postal and email delivery services
            that help us stay in touch with you, customer chat and email support
            services that help us communicate with you, those that assist us
            with our marketing efforts (e.g. by providing tools for identifying
            a specific marketing target group or improving our marketing
            campaigns), those that help us understand and enhance our Services
            (like analytics providers), and companies that make products
            available on our websites, who may need information about you in
            order to, for example, provide technical or other support services
            to you. We require vendors to agree to privacy commitments in order
            to share information with them. Other vendors are listed in our more
            specific policies.
          </li>
          <li>
            <b>Legal and Regulatory Requirements:</b> We may disclose
            information about you in response to a subpoena, court order, or
            other governmental request.
          </li>
          <li>
            <b>To Protect Rights, Property, and Others:</b> We may disclose
            information about you when we believe in good faith that disclosure
            is reasonably necessary to protect the property or rights of NRS
            Systems, third parties, or the public at large. For example, if we
            have a good faith belief that there is an imminent danger of death
            or serious physical injury, we may disclose information related to
            the emergency without delay.
          </li>
          <li>
            <b>Business Transfers:</b> In connection with any merger, sale of
            company assets, or acquisition of all or a portion of our business
            by another company, or in the unlikely event that NRS Systems goes
            out of business or enters bankruptcy, user information would likely
            be one of the assets that is transferred or acquired by a third
            party. If any of these events were to happen, this Privacy Policy
            would continue to apply to your information and the party receiving
            your information may continue to use your information, but only
            consistent with this Privacy Policy.
          </li>
          <li>
            <b>With Your Consent:</b> We may share and disclose information with
            your consent or at your direction. For example, we may share your
            information with third parties with which you authorize us to do so.
          </li>
          <li>
            <b>Aggregated or De-Identified Information:</b> We may share
            information that has been aggregated or reasonably de-identified, so
            that the information could not reasonably be used to identify you.
            For instance, we may publish aggregate statistics about the use of
            our Services and we may share a hashed version of your email address
            to facilitate customized ad campaigns on other platforms.
          </li>
          <li>
            <b>Published Support Requests:</b> And if you send us a request (for
            example, via a support email or one of our feedback mechanisms), we
            reserve the right to publish that request in order to help us
            clarify or respond to your request or to help us support other
            users.
          </li>
        </ul>
        <br />
        <h3 className="text-color">How Long We Keep Information</h3>
        We generally discard information about you when we no longer need the
        information for the purposes for which we collect and use it — which are
        described in the section above on How and Why We Use Information — and
        we are not legally required to continue to keep it. For example, we keep
        the web server logs that record information about a visitor to one of
        NRS System’s websites, such as the visitor’s IP address, browser type,
        and operating system, for approximately 30 days. We retain the logs for
        this period of time in order to, among other things, analyze traffic to
        NRS System’s websites and investigate issues if something goes wrong on
        one of our websites.
        <br />
        <br />
        <h3 className="text-color">Security</h3>
        While no online service is 100% secure, we work very hard to protect
        information about you against unauthorized access, use, alteration, or
        destruction, and take reasonable measures to do so, such as monitoring
        our Services for potential vulnerabilities and attacks.
        <br />
        <br />
        <h3 className="text-color">Choices</h3>
        You have several choices available when it comes to information about
        you:
        <ul>
          <li>
            <b>Limit the Information that You Provide:</b> If you have an
            account with us, you can choose not to provide the optional account
            information, profile information, and transaction and billing
            information. Please keep in mind that if you do not provide this
            information, certain features of our Services — for example, paid
            services, –may not be accessible.
          </li>
          <li>
            <b>Opt-Out of Marketing Communications:</b> You may opt out of
            receiving promotional communications from us. Just follow the
            instructions in those communications or let us know. If you opt out
            of promotional communications, we may still send you other
            communications, like those about your account and legal notices.
          </li>
          <li>
            <b>Set Your Browser to Reject Cookies:</b> At this time, Automattic
            does not respond to “do not track” signals across all of our
            Services. However, you can usually choose to set your browser to
            remove or reject browser cookies before using Automattic’s websites,
            with the drawback that certain features of Automattic’s websites may
            not function properly without the aid of cookies.
          </li>
          <li>
            <b>Close Your Account:</b> While we’d be very sad to see you go, if
            you no longer want to use our Services, you can close your account.
            Please keep in mind that we may continue to retain your information
            after closing your account, as described in How Long We Keep
            Information above — for example, when that information is reasonably
            needed to comply with (or demonstrate our compliance with) legal
            obligations such as law enforcement requests, or reasonably needed
            for our legitimate business interests.
          </li>
        </ul>
        <br />
        <h3 className="text-color">Your Rights</h3>
        If you are located in certain countries, including those that fall under
        the scope of the European General Data Protection Regulation (AKA the
        “GDPR”), data protection laws give you rights with respect to your
        personal data, subject to any exemptions provided by the law, including
        the rights to:
        <ul>
          <li>Request access to your personal data;</li>
          <li>Request correction or deletion of your personal data;</li>
          <li>Object to our use and processing of your personal data;</li>
          <li>
            Request that we limit our use and processing of your personal data;
            and
          </li>
          <li>Request portability of your personal data.</li>
        </ul>
        You can usually access, correct, or delete your personal data using your
        account settings and tools that we offer, but if you aren’t able to do
        that, or you would like to contact us about one of the other rights,
        scroll down to How to Reach Us to, well, find out how to reach us. EU
        individuals also have the right to make a complaint to a government
        supervisory authority.
        <br />
        <br />
        <h3 className="text-color">Credit</h3>
        Our privacy policy is based on Automaticc.com’s. They have made their
        privacy policy available under a Creative Commons Sharealike license.
        NRS Systems is a huge fan of companies helping each other out so we
        appreciate Automattic doing this. Please visit their site at
        http://www.automatticc.com
      </div>
    </div>
  );
}
