import React from "react";
import { Switch, Route, Link, Redirect, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";

import NavButton from "../router/NavButton";
import MyAccount from "../views/MyAccount";
import MyArtwork from "../views/MyArtwork";
import MyFavorites from "../views/MyFavorites";
import MyGalleries from "../views/MyGalleries";
import SourceDetail from "../components/details/SourceDetail";

export default function AccountRoutes() {
  const user = useSelector((state) => state.user);
  let { path, url } = useRouteMatch();

  return (
    <div className="flex-row-collapse">
      <div
        className="flex-row-container-xs"
        style={{ display: "flex", position: "relative" }}
      >
        <div
          className="account-router"
          style={{
            border: "1px solid white",
            margin: "15px",
            borderRadius: "15px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <Link to={url}>
            <NavButton name="My Account" routes={[url]} />
          </Link>
          <Link to={`${url}/favorites`}>
            <NavButton name="My Favorites" routes={[`${url}/favorites`]} />
          </Link>
          <Link to={`${url}/galleries`}>
            <NavButton name="My Galleries" routes={[`${url}/galleries`]} />
          </Link>
          <Link to={`${url}/artworks`}>
            <NavButton name="My Artworks" routes={[`${url}/artworks`]} />
          </Link>
          {user.source && (
            <Link to={`${url}/curations`}>
              <NavButton name="My Curations" routes={[`${url}/curations`]} />
            </Link>
          )}
        </div>
      </div>
      <div style={{ display: "block", width: "100%" }}>
        <Switch>
          <Route exact path={path}>
            <MyAccount />
          </Route>
          <Route path={`${path}/favorites`}>
            <MyFavorites />
          </Route>
          <Route path={`${path}/galleries`}>
            <MyGalleries />
          </Route>
          <Route path={`${path}/artworks`}>
            <MyArtwork />
          </Route>
          {user.source && (
            <Route>
              <SourceDetail
                id={user.source}
                addObject={() => {}}
                removeObject={() => {}}
              />
            </Route>
          )}
          <Redirect to={path} />
        </Switch>
      </div>
    </div>
  );
}
