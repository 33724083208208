import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Table, Space, Modal, Button, Popconfirm, message } from "antd";
import { EyeOutlined, DeleteOutlined } from "@ant-design/icons";

import api from "../utility/api";
import urls from "../utility/urls";

const { Column } = Table;

export default function Notification() {
  const [visible, setVisible] = useState(false);
  const [notification, setNotification] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    api.get(urls.notification()).then((response) => {
      if (response) {
        setData(response.results);
        setLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    var count = data.filter(obj => !obj.read).length;
    dispatch({type: "SET_NOTIFICATIONCOUNT", notificationCount: count})
    // eslint-disable-next-line
  }, [data])

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "15px",
      }}
    >
      <div className="header-text" style={{ marginBottom: "25px" }}>
        Notifications
      </div>
      <Table
        dataSource={data}
        style={{ width: "80%" }}
        loading={loading}
        rowKey="id"
        bordered
      >
        <Column
          title="Title"
          dataIndex="title"
          key="title"
          render={(text, record) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {!record.read && (
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "8px",
                    background: "#fa3c3c",
                    marginRight: "10px",
                  }}
                />
              )}
              {record.title}
            </div>
          )}
        />
        <Column
          title="Timestamp"
          dataIndex="sent"
          key="sent"
          responsive={["sm"]}
          render={(text, record) => new Date(record.sent).toLocaleString()}
        />
        <Column
          title="Action"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <Button
                onClick={() => {
                  setNotification(record);
                  setVisible(true);
                  if (!record.read) {
                    api
                      .patch(urls.notification(record.id), { read: true })
                      .then((response) => {
                        if (response) {
                          const id = record.id;
                          let arr = [...data];
                          arr.find((obj, i) => {
                            if (obj.id === id) {
                              arr[i] = response;
                              setData(arr);
                              return true;
                            }
                            return false;
                          });
                        }
                      });
                  }
                }}
                icon={<EyeOutlined />}
              />
              <Popconfirm
                title="Delete this notification?"
                onConfirm={() => {
                  api.delete(urls.notification(record.id)).then((response) => {
                    if (response !== null) {
                      var temp = [...data];
                      setData(temp.filter((obj) => obj.id !== record.id));
                      message.success("Notification Deleted");
                    }
                  });
                }}
              >
                <Button
                  icon={<DeleteOutlined style={{ color: "#fa3c3c" }} />}
                />
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
      <Modal
        title={notification.title}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <div className="sub-text">{notification.message}</div>
      </Modal>
    </div>
  );
}
