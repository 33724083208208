import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Skeleton, Button, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import ArtMasonry from "../layout/ArtMasonry";
import urls from "../../utility/urls";
import ImageCard from "../cards/ImageCard";
import ArtworkDetail from "./ArtworkDetail";
import api from "../../utility/api";

const { Text } = Typography;

export default function SourceDetail(props) {
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  let history = useHistory();

  useEffect(() => {
    if (props.id) {
      api.get(urls.source(props.id)).then((response) => {
        if (response) {
          setData(response);
          setLoading(false);
        }
      });
    }
  }, [props.id, user]);

  return (
    <div>
      <Skeleton
        active
        paragraph={{ rows: 10 }}
        loading={loading}
        style={{ display: "flex", flexDirection: "column", width: "100%" }}
      >
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <Text className="header-text" style={{ paddingLeft: "15px" }}>
            {data.name}
          </Text>
          {data.user === user.id && (
            <div
              style={{
                marginBottom: "15px",
                marginLeft: "15px",
              }}
            >
              <Button
                className="flex-row-item"
                icon={<PlusOutlined />}
                onClick={() => history.push("/create/?mode=source")}
              >
                Add
              </Button>
            </div>
          )}
        </div>
        <ArtMasonry
          url={urls.artwork(null, { source: props.id })}
          lightbox
          objectType={ImageCard}
          modalType={ArtworkDetail}
          owner={data.user}
          gridEdit
        />
      </Skeleton>
    </div>
  );
}
