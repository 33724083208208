import React from "react";
import { useParams } from "react-router-dom";

import ArtworkDetail from "../components/details/ArtworkDetail";

export default function Artwork() {
  let { id } = useParams();

  return (
    <ArtworkDetail
      id={id}
      addObject={() => {}}
      removeObject={() => {}}
      updateObject={() => {}}
    />
  );
}
