import React, { useState } from "react";
import { Modal, Space, List, Comment, Input, Button, Form } from "antd";
import { MessageOutlined } from "@ant-design/icons";

const { TextArea } = Input;

export default function MessageModal(props) {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    if (values.message) {
      props.updateField(values.message);
      form.resetFields();
    }
  };

  return (
    <>
      <Space
        className="sub-description"
        onClick={() => setVisible(!visible)}
        style={{ cursor: "pointer" }}
      >
        <MessageOutlined />
        {props.messages.length}
      </Space>
      <Modal visible={visible} footer={null} onCancel={() => setVisible(false)}>
        <List
          style={{
            border: "1px solid white",
            padding: "15px",
            marginTop: "25px",
            marginBottom: "15px",
            overflowY: "auto",
            maxHeight: "50vh",
          }}
          dataSource={props.messages}
          itemLayout="horizontal"
          renderItem={(item) => (
            <li>
              <Comment
                author={item.user.display_name}
                content={item.message.split("\n").map((str, i) => (
                  <p key={i}>{str}</p>
                ))}
                datetime={new Date(item.sent).toLocaleString([], {
                  timeStyle: "short",
                  dateStyle: "short",
                })}
              />
            </li>
          )}
        />
        <Form form={form} onFinish={onFinish}>
          <Form.Item name="message" style={{ marginBottom: "15px" }}>
            <TextArea maxLength={2000} />
          </Form.Item>
          <Form.Item style={{ margin: 0 }}>
            <Button type="primary" htmlType="submit">
              Send Message
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
