import React, { useContext, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import { Modal, Form, Select, Input, message, Checkbox } from "antd";

import { GalleryContext } from "../../wrappers/GalleryContext";
import api from "../../utility/api";
import { LoginContext } from "../../wrappers/LoginContext";
import urls from "../../utility/urls";

const { Option } = Select;

export default function GalleryModal() {
  const user = useSelector((state) => state.user);
  const [galleries, setGalleries] = useState([]);
  let { visible, artworkId, setGalleryModal } = useContext(GalleryContext);
  const { setLoginVisible } = useContext(LoginContext);
  const [form] = Form.useForm();

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        if (values.gallery === "default") {
          api
            .post(urls.gallery(), {
              name: values.name,
              public: values.public,
              artwork: [artworkId],
            })
            .then((response) => {
              if (response) {
                message.success("Gallery Created");
                setGalleryModal(null);
                localStorage.setItem("lastGallery", response.id);
              }
            });
        } else {
          api
            .patch(urls.gallery(values.gallery), {
              add: artworkId,
            })
            .then((response) => {
              if (response) {
                message.success("Added To Gallery");
                setGalleryModal(null);
                localStorage.setItem("lastGallery", values.gallery);
              }
            });
        }
      })
      .catch(() => {
        form.resetFields();
      });
  };

  const idExists = (gallery) =>
    gallery.id === parseInt(localStorage.getItem("lastGallery"));

  useEffect(() => {
    if (!user && artworkId) {
      setGalleryModal(null);
      setLoginVisible(true);
    } else if (user && !user.active) {
      setGalleryModal(null);
      setLoginVisible("verify");
    } else if (user && artworkId) {
      api
        .retrieve(urls.gallery(null, { creator: user.id, filters: "exclude" }))
        .then((response) => {
          if (response) {
            setGalleries(response);
            form.resetFields();
          }
        });
    }
    // eslint-disable-next-line
  }, [artworkId, setGalleryModal, setLoginVisible, user]);

  if (visible) {
    return ReactDOM.createPortal(
      <Modal
        title="Add to Gallery"
        visible={true}
        onCancel={() => setGalleryModal(false)}
        onOk={onFinish}
      >
        <Form
          form={form}
          initialValues={
            galleries.some(idExists)
              ? { gallery: parseInt(localStorage.getItem("lastGallery")) }
              : {}
          }
        >
          <Form.Item name="gallery" label="Select Gallery">
            <Select
              showSearch
              placeholder="Select a Gallery"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option key={0} value="default">
                Create New Gallery
              </Option>
              {galleries.map((gallery) => (
                <Option key={gallery.id} value={gallery.id}>
                  {gallery.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            style={{ margin: 0, minHeight: 0 }}
            shouldUpdate={(prevValues, curValues) =>
              prevValues.gallery !== curValues.gallery
            }
          >
            {() => {
              return (
                <>
                  <Form.Item
                    label="New Gallery"
                    name="name"
                    hidden={form.getFieldValue("gallery") !== "default"}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="Public" name="public" valuePropName="checked" hidden={form.getFieldValue("gallery") !== "default"}>
                    <Checkbox style={{marginTop: '5px'}} />
                  </Form.Item>
                </>
              );
            }}
          </Form.Item>
        </Form>
      </Modal>,
      document.querySelector("#gallery-root")
    );
  } else {
    return null;
  }
}
