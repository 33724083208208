import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { Typography, Pagination, Space, Input } from "antd";

import api from "../../utility/api";
import urls from "../../utility/urls";
import Settings from "./Settings";

const { Text } = Typography;
const { Search } = Input;

export default function ArtList(props) {
  const [objects, setObjects] = useState([]);
  const [count, setCount] = useState(0);
  const [next, setNext] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const add = () => {
    if (next ? true : false) {
      setLoading(true);
      setNext(null);
      api.get(next).then((response) => {
        if (response) {
          setCount(response.count);
          var temp = [...objects];
          var results = response.results;
          temp = temp.concat(results);
          const set = new Set();
          temp = temp.filter((el) => {
            const duplicate = set.has(el.id);
            set.add(el.id);
            return !duplicate;
          });
          setObjects(temp);
          setLoading(false);
          setNext(response.next);
        } else {
          setLoading(false);
        }
      });
    }
  };

  const updateObject = (data) => {
    const id = data.id;
    let arr = [...objects];
    arr.find((obj, i) => {
      if (obj.id === id) {
        arr[i] = data;
        setObjects(arr);
        return true;
      }
      return false;
    });
  };

  const removeObject = (id) => {
    var temp = [...objects];
    setObjects(temp.filter((obj) => obj.id !== id));
    setCount(count - 1);
  };

  const changePage = (pg, sz) => {
    var params;

    if (pg !== page) {
      params = new URLSearchParams();
      params.set("offset", pageSize * (pg - 1));
      setPage(pg);
      setObjects([]);
      setNext(urls.getUrl(props.url, urls.getParams(params)));
    }

    if (sz !== pageSize) {
      if (pg === 1) {
        setPageSize(sz);
      } else {
        params = new URLSearchParams();
        params.set("offset", pageSize * (pg - 1));
        setPage(pg);
        setPageSize(sz);
        setObjects([]);
        setNext(urls.getUrl(props.url, urls.getParams(params)));
      }
    }
  };

  const onSearch = (val) => {
    setObjects([]);
    setNext(
      `${props.url.href}${props.url.href.includes("?") ? "&" : "?"}${
        props.search
      }=${val}`
    );
  };

  useEffect(() => {
    setObjects([]);
    setNext(props.url.href);
    // eslint-disable-next-line
  }, [props.url.href]);

  return (
    <InfiniteScroll
      loadMore={add}
      hasMore={
        next &&
        (props.pagination
          ? objects.slice(0, pageSize).length < pageSize
          : true) &&
        !loading
      }
      initialLoad={true}
      threshold={500}
      useWindow={props.customScroll ? false : true}
      getScrollParent={() =>
        document.getElementsByClassName(props.customScroll)[0]
      }
    >
      {props.search && (
        <Search
          style={{ marginTop: "25px" }}
          enterButton
          allowClear
          onSearch={onSearch}
        />
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div className="text-box">
          {props.title && <Text className="header-text">{props.title}</Text>}
          <Space
            className="flex-row-collapse"
            style={{ display: "flex", alignItems: "self-start" }}
          >
            {props.subtitle && (
              <Text className="sub-text">{props.subtitle}</Text>
            )}
            {props.results && (
              <Text className="sub-text">{`${count
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Results`}</Text>
            )}
          </Space>
        </div>
        {props.settings && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              float: "right",
              marginRight: "15px",
            }}
          >
            <Settings />
          </div>
        )}
      </div>

      <div style={{ padding: "15px" }}>
        {props.pagination && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "15px",
            }}
          >
            <Pagination
              total={count}
              current={page}
              pageSize={pageSize}
              simple
              hideOnSinglePage
              onChange={changePage}
            />
          </div>
        )}
        <props.component
          loading={loading}
          objects={props.pagination ? objects.slice(0, pageSize) : objects}
          updateObject={updateObject}
          removeObject={removeObject}
          options={props.options}
        />
        {props.pagination && (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              marginBottom: "25px",
              marginTop: "25px",
            }}
          >
            <Pagination
              total={Math.min(count, 10000)}
              hideOnSinglePage
              pageSize={pageSize}
              current={page}
              onChange={changePage}
            />
          </div>
        )}
      </div>
    </InfiniteScroll>
  );
}
