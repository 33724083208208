import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { useSpring, animated } from "react-spring";
import { Card, Typography, message, Skeleton } from "antd";
import { StarOutlined } from "@ant-design/icons";
import formatter from "../../utility/formatter";
import CardIcon from "./CardIcon";
import api from "../../utility/api";
import urls from "../../utility/urls";
import SmartGallery from "../layout/react-smart-gallery/SmartGallery";
import { LoginContext } from "../../wrappers/LoginContext";

const { Text } = Typography;

export default function ArtistCard(props) {
  const user = useSelector((state) => state.user);
  const scenic = useSelector((state) => state.scenic);
  const [hover, setHover] = useState(false);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [images, setImages] = useState([]);
  const [favorite, setFavorite] = useState(props.data.favorite);
  const [favoriteHover, setFavoriteHover] = useState(false);
  let { setLoginVisible } = useContext(LoginContext);

  const icon = useSpring({ opacity: hover ? 1 : 0, right: hover ? 20 : 0 });
  const textbox = useSpring({ opacity: hover ? 1 : 0, bottom: hover ? 15 : 0 });
  const overlay = useSpring({ opacity: hover ? 0.8 : 1, width: "100%" });

  const handleFavorite = () => {
    if (user && user.active) {
      if (favorite) {
        api.delete(urls.favorite(favorite)).then((response) => {
          if (response != null) {
            message.success("Artist Removed from Favorites");
            setFavorite(null);
            if (!props.owner) {
              props.removeObject(props.data.id);
            }
          }
        });
      } else {
        api
          .post(urls.favorite(), { model: "Artist", object_id: props.data.id })
          .then((response) => {
            if (response) {
              message.success("Artist Added to Favorites");
              setFavorite(response.id);
            }
          });
      }
    } else {
      if (user) {
        setLoginVisible("verify");
      } else {
        setLoginVisible(true);
      }
    }
  };

  const openModal = () => {
    props.openModal(props.data.id);
  };

  useEffect(() => {
    if (!props.data.photo) {
      api
        .get(urls.artwork(null, { artist: props.data.id }))
        .then((response) => {
          if (response) {
            setCount(response.count);
            setImages(formatter.galleryImages(response.results));
          }
        });
    }
  }, [props.data.id, props.data.photo]);

  return (
    <div
      className={scenic ? "card-scenic scaler" : "card scaler"}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {props.data.photo ? (
        <div
          style={{
            background: "black",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              zIndex: scenic ? 1 : 0,
            }}
            onClick={openModal}
          />
          <animated.img
            alt={props.data.name}
            onLoad={() => {
              setLoading(false);
              try {
                props.updateGrid();
              } catch (error) {
                console.log(error)
              }
            }}
            src={props.data.photo}
            className="image"
            style={scenic ? overlay : null}
          />
        </div>
      ) : (
        <div
          style={{
            background: "black",
            flexDirection: "column",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
          onClick={openModal}
        >
          <div
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              zIndex: scenic ? 1 : 0,
            }}
          />
          {loading && <Skeleton active paragraph={{ rows: 8 }} />}
          {images.length !== 0 && (
            <animated.div
              className="image"
              style={scenic ? overlay : { width: "100%" }}
            >
              <SmartGallery
                total={count}
                width="100%"
                height={450}
                onLoad={() => {
                  setLoading(false);
                }}
                images={images}
              />
            </animated.div>
          )}
        </div>
      )}
      {scenic ? (
        <div style={{ zIndex: 2 }}>
          <animated.div style={icon} className="icon-box">
            <CardIcon
              color={favorite ? "#ffd700" : "#5B5F65"}
              text={favorite ? "Remove Favorite" : "Add to Favorites"}
              icon={<StarOutlined style={{ margin: "10px", color: "white" }} />}
              onclick={handleFavorite}
            />
          </animated.div>
          <animated.div
            className="text-box-scenic"
            style={textbox}
            onClick={openModal}
          >
            <Text className="main-text light">
              {formatter.artist(props.data)}
            </Text>
            <Text
              className="sub-text light"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {props.data.nationality ? props.data.nationality.name : null}
            </Text>
          </animated.div>
        </div>
      ) : (
        <Card
          actions={[
            <StarOutlined
              className="hover-icon"
              onMouseEnter={() => setFavoriteHover(true)}
              onMouseLeave={() => setFavoriteHover(false)}
              style={favorite || favoriteHover ? { color: "#ffd700" } : {}}
              onClick={handleFavorite}
            />,
          ]}
        >
          <div className="text-box" onClick={openModal}>
            <Text className="main-text">{formatter.artist(props.data)}</Text>
            <Text
              className="sub-text"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {props.data.nationality ? props.data.nationality.name : null}
            </Text>
          </div>
        </Card>
      )}
    </div>
  );
}
