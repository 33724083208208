import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Form, Input, InputNumber, Select, Switch, message } from "antd";
import { EditOutlined } from "@ant-design/icons";

import ArtSelect from "../layout/ArtSelect";
import urls from "../../utility/urls";
import api from "../../utility/api";

const { Option } = Select;

export default function ArtworkFormModal(props) {
  const getInitial = () => {
    return {
      location: props.initial.location ? props.initial.location.id : null,
      artist: props.initial.artist ? props.initial.artist.id : null,
      source: props.initial.source ? props.initial.source.id : null,
      tags: props.initial.tags.map((item) => item.id),
    };
  };

  const user = useSelector((state) => state.user);
  const [visible, setVisible] = useState(false);
  const [values, setValues] = useState(getInitial);
  const [form] = Form.useForm();

  const onFinish = () => {
    form.validateFields().then((val) => {
      var data = Object.assign({}, val, values);
      api.patch(urls.artwork(props.initial.id), data).then((response) => {
        if (response) {
          props.updateArtwork(response);
          setVisible(false);
          message.success("Artwork Updated");
        }
      });
    });
  };

  const updateValue = (key, val) => {
    if (Array.isArray(val)) {
      setValues(
        Object.assign({}, values, { [key]: val.map((item) => item.id) })
      );
    } else {
      setValues(Object.assign({}, values, { [key]: val ? val.id : null }));
    }
  };

  return (
    <>
      <EditOutlined
        onClick={() => setVisible(true)}
        style={{ color: "#fa3c3c", cursor: "pointer" }}
      />
      <Modal
        title="Edit Artwork"
        visible={visible}
        onOk={onFinish}
        onCancel={() => setVisible(false)}
      >
        <Form form={form} initialValues={props.initial}>
          <Form.Item name="title" label="Title">
            <Input maxLength={256} />
          </Form.Item>
          <Form.Item name="year" label="Year">
            <InputNumber />
          </Form.Item>
          <Form.Item name="location" label="Location">
            <ArtSelect
              name="location"
              title="Location"
              display="location"
              url={urls.location()}
              initial={props.initial.location}
              search="name"
              updateValue={updateValue}
            />
          </Form.Item>
          {(user.admin ||
            (props.initial.source &&
              props.initial.source.id === user.source)) && (
            <Form.Item label="Artist">
              <ArtSelect
                name="artist"
                title="Artist"
                display="name"
                url={urls.artist()}
                initial={props.initial.artist}
                search="name"
                updateValue={updateValue}
              />
            </Form.Item>
          )}
          <Form.Item name="dimensions" label="Dimensions">
            <Input maxLength={128} />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input.TextArea maxLength={2000} />
          </Form.Item>
          <Form.Item name="license_type" label="Licensing">
            <Select>
              <Option value="CC0">Creative Commons</Option>
              <Option value="RF">Royalty Free</Option>
              <Option value="P">Public Domain</Option>
              <Option value="E">Editorial Use</Option>
            </Select>
          </Form.Item>
          {user.admin && (
            <Form.Item label="Source">
              <ArtSelect
                name="source"
                title="Source"
                display="name"
                url={urls.source()}
                initial={props.initial.source}
                search="name"
                updateValue={updateValue}
              />
            </Form.Item>
          )}
          <Form.Item name="nudity" label="Nudity" valuePropName="checked">
            <Switch />
          </Form.Item>
          {((props.initial.artist && props.initial.artist.id === user.artist) ||
            (props.initial.source &&
              props.initial.source.id === user.source)) && (
            <Form.Item name="public" label="Public" valuePropName="checked">
              <Switch />
            </Form.Item>
          )}
          <Form.Item label="Tags">
            <ArtSelect
              name="tags"
              title="Tags"
              display="description"
              url={urls.tag()}
              initial={props.initial.tags}
              search="name"
              updateValue={updateValue}
              many
            />
          </Form.Item>
          {user.admin && (
            <>
              <Form.Item
                name="published"
                label="Published"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
              <Form.Item name="status" label="Status">
                <Select>
                  <Option value="S">Submitted</Option>
                  <Option value="I">In-Review</Option>
                  <Option value="A">Approved</Option>
                  <Option value="E">Edit Needed</Option>
                  <Option value="R">Rejected</Option>
                  <Option value="C">Changed</Option>
                </Select>
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
    </>
  );
}
