import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  Upload,
  Switch,
  Select,
  message,
} from "antd";
import { EditOutlined } from "@ant-design/icons";

import ArtSelect from "../layout/ArtSelect";
import urls from "../../utility/urls";
import api from "../../utility/api";

const { Option } = Select;

export default function ArtistFormModal(props) {
  const user = useSelector((state) => state.user);
  const [visible, setVisible] = useState(false);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onFinish = () => {
    form.validateFields().then((values) => {
      setLoading(true);
      var data = new FormData();
      if (props.user) {
        data.append("user", props.user);
      }
      data.append("name", values.name);
      data.append(
        "birth_year",
        values.birth_year && values.birth_year !== 0 ? values.birth_year : null
      );
      if (props.initial ? !props.user && !props.initial.user : !props.user) {
        data.append(
          "death_year",
          values.death_year && values.death_year !== 0
            ? values.death_year
            : null
        );
      }
      if (props.initial && !("birth_place" in formData)) {
        //pass
      } else {
        data.append(
          "birth_place",
          formData.birth_place ? formData.birth_place.id : null
        );
      }
      if (props.initial && !("nationality" in formData)) {
        //pass
      } else {
        data.append(
          "nationality",
          formData.nationality ? formData.nationality.id : null
        );
      }
      data.append("bio", values.bio ? values.bio : null);
      data.append(
        "official_site",
        values.official_site ? values.official_site : null
      );
      if (user.admin) {
        data.append("published", values.published);
        data.append("status", values.status);
      }
      if (props.initial && !form.isFieldTouched("photo")) {
        //pass
      } else {
        data.append(
          "photo",
          values.photo && values.photo.length > 0
            ? values.photo[0].originFileObj
            : null
        );
      }
      if (props.initial) {
        api.patch(urls.artist(props.initial.id), data).then((response) => {
          if (response) {
            props.updateObject(response);
            setVisible(false);
            message.success("Artist Updated");
          }
          setLoading(false);
        });
      } else {
        api.post(urls.artist(), data).then((response) => {
          if (response) {
            props.addValue(response);
            setVisible(false);
          }
          setLoading(false);
        });
      }
    });
  };

  return (
    <>
      {props.initial ? (
        <>
          {props.button ? (
            <Button
              className="flex-row-item"
              icon={<EditOutlined />}
              onClick={() => setVisible(true)}
            >
              Edit
            </Button>
          ) : (
            <EditOutlined
              style={{ fontSize: "16px", color: "#fa3c3c", cursor: "pointer" }}
              onClick={() => setVisible(true)}
            />
          )}
        </>
      ) : (
        <Button type="primary" onClick={() => setVisible(true)}>
          {props.title}
        </Button>
      )}
      <Modal
        visible={visible}
        title={props.title}
        onCancel={() => setVisible(false)}
        onOk={onFinish}
        okButtonProps={{ loading: loading }}
      >
        <Form
          form={form}
          initialValues={
            props.initial
              ? Object.assign({}, props.initial, {
                  photo: props.initial.photo
                    ? [
                        {
                          uid: "1",
                          name: "Current Photo",
                          url: props.initial.photo,
                        },
                      ]
                    : [],
                })
              : null
          }
        >
          <Form.Item name="name" label="Artist Name" required>
            <Input />
          </Form.Item>
          <Form.Item name="birth_year" label="Birth Year">
            <InputNumber />
          </Form.Item>
          {(props.initial
            ? !props.user && !props.initial.user
            : !props.user) && (
                <Form.Item name="death_year" label="Death Year">
                  <InputNumber />
                </Form.Item>
              )}
          <Form.Item label="Origin">
            <ArtSelect
              name="birth_place"
              title="Location"
              display="location"
              url={urls.location()}
              search="name"
              updateValue={(key, val) =>
                setFormData(Object.assign({}, formData, { [key]: val }))
              }
              initial={props.initial ? props.initial.birth_place : null}
            />
          </Form.Item>
          <Form.Item label="Nationality">
            <ArtSelect
              name="nationality"
              title="Nationality"
              display="name"
              url={urls.nationality()}
              search="name"
              updateValue={(key, val) =>
                setFormData(Object.assign({}, formData, { [key]: val }))
              }
              initial={props.initial ? props.initial.nationality : null}
            />
          </Form.Item>
          <Form.Item name="bio" label="Bio">
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="official_site"
            label="Website"
            rules={[
              {
                type: "url",
                message: "Website should be in correct url format",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="photo"
            label="Profile Picture"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              name="photo"
              beforeUpload={() => false}
              maxCount={1}
              accept="image/jpeg,image/png"
            >
              <Button>Upload Photo</Button>
            </Upload>
          </Form.Item>
          {user.admin && (
            <>
              <Form.Item
                name="published"
                label="Published"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
              <Form.Item name="status" label="Status">
                <Select>
                  <Option value="S">Submitted</Option>
                  <Option value="I">In-Review</Option>
                  <Option value="A">Approved</Option>
                  <Option value="E">Edit Needed</Option>
                  <Option value="R">Rejected</Option>
                  <Option value="C">Changed</Option>
                </Select>
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
    </>
  );
}
