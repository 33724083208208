import urls from "./urls";

const LICENSE_OPTIONS = [
  ["C", "Copyright"],
  ["F", "Fair Use"],
  ["CC0", "Creative Commons"],
  ["P", "Public Domain"],
  ["RF", "Royalty Free"],
  ["E", "Editorial Use"],
  ["U", "Unknown"],
];

const STATUS_OPTIONS = [
  ["S", "Submitted"],
  ["I", "In-Review"],
  ["A", "Approved"],
  ["E", "Edit Needed"],
  ["R", "Rejected"],
  ["C", "Changed"],
];

const TYPENAME_OPTIONS = [
  ["CA", "Category"],
  ["ST", "Style"],
  ["OT", "Other"],
  ["ME", "Media"],
  ["GE", "Genre"],
  ["MO", "Movement"],
  ["PE", "Period"],
  ["FI", "Field"],
];

const translator = (choice, options) => {
  for (var x of options) {
    if (choice === x[0]) {
      return x[1];
    }
  }
  return "Unknown";
};

const formatter = {
  year: (year) => {
    if (year >= 0) {
      return year;
    } else {
      return `${Math.abs(year)} BC`;
    }
  },
  title: (title, year) => {
    if (title) {
      return title + (year ? ` (${formatter.year(year)})` : "");
    } else {
      return "Unknown";
    }
  },
  artist: (artist) => {
    if (artist) {
      if (artist.birth_year || artist.death_year) {
        return (
          artist.name +
          (artist.birth_year && artist.death_year
            ? ` (${artist.birth_year} - ${artist.death_year})`
            : ` (${artist.birth_year || artist.death_year})`)
        );
      } else {
        return artist.name;
      }
    } else {
      return "Unknown";
    }
  },
  artistLife: (artist) => {
    var birthInfo = formatter.location(artist.birth_place, artist.birth_year);
    var deathInfo = formatter.location(artist.death_place, artist.death_year);
    if (birthInfo && deathInfo) {
      return `${birthInfo} - ${deathInfo}`;
    } else if (birthInfo || deathInfo) {
      return birthInfo || deathInfo;
    } else {
      return null;
    }
  },
  selectImage: (images, priority = null) => {
    if (images && images.length !== 0) {
      images.sort(
        (a, b) =>
          (a.width === null) - (b.width === null) || +(a > b) || -(a < b)
      );
      for (var image of images) {
        image = image.url || urls.getFile(image.file);
        if (image.includes(priority)) {
          return image;
        }
      }
      if (priority === "widget") {
        return formatter.selectImage(images, "display");
      }
      return (
        images[images.length - 1].url ||
        urls.getFile(images[images.length - 1].file)
      );
    } else {
      return null;
    }
  },
  galleryImages: (artworks) => {
    var srcList = [];
    for (var artwork of artworks) {
      srcList.push(formatter.selectImage(artwork.images, "thumbnail"));
    }
    return srcList;
  },
  location: (location, year = null) => {
    if (location) {
      var str = "";
      if (location.city) {
        str = str.concat(location.city);
        if (location.state || location.country) {
          str = str.concat(", ");
        }
      }
      if (location.state) {
        str = str.concat(location.state);
        if (location.country) {
          str = str.concat(", ");
        }
      }
      if (location.country) {
        str = str.concat(location.country);
      }
      if (year) {
        str = `${str}, ${year}`;
      }
      return str;
    } else {
      return null;
    }
  },
  license: (license) => {
    return translator(license, LICENSE_OPTIONS);
  },
  status: (status) => {
    return translator(status, STATUS_OPTIONS);
  },
  typename: (typename) => {
    return translator(typename, TYPENAME_OPTIONS);
  },
};

export default formatter;
