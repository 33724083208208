import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { animated, useSpring } from "react-spring";
import { Card, Typography, Popconfirm, message, Skeleton } from "antd";
import { DeleteOutlined, StarOutlined } from "@ant-design/icons";

import SmartGallery from "../layout/react-smart-gallery/SmartGallery";
import api from "../../utility/api";
import formatter from "../../utility/formatter";
import urls from "../../utility/urls";
import CardIcon from "./CardIcon";
import { LoginContext } from "../../wrappers/LoginContext";

const { Text } = Typography;

export default function GalleryCard(props) {
  const user = useSelector((state) => state.user);
  const scenic = useSelector((state) => state.scenic);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [favorite, setFavorite] = useState(props.data.favorite);
  const [favoriteHover, setFavoriteHover] = useState(false);
  const [hover, setHover] = useState(false);
  let { setLoginVisible } = useContext(LoginContext);

  const icon = useSpring({ opacity: hover ? 1 : 0, right: hover ? 20 : 0 });
  const textbox = useSpring({ opacity: hover ? 1 : 0, bottom: hover ? 15 : 0 });
  const overlay = useSpring({ opacity: hover ? 0.8 : 1, width: "100%" });

  const deleteGallery = () => {
    api.delete(urls.gallery(props.data.id)).then((response) => {
      if (response !== null) {
        message.success("Gallery Deleted");
        props.removeObject(props.data.id);
      }
    });
  };

  const handleFavorite = () => {
    if (user && user.active) {
      if (favorite) {
        api.delete(urls.favorite(favorite)).then((response) => {
          if (response != null) {
            message.success("Gallery Removed from Favorites");
            setFavorite(null);
            if (!props.owner) {
              props.removeObject(props.data.id);
            }
          }
        });
      } else {
        api
          .post(urls.favorite(), { model: "Gallery", object_id: props.data.id })
          .then((response) => {
            if (response) {
              message.success("Gallery Added to Favorites");
              setFavorite(response.id);
            }
          });
      }
    } else {
      if (user) {
        setLoginVisible("verify");
      } else {
        setLoginVisible(true);
      }
    }
  };

  const openModal = () => {
    props.openModal(props.data.id, props.owner ? true : false);
  };

  useEffect(() => {
    api.get(urls.galleryImages(props.data.id)).then((response) => {
      if (response) {
        setCount(response.count);
        setImages(formatter.galleryImages(response.results));
      }
    });
  }, [props.data.id]);

  return (
    <div
      className={scenic ? "card-scenic scaler" : "card scaler"}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        style={{
          background: "black",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
        onClick={openModal}
      >
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            zIndex: scenic ? 1 : 0,
          }}
        />
        {loading && <Skeleton active paragraph={{ rows: 8 }} />}
        {images.length !== 0 && (
          <animated.div
            className="image"
            style={scenic ? overlay : { width: "100%" }}
          >
            <SmartGallery
              total={count}
              width="100%"
              height={450}
              onLoad={() => {
                setLoading(false);
                try {
                  props.updateGrid();
                } catch (error) {
                  console.log(error)
                }
              }}
              images={images}
            />
          </animated.div>
        )}
      </div>
      {scenic ? (
        <div style={{ zIndex: 2 }}>
          <animated.div style={icon} className="icon-box">
            <CardIcon
              color={favorite ? "#ffd700" : "#5B5F65"}
              text={favorite ? "Remove Favorite" : "Add to Favorites"}
              icon={<StarOutlined style={{ margin: "10px", color: "white" }} />}
              onclick={handleFavorite}
            />
          </animated.div>
          <animated.div
            className="text-box-scenic"
            style={textbox}
            onClick={openModal}
          >
            <Text className="main-text light">{props.data.name}</Text>
            <Text
              className="sub-text light"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {props.data.creator}
            </Text>
          </animated.div>
        </div>
      ) : (
        <Card
          actions={
            props.owner
              ? [
                  <StarOutlined
                    className="hover-icon"
                    onMouseEnter={() => setFavoriteHover(true)}
                    onMouseLeave={() => setFavoriteHover(false)}
                    style={
                      favorite || favoriteHover ? { color: "#ffd700" } : {}
                    }
                    onClick={handleFavorite}
                  />,
                  <Popconfirm
                    title="Delete this Gallery?"
                    onConfirm={deleteGallery}
                  >
                    <DeleteOutlined />
                  </Popconfirm>,
                ]
              : [
                  <StarOutlined
                    className="hover-icon"
                    onMouseEnter={() => setFavoriteHover(true)}
                    onMouseLeave={() => setFavoriteHover(false)}
                    style={
                      favorite || favoriteHover ? { color: "#ffd700" } : {}
                    }
                    onClick={handleFavorite}
                  />,
                ]
          }
        >
          <div className="text-box" onClick={openModal}>
            <Text className="main-text">{props.data.name}</Text>
            <Text
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              className="sub-text"
            >
              {props.data.creator}
            </Text>
          </div>
        </Card>
      )}
    </div>
  );
}
