import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import TestComponent from "../components/TestComponent";
import Artwork from "../views/Artwork";
import ArtworkSearch from "../views/ArtworkSearch";
import Gallery from "../views/Gallery";
import GallerySearch from "../views/GallerySearch";
import ArtistSearch from "../views/ArtistSearch";
import Artist from "../views/Artist";
import Home from "../views/Home";
import Login from "../views/Login";
import TagArtwork from "../views/TagArtwork";
import Privacy from "../views/Privacy";
import Terms from "../views/Terms";
import About from "../views/About";
import Contact from "../views/Contact";
import Stats from "../views/Stats";
import Report from "../views/Report";
import Create from "../views/Create";
import PasswordReset from "../views/PasswordReset";
import PrivateRoute from "./PrivateRoute";
import AccountRoutes from "./AccountRoutes";
import Source from "../views/Source";
import Review from "../views/Review";
import Editor from "../views/Editor";
import Nationality from "../views/Nationality";
import Location from "../views/Location";
import Notification from "../views/Notification";

export default function AppRoutes() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/artists/:id">
        <Artist />
      </Route>
      <Route exact path="/artists">
        <ArtistSearch />
      </Route>
      <Route exact path="/gallery/:id">
        <Gallery />
      </Route>
      <Route exact path="/gallery">
        <GallerySearch />
      </Route>
      <Route path="/artworks/:id">
        <Artwork />
      </Route>
      <Route path="/artworks">
        <ArtworkSearch />
      </Route>
      <Route path="/tags/:id">
        <TagArtwork />
      </Route>
      <Route path="/nationalities/:id">
        <Nationality />
      </Route>
      <Route path="/locations/:id">
        <Location />
      </Route>
      <Route path="/sources/:id">
        <Source />
      </Route>
      <Route path="/password-reset">
        <PasswordReset />
      </Route>
      <Route path="/privacy-policy">
        <Privacy />
      </Route>
      <Route path="/terms-of-service">
        <Terms />
      </Route>
      <Route path="/about-us">
        <About />
      </Route>
      <Route path="/contact-us">
        <Contact />
      </Route>
      <Route path="/stats">
        <Stats />
      </Route>
      <PrivateRoute path="/notifications">
        <Notification />
      </PrivateRoute>
      <PrivateRoute path="/create">
        <Create />
      </PrivateRoute>
      <PrivateRoute path="/reports">
        <Report />
      </PrivateRoute>
      <PrivateRoute path="/reviews">
        <Review />
      </PrivateRoute>
      <PrivateRoute path="/myaccount">
        <AccountRoutes />
      </PrivateRoute>
      <PrivateRoute path="/editor">
        <Editor />
      </PrivateRoute>
      <Route path="/test">
        <TestComponent />
      </Route>
      <Redirect to="/" />
    </Switch>
  );
}
