import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Typography,
  Space,
  Button,
  message,
  Modal,
  Form,
  Input,
  Radio,
} from "antd";

import api from "../utility/api";
import urls from "../utility/urls";

const { Text } = Typography;

export default function MyAccount() {
  const user = useSelector((state) => state.user);
  const order = useSelector((state) => state.order);
  const [data, setData] = useState({});
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  let history = useHistory();

  const updateField = (key, value) => {
    var data = {};
    data[key] = value;
    api.patch(urls.user(user.id), data).then((response) => {
      setData(response);
      message.success("Profile Updated");
    });
  };

  const onFinish = () => {
    form.validateFields().then((values) => {
      api.patch(urls.user(user.id), values).then((response) => {
        if (response) {
          setData(response);
          setVisible(false);
          message.success("Password Updated");
        }
      });
    });
  };

  useEffect(() => {
    api.get(urls.user(user.id)).then((response) => {
      if (response) {
        setData(response);
      }
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Text
        className="header-text"
        style={{ marginTop: "15px", marginLeft: "15px" }}
      >
        My Account
      </Text>
      <Space
        direction="vertical"
        style={{
          marginLeft: "15px",
          marginTop: "25px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!user.iss && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text className="main-text">Email:</Text>
            <Text className="sub-text" style={{ marginLeft: "10px" }}>
              {data.email}
            </Text>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Text className="main-text">Name:</Text>
          <Text
            className="sub-text"
            editable={{ onChange: (val) => updateField("name", val) }}
            style={{ marginLeft: "10px" }}
          >
            {data.name}
          </Text>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Text className="main-text">Display Name:</Text>
          <Text
            className="sub-text"
            editable={{ onChange: (val) => updateField("display_name", val) }}
            style={{ marginLeft: "10px" }}
          >
            {data.display_name}
          </Text>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Text className="main-text">Home Page Sorting:</Text>
          <Radio.Group
            style={{ marginTop: "5px", marginLeft: "10px" }}
            value={order ? order : "null"}
            onChange={(val) =>
              dispatch({ type: "SET_ORDER", order: val.target.value })
            }
          >
            <Radio className="sub-description" value={"null"}>
              Random
            </Radio>
            <Radio className="sub-description" value="latest">
              Most Recent
            </Radio>
            <Radio className="sub-description" value="popular">
              Most Popular
            </Radio>
          </Radio.Group>
        </div>
        {!user.iss && (
          <Button
            type="primary"
            onClick={() => setVisible(true)}
            style={{ marginTop: "25px" }}
          >
            Change Password
          </Button>
        )}
        {user.admin && (
          <div>
            <div style={{ height: "20px" }} />
            <div className="footer-border" />
            <Text className="header-text" style={{ marginTop: "15px" }}>
              Admin Features
            </Text>
            <div style={{ height: "20px" }} />
            <Button
              type="primary"
              onClick={() => history.push("/create/?mode=admin")}
            >
              Upload Artwork
            </Button>
            <div style={{ height: "20px" }} />
            <Button type="primary" onClick={() => history.push("/reviews")}>
              Review Submissions
            </Button>
            <div style={{ height: "20px" }} />
            <Button type="primary" onClick={() => history.push("/reports")}>
              View Reports
            </Button>
            <div style={{ height: "20px" }} />
            <Button type="primary" onClick={() => history.push("/editor")}>
              Object Editor
            </Button>
          </div>
        )}
        <Modal
          visible={visible}
          onCancel={() => {
            setVisible(false);
          }}
          onOk={onFinish}
        >
          <Form form={form} style={{ marginTop: "25px" }}>
            <Form.Item label="Old Password" name="old_password" required>
              <Input.Password />
            </Form.Item>
            <Form.Item label="New Password" name="new_password" required>
              <Input.Password />
            </Form.Item>
          </Form>
        </Modal>
      </Space>
    </div>
  );
}
