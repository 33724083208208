import React, { useContext, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import { Space, Input, Badge } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import logoLight from "../static/img/ArtDrive_Logo.svg";
import logoDark from "../static/img/ArtDrive_Logo_Reversed.svg";
import NavButton from "./NavButton";
import { LoginContext } from "../wrappers/LoginContext";
import api from "../utility/api";
import urls from "../utility/urls";
import Drawer from "./Drawer";

const { Search } = Input;

export default function Navbar(props) {
  const user = useSelector((state) => state.user);
  const darkMode = useSelector((state) => state.darkMode);
  const notificationCount = useSelector((state) => state.notificationCount);
  const [hover, setHover] = useState(false);
  const [count, setCount] = useState(0);
  const [visible, setVisible] = useState(false);
  const { setLoginVisible } = useContext(LoginContext);
  let history = useHistory();
  const dispatch = useDispatch();

  const menuAppear = useSpring({
    transform: hover ? "translate3D(0,0,0)" : "translate3D(0,-40px,0)",
    opacity: hover ? 1 : 0,
    paddingTop: "10px",
    position: "absolute",
    left: "-50px",
  });

  const searchAppear = useSpring({
    transform: visible ? "translate3D(0,0,0)" : "translate3D(0,-40px,0)",
    display: visible ? "flex" : "none",
    opacity: visible ? 1 : 0,
    width: "100%",
  });

  useEffect(() => {
    const getCount = () => {
      api.get(urls.count).then((response) => {
        if (response) {
          setCount(response);
        }
      });
      if (user) {
        api.get(urls.notification(null, { read: false })).then((response) => {
          if (response) {
            dispatch({
              type: "SET_NOTIFICATIONCOUNT",
              notificationCount: response.count,
            });
          }
        });
      }
    };
    getCount();
    setInterval(getCount, 10 * 60 * 1000);
    // eslint-disable-next-line
  }, [user]);

  const logout = () => {
    props.logout();
    setHover(false);
  };

  return (
    <div
      className="background"
      id="Navbar"
      style={{
        display: "flex",
        flexDirection: "column",
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 10,
        boxShadow: "rgba(0,0,0,.10) 0 3px 11px 0",
        transition: "top 0.3s",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "15px",
          paddingRight: "25px",
          width: "100%",
        }}
      >
        <Link to="/" style={{ width: "200px" }}>
          {darkMode ? (
            <img alt="My Art Drive" src={logoDark} />
          ) : (
            <img alt="My Art Drive" src={logoLight} />
          )}
        </Link>
        <Search
          className="nav-desktop"
          placeholder={`Search ${count} Available Artworks`}
          enterButton
          onSearch={(text) => history.push(`/artworks/?search=${text}`)}
          style={{ width: "50%", margin: "auto" }}
        />
        <Space
          size={20}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <SearchOutlined
            className="text-color nav-mobile"
            style={{ fontSize: "24px" }}
            onClick={() => setVisible(!visible)}
          />
          <Drawer logout={logout} notificationCount={notificationCount} />
        </Space>
        <Space
          size="large"
          className="nav-desktop"
          style={{ flexDirection: "row" }}
        >
          {user ? (
            <div
              id="profile-dropdown"
              style={{ display: "inline-block", position: "relative" }}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={(e) => {
                try {
                  if (
                    document
                      .elementFromPoint(e.clientX, e.clientY)
                      .closest("#profile-dropdown") === null
                  ) {
                    setHover(false);
                  }
                } catch (err) {
                  setHover(false);
                }
              }}
            >
              <Badge count={notificationCount} size="small" offset={[0, -10]}>
                <NavButton
                  colored
                  name="My Profile"
                  routes={["/myfavorites", "/mygalleries"]}
                />
              </Badge>
              <animated.div style={menuAppear}>
                <div
                  className="background"
                  style={{
                    display: hover ? "flex" : "none",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "175px",
                    padding: "10px",
                    borderRadius: "10px",
                    border: "2px solid #fa3c3c",
                    boxShadow: "rgba(0,0,0,.10) 0 3px 11px 0",
                  }}
                >
                  {user.admin && (
                    <Link
                      to="/admin"
                      onClick={() => window.location.replace(urls.admin)}
                    >
                      <NavButton name="Admin" />
                    </Link>
                  )}
                  <Link to="/myaccount">
                    <NavButton name="My Account" routes={["/myaccount"]} />
                  </Link>
                  <Link to="/notifications">
                    <Badge
                      count={notificationCount}
                      dot
                      size="small"
                      offset={[10, 8]}
                    >
                      <NavButton
                        name="Notifications"
                        routes={["/notifications"]}
                      />
                    </Badge>
                  </Link>
                  <Link to="/create">
                    <NavButton name="Upload" routes={["/create"]} />
                  </Link>
                  <NavButton onClick={logout} name="Logout" />
                </div>
              </animated.div>
            </div>
          ) : (
            <>
              <NavButton
                onClick={() => setLoginVisible("signup")}
                name="Sign Up"
              />
              <NavButton
                colored
                onClick={() => setLoginVisible(true)}
                name="Login"
              />
            </>
          )}
          <div
            className="divider-color"
            style={{ width: "2px", height: "25px" }}
          />
          <Link to="/artworks">
            <NavButton name="Artwork" routes={["/artworks"]} />
          </Link>
          <Link to="/artists">
            <NavButton name="Artist" routes={["/artists"]} />
          </Link>
          <Link to="/gallery">
            <NavButton name="Gallery" routes={["/gallery"]} />
          </Link>
        </Space>
      </div>
      <animated.div style={searchAppear}>
        <Search
          className="nav-mobile"
          placeholder={`Search ${count} Available Artworks`}
          enterButton
          onSearch={(text) => history.push(`/artworks/?search=${text}`)}
          style={{ width: "100%" }}
        />
      </animated.div>
    </div>
  );
}
