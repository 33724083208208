import React, { useState } from "react";
import { Button, Modal, Form, Input, Select, message } from "antd";
import { EditOutlined } from "@ant-design/icons";

import urls from "../../utility/urls";
import api from "../../utility/api";

const { Option } = Select;

export default function TagFormModal(props) {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = () => {
    form.validateFields().then((values) => {
      console.log(values)
      setLoading(true);
      api.patch(urls.tag(props.initial.id), values).then((response) => {
        if (response) {
          props.updateObject(response);
          setVisible(false);
          setLoading(false);
          message.success("Tag Updated");
        }
      });
    });
  };

  return (
    <>
      {props.initial ? (
        <>
          {props.button ? (
            <Button
              className="flex-row-item"
              icon={<EditOutlined />}
              onClick={() => setVisible(true)}
            >
              Edit
            </Button>
          ) : (
            <EditOutlined
              style={{ fontSize: "16px", color: "#fa3c3c", cursor: "pointer" }}
              onClick={() => setVisible(true)}
            />
          )}
        </>
      ) : (
        <Button type="primary" onClick={() => setVisible(true)}>
          {props.title}
        </Button>
      )}
      <Modal
        visible={visible}
        title={props.title}
        onCancel={() => setVisible(false)}
        onOk={onFinish}
        okButtonProps={{ loading: loading }}
      >
        <Form form={form} initialValues={props.initial}>
          <Form.Item name="description" label="Description" required>
            <Input max={64} />
          </Form.Item>
          <Form.Item name="typename" label="Type">
            <Select>
              <Option value="CA">Category</Option>
              <Option value="ME">Media</Option>
              <Option value="ST">Style</Option>
              <Option value="GE">Genre</Option>
              <Option value="FI">Field</Option>
              <Option value="MO">Movement</Option>
              <Option value="PE">Period</Option>
              <Option value="OT">Other</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
