import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Radio,
  Switch,
  Modal,
  Space,
  Typography,
  Tooltip,
  Button,
  Select,
} from "antd";
import Icon, {
  PictureOutlined,
  AlignLeftOutlined,
  SettingOutlined,
} from "@ant-design/icons";

import { ReactComponent as Sun } from "../../static/img/sun.svg";
import { ReactComponent as Moon } from "../../static/img/moon.svg";
import api from "../../utility/api";
import urls from "../../utility/urls";

const { Text } = Typography;
const { Option } = Select;

export default function Settings() {
  const [visible, SetVisible] = useState(false);
  const [galleries, setGalleries] = useState([]);
  const user = useSelector((state) => state.user);
  const scenic = useSelector((state) => state.scenic);
  const darkMode = useSelector((state) => state.darkMode);
  const quickPick = useSelector((state) => state.quickPick);
  const reviewBar = useSelector((state) => state.reviewBar);
  const dispatch = useDispatch();

  const SunIcon = (props) => <Icon component={Sun} {...props} />;
  const MoonIcon = (props) => <Icon component={Moon} {...props} />;

  useEffect(() => {
    if (visible && user) {
      api
        .retrieve(urls.gallery(null, { creator: user.id, filters: "exclude" }))
        .then((response) => {
          if (response) {
            setGalleries(response);
          }
        });
    }
    // eslint-disable-next-line
  }, [visible]);

  return (
    <div>
      <Tooltip title="Settings">
        <Button
          onClick={() => SetVisible(true)}
          icon={
            <SettingOutlined
              style={{ fontSize: "22px", verticalAlign: "middle" }}
            />
          }
        ></Button>
      </Tooltip>
      <Modal
        visible={visible}
        bodyStyle={{ padding: 0 }}
        onCancel={() => SetVisible(false)}
        footer={null}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
          }}
        >
          <Space>
            <Text className="main-description">View Type:</Text>
            <Radio.Group
              buttonStyle="solid"
              value={scenic}
              onChange={() => dispatch({ type: "SET_SCENIC", scenic: !scenic })}
            >
              <Radio.Button value={false}>
                <PictureOutlined
                  style={{ fontSize: "22px", verticalAlign: "middle" }}
                />{" "}
                <AlignLeftOutlined
                  style={{ fontSize: "22px", verticalAlign: "middle" }}
                />
              </Radio.Button>
              <Radio.Button value={true}>
                <PictureOutlined
                  style={{ fontSize: "22px", verticalAlign: "middle" }}
                />
              </Radio.Button>
            </Radio.Group>
          </Space>
          <div style={{ height: "20px" }} />
          <Space>
            <Text className="main-description">Theme:</Text>
            <Switch
              checked={darkMode}
              onChange={() =>
                dispatch({ type: "SET_DARKMODE", darkMode: !darkMode })
              }
              unCheckedChildren={<SunIcon />}
              checkedChildren={<MoonIcon />}
            />
          </Space>
          <div style={{ height: "20px" }} />
          {user && (
            <div
              style={{ display: "flex", width: "100%", alignItems: "center" }}
            >
              <Text className="main-description">Quick Pick:</Text>
              <Select
                value={quickPick}
                style={{ width: "80%", marginLeft: "10px" }}
                onChange={(value) =>
                  dispatch({ type: "SET_QUICKPICK", quickPick: value })
                }
              >
                <Option key={0} value={null}>
                  No Quick Pick
                </Option>
                {galleries.map((gallery) => (
                  <Option key={gallery.id} value={gallery.id.toString()}>
                    {gallery.name}
                  </Option>
                ))}
              </Select>
            </div>
          )}
          <div style={{ height: "20px" }} />
          {user && user.admin && (
            <Space>
              <Text className="main-description">Admin Edits:</Text>
              <Switch
                checked={reviewBar}
                onChange={() =>
                  dispatch({ type: "SET_REVIEWBAR", reviewBar: !reviewBar })
                }
              />
            </Space>
          )}
        </div>
      </Modal>
    </div>
  );
}
