import React from "react";
import { List, Image, Dropdown, Menu, message, Button, Typography } from "antd";
import { InboxOutlined, CheckOutlined } from "@ant-design/icons";

import api from "../../utility/api";
import urls from "../../utility/urls";
import formatter from "../../utility/formatter";
import ArtworkFormModal from "../modals/ArtworkFormModal";
import ArtistFormModal from "../modals/ArtistFormModal";

const { Text, Paragraph } = Typography;

export default function ReviewItems(props) {
  const updateObject = (item, data) => {
    api
      .patch(getModel() ? urls.artwork(item.id) : urls.artist(item.id), data)
      .then((response) => {
        if (response) {
          props.updateObject(response);
          message.success("Object Updated");

          if (response.status !== item.status) {
            props.removeObject(item.id);
          }
        }
      });
  };

  const getStatus = (item) => {
    var status = item.status;
    if (status === "S") {
      return ["Submitted", "red"];
    } else if (status === "I") {
      return ["In-Review", "gold"];
    } else if (status === "A") {
      return ["Approved", "green"];
    } else if (status === "E") {
      return ["Edit Needed", "#34a1fd"];
    } else if (status === "R") {
      return ["Rejected", "purple"];
    } else if (status === "C") {
      return ["Changed", "gray"];
    }
  };

  const getModel = () => {
    if (props.options) {
      // artwork
      return true;
    } else {
      // artist
      return false;
    }
  };

  const viewObject = (id) => {
    if (getModel()) {
      urls.openInNewTab(urls.getLink("/artworks", id));
    } else {
      urls.openInNewTab(urls.getLink("/artists", id));
    }
  };

  return (
    <List
      itemLayout="vertical"
      dataSource={props.objects}
      loading={props.loading && props.objects.length === 0}
      renderItem={(item) => (
        <List.Item
          key={item.id}
          actions={[
            <InboxOutlined
              style={{
                fontSize: "16px",
                color: item.published ? "green" : "red",
                cursor: "pointer",
              }}
              onClick={() => updateObject(item, { published: !item.published })}
            />,
            <Dropdown
              placement="bottomCenter"
              overlay={
                <Menu style={{ padding: 0 }}>
                  <Menu.Item
                    className="sub-description"
                    style={{ background: "red" }}
                    onClick={() => updateObject(item, { status: "S" })}
                  >
                    Submitted
                  </Menu.Item>
                  <Menu.Item
                    className="sub-description"
                    style={{ background: "gold" }}
                    onClick={() => updateObject(item, { status: "I" })}
                  >
                    In-Review
                  </Menu.Item>
                  <Menu.Item
                    className="sub-description"
                    style={{ background: "#34a1fd" }}
                    onClick={() => updateObject(item, { status: "E" })}
                  >
                    Edit Needed
                  </Menu.Item>
                  <Menu.Item
                    className="sub-description"
                    style={{ background: "purple" }}
                    onClick={() => updateObject(item, { status: "R" })}
                  >
                    Rejected
                  </Menu.Item>
                  <Menu.Item
                    className="sub-description"
                    style={{ background: "gray" }}
                    onClick={() => updateObject(item, { status: "C" })}
                  >
                    Changed
                  </Menu.Item>
                </Menu>
              }
            >
              <span style={{ color: getStatus(item)[1], cursor: "pointer" }}>
                {getStatus(item)[0]}
              </span>
            </Dropdown>,
            <>
              {getModel() ? (
                <ArtworkFormModal
                  initial={item}
                  updateArtwork={props.updateObject}
                />
              ) : (
                <ArtistFormModal
                  title="Edit Artist"
                  initial={item}
                  updateObject={props.updateObject}
                />
              )}
            </>,
            <Button
              style={{ padding: 0 }}
              type="link"
              onClick={() => viewObject(item.id)}
            >
              View
            </Button>,
            <CheckOutlined
              style={{ color: "green", cursor: "pointer" }}
              onClick={() =>
                updateObject(item, { status: "A", published: true })
              }
            />,
          ]}
          extra={
            getModel() ? (
              <Image
                width={100}
                height={100}
                alt={item.title}
                src={formatter.selectImage(item.images, "thumbnail")}
              />
            ) : (
              <>
                {item.photo ? (
                  <Image
                    width={100}
                    height={100}
                    alt={item.name}
                    src={item.photo}
                  />
                ) : null}
              </>
            )
          }
        >
          {getModel() ? (
            <List.Item.Meta
              title={formatter.title(item.title, item.year)}
              description={formatter.artist(item.artist)}
            />
          ) : (
            <List.Item.Meta
              title={formatter.artist(item)}
              description={item.nationality ? item.nationality.name : null}
            />
          )}
          {getModel() ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              {item.location && (
                <Text className="main-description">
                  Location:{" "}
                  <Text className="sub-description">
                    {formatter.location(item.location)}
                  </Text>
                </Text>
              )}
              {item.dimensions && (
                <Text className="main-description">
                  Dimensions:{" "}
                  <Text className="sub-description">{item.dimensions}</Text>
                </Text>
              )}
              {item.nudity && (
                <Text className="main-description">
                  Nudity:{" "}
                  <Text className="sub-description">Contains Nudity</Text>
                </Text>
              )}
              {item.source && (
                <Text className="main-description">
                  Source:{" "}
                  <Text className="sub-description">{item.source.name}</Text>
                </Text>
              )}
              <Text className="main-description">
                License:{" "}
                <Text className="sub-description">
                  {formatter.license(item.license_type)}
                </Text>
              </Text>
              {item.description && (
                <Paragraph
                  className="sub-description"
                  ellipsis={{ rows: 3, expandable: true, symbol: "more" }}
                  style={{ margin: 0 }}
                >
                  <Text className="main-description">Description:</Text>{" "}
                  {item.description}
                </Paragraph>
              )}
            </div>
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              {formatter.artistLife(item) !== null && (
                <Text className="main-description">
                  {" "}
                  Origin:{" "}
                  <Text className="sub-description">
                    {formatter.artistLife(item)}
                  </Text>
                </Text>
              )}
              {item.death_cause && (
                <Text className="main-description">
                  {" "}
                  Cause of Death:{" "}
                  <Text className="sub-description">{item.death_cause}</Text>
                </Text>
              )}
              {item.bio && (
                <Paragraph
                  className="sub-description"
                  ellipsis={{ rows: 3, expandable: true, symbol: "more" }}
                  style={{ margin: 0 }}
                >
                  <Text className="main-description">Bio:</Text> {item.bio}
                </Paragraph>
              )}
              {item.official_site && (
                <Text className="main-description">
                  {" "}
                  Website:{" "}
                  <a className="sub-description" href={item.official_site}>
                    {item.official_site}
                  </a>
                </Text>
              )}
              {item.wikipedia && (
                <Text className="main-description">
                  {" "}
                  Wikipedia:{" "}
                  <a className="sub-description" href={item.wikipedia}>
                    {item.wikipedia}
                  </a>
                </Text>
              )}
            </div>
          )}
        </List.Item>
      )}
    />
  );
}
