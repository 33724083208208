import React, { useState, useContext } from "react";
import { useSelector } from "react-redux";
import { useSpring, animated } from "react-spring";
import { Typography, Card, Popconfirm, message, Skeleton } from "antd";
import {
  StarOutlined,
  AppstoreAddOutlined,
  DeleteOutlined,
  ThunderboltOutlined,
  AuditOutlined,
  InboxOutlined,
} from "@ant-design/icons";

import CardIcon from "./CardIcon";
import { GalleryContext } from "../../wrappers/GalleryContext";
import formatter from "../../utility/formatter";
import api from "../../utility/api";
import urls from "../../utility/urls";
import { LoginContext } from "../../wrappers/LoginContext";

const { Text } = Typography;

export default function ImageCard(props) {
  const [hover, setHover] = useState(false);
  const [loading, setLoading] = useState(true);
  const [imageSrc, setImageSrc] = useState(
    formatter.selectImage(props.data.images, "thumbnail")
  );
  const scenic = useSelector((state) => state.scenic);
  const quickPick = useSelector((state) => state.quickPick);
  let { setGalleryModal } = useContext(GalleryContext);
  let { setLoginVisible } = useContext(LoginContext);
  const user = useSelector((state) => state.user);
  const reviewBar = useSelector((state) => state.reviewBar);
  const [favorite, setfavorite] = useState(props.data.favorite);
  const [favoriteHover, setFavoriteHover] = useState(false);

  const icon = useSpring({ opacity: hover ? 1 : 0, right: hover ? 20 : 0 });
  const overlay = useSpring({ opacity: hover ? 0.8 : 1 });
  const textbox = useSpring({ opacity: hover ? 1 : 0, bottom: hover ? 15 : 0 });

  const galleryRemove = () => {
    api
      .patch(urls.gallery(props.owner.id), { remove: props.data.id })
      .then((response) => {
        if (response) {
          message.success("Artwork Removed");
          props.removeObject(props.data.id);
        }
      });
  };

  const handleFavorite = () => {
    if (user && user.active) {
      if (favorite) {
        api.delete(urls.favorite(favorite)).then((response) => {
          if (response != null) {
            message.success("Artwork Removed from Favorites");
            setfavorite(null);
            if (!props.owner) {
              props.removeObject(props.data.id);
            }
          }
        });
      } else {
        api
          .post(urls.favorite(), { model: "Artwork", object_id: props.data.id })
          .then((response) => {
            if (response) {
              message.success("Artwork Added to Favorites");
              setfavorite(response.id);
            }
          });
      }
    } else {
      if (user) {
        setLoginVisible("verify");
      } else {
        setLoginVisible(true);
      }
    }
  };

  const quickAdd = () => {
    api
      .patch(urls.gallery(quickPick), {
        add: props.data.id,
      })
      .then((response) => {
        if (response) {
          message.success("Added To Gallery");
        }
      });
  };

  const openModal = () => {
    if (props.owner && props.owner.filters) {
      props.openModal(props.data.id, "gallery");
    } else {
      props.openModal(props.data.id);
    }
  };

  const updateField = (key, value) => {
    var values = {};
    values[key] = value;
    if (key === "published" && value === false) {
      values["status"] = "I";
    }
    api.patch(urls.artwork(props.data.id), values).then((response) => {
      if (response) {
        props.updateObject(Object.assign({}, props.data, values));
        message.success("Artwork Updated");
      }
    });
  };

  const deleteArtwork = () => {
    api.delete(urls.artwork(props.data.id)).then((response) => {
      if (response !== null) {
        message.success("Artwork Deleted");
        props.removeObject(props.data.id);
      }
    });
  };

  return (
    <div
      className={scenic ? "card-scenic scaler" : "card scaler"}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        style={{
          background: "black",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            zIndex: scenic ? 1 : 0,
          }}
          onClick={openModal}
        />
        {loading && <Skeleton active paragraph={{ rows: 8 }} />}
        <animated.img
          alt={formatter.title(props.data.title, props.data.year)}
          onLoad={() => {
            setLoading(false);
          }}
          src={imageSrc}
          onError={() => setImageSrc(formatter.selectImage(props.data.images))}
          className="image"
          style={scenic ? overlay : null}
        />
      </div>
      {scenic ? (
        <div style={{ zIndex: 2 }}>
          <animated.div style={icon} className="icon-box">
            <CardIcon
              color={favorite ? "#ffd700" : "#5B5F65"}
              text={favorite ? "Remove Favorite" : "Add to Favorites"}
              icon={<StarOutlined style={{ margin: "10px", color: "white" }} />}
              onClick={handleFavorite}
            />
            {quickPick && parseInt(quickPick) !== props.owner.id && (
              <CardIcon
                color="#2a9df4"
                text="Quick Pick"
                icon={
                  <ThunderboltOutlined
                    style={{ margin: "10px", color: "white" }}
                  />
                }
                onClick={quickAdd}
              />
            )}
            {props.owner &&
            props.owner.filters &&
            props.owner.filters.length === 0 ? (
              <Popconfirm
                title="Remove From Gallery?"
                placement="right"
                onConfirm={galleryRemove}
              >
                <CardIcon
                  color="#fa3c3c"
                  text="Remove Artwork"
                  icon={
                    <DeleteOutlined
                      style={{ margin: "10px", color: "white" }}
                    />
                  }
                />
              </Popconfirm>
            ) : (
              <CardIcon
                color="#fa3c3c"
                text="Add to Gallery"
                icon={
                  <AppstoreAddOutlined
                    style={{ margin: "10px", color: "white" }}
                  />
                }
                onClick={() => setGalleryModal(props.data.id)}
              />
            )}
          </animated.div>
          <animated.div
            className="text-box-scenic"
            style={textbox}
            onClick={openModal}
          >
            <Text className="main-text light">
              {formatter.title(props.data.title, props.data.year)}
            </Text>
            <Text className="sub-text light">
              {formatter.artist(props.data.artist)}
            </Text>
          </animated.div>
        </div>
      ) : (
        <Card
          actions={[
            <StarOutlined
              onClick={handleFavorite}
              className="hover-icon"
              onMouseEnter={() => setFavoriteHover(true)}
              onMouseLeave={() => setFavoriteHover(false)}
              style={favorite || favoriteHover ? { color: "#ffd700" } : {}}
            />,
            quickPick && parseInt(quickPick) !== props.owner.id && (
              <ThunderboltOutlined onClick={quickAdd} />
            ),
            props.owner &&
            props.owner.filters &&
            props.owner.filters.length === 0 ? (
              <Popconfirm
                title="Remove From Gallery?"
                onConfirm={galleryRemove}
              >
                <DeleteOutlined />
              </Popconfirm>
            ) : (
              <AppstoreAddOutlined
                onClick={() => setGalleryModal(props.data.id)}
              />
            ),
            user && props.owner === user.id && (
              <Popconfirm title="Delete Artwork?" onConfirm={deleteArtwork}>
                <DeleteOutlined />
              </Popconfirm>
            ),
          ].filter(Boolean)}
        >
          <div className="text-box" onClick={openModal}>
            <Text className="main-text">
              {formatter.title(props.data.title, props.data.year)}
            </Text>
            <Text className="sub-text">
              {formatter.artist(props.data.artist)}
            </Text>
          </div>
        </Card>
      )}
      {user && user.admin && reviewBar && (
        <Card
          actions={[
            <div
              onClick={() => updateField("nudity", !props.data.nudity)}
              style={{ color: props.data.nudity ? "red" : "green" }}
            >
              NSFW
            </div>,
            <InboxOutlined
              onClick={() => updateField("published", !props.data.published)}
              style={{ color: props.data.published ? "green" : "red" }}
            />,
            <AuditOutlined
              onClick={() => {
                api
                  .post(urls.report(), {
                    model: "Artwork",
                    category: "A",
                    reason: "Admin Flagged",
                    object_id: props.data.id,
                  })
                  .then((response) => {
                    if (response) {
                      message.success("Artwork Reported");
                    }
                  });
              }}
            />,
          ]}
        />
      )}
    </div>
  );
}
