import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { Space, Statistic, Button, message, Typography, Skeleton } from "antd";
import {
  EyeOutlined,
  StarOutlined,
  FacebookOutlined,
  TwitterOutlined,
} from "@ant-design/icons";

import ImageCard from "../cards/ImageCard";
import ArtworkDetail from "./ArtworkDetail";
import urls from "../../utility/urls";
import ArtMasonry from "../../components/layout/ArtMasonry";
import api from "../../utility/api";
import { LoginContext } from "../../wrappers/LoginContext";

const { Text } = Typography;

export default function GalleryDetail(props) {
  const user = useSelector((state) => state.user);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [favoriteHover, setFavoriteHover] = useState(false);
  let { setLoginVisible } = useContext(LoginContext);

  const handleFavorite = () => {
    if (user) {
      if (data.favorite) {
        api.delete(urls.favorite(data.favorite)).then((response) => {
          if (response != null) {
            message.success("Gallery Removed from Favorites");
            var temp = Object.assign({}, data);
            temp.favorite = null;
            temp.total_favorites -= 1;
            setData(temp);
            if (props.options !== "gallery") {
              props.removeObject(data.id);
            }
          }
        });
      } else {
        api
          .post(urls.favorite(), { model: "Gallery", object_id: data.id })
          .then((response) => {
            if (response) {
              message.success("Gallery Added to Favorites");
              var temp = Object.assign({}, data);
              temp.favorite = response.id;
              temp.total_favorites += 1;
              setData(temp);
              if (props.options !== "gallery") {
                props.addObject(response.object_data);
              }
            }
          });
      }
    } else {
      setLoginVisible(true);
    }
  };

  useEffect(() => {
    api.get(urls.gallery(props.id)).then((response) => {
      if (response) {
        setData(response);
        setLoading(false);
      }
    });
    api.post(urls.tracker, {
      object_id: props.id,
      model: "Gallery",
      tracker_type: "V",
    });
  }, [props.id]);

  return (
    <Skeleton
      active
      paragraph={{ rows: 10 }}
      loading={loading}
      style={{ display: "flex", flexDirection: "column", width: "100%" }}
    >
      {data && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Text className="header-text" style={{ paddingLeft: "15px" }}>
            {data.name}
          </Text>
          <Space style={{ marginLeft: "20px" }} size={35}>
            <Statistic
              className="sub-text"
              prefix={<EyeOutlined className="stat-icon" />}
              title="Views"
              value={data.total_views}
            />
            <Statistic
              className="sub-text"
              prefix={<StarOutlined className="stat-icon" />}
              title="Favorites"
              value={data.total_favorites}
            />
          </Space>
          <div
            className="flex-row-collapse"
            style={{
              marginTop: "10px",
              marginBottom: "15px",
              marginLeft: "15px",
            }}
          >
            <div>
              <Button
                className="flex-row-item"
                onClick={handleFavorite}
                onMouseEnter={() => setFavoriteHover(true)}
                onMouseLeave={() => setFavoriteHover(false)}
                icon={
                  <StarOutlined
                    style={
                      data.favorite || favoriteHover ? { color: "#ffd700" } : {}
                    }
                  />
                }
              >
                Favorite
              </Button>
            </div>
            <div>
              <Button
                className="flex-row-item"
                icon={<FacebookOutlined style={{ color: "#4267B2" }} />}
                onClick={() =>
                  urls.openInNewTab(
                    `https://www.facebook.com/sharer/sharer.php?u=https://myartdrive.com/gallery/${data.id}/`
                  )
                }
              ></Button>
              <Button
                className="flex-row-item"
                icon={<TwitterOutlined style={{ color: "#1DA1F2" }} />}
                onClick={() =>
                  urls.openInNewTab(
                    `https://twitter.com/share/?url=https://myartdrive.com/gallery/${data.id}/`
                  )
                }
              ></Button>
            </div>
          </div>
          <ArtMasonry
            subtitle={data.creator}
            url={urls.galleryImages(props.id)}
            objectType={ImageCard}
            modalType={ArtworkDetail}
            customScroll="ant-modal-wrap"
            owner={props.options ? data : null}
            lightbox
            gridEdit
          />
        </div>
      )}
    </Skeleton>
  );
}
