import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { animated, useSpring } from "react-spring";
import { Space, Badge } from "antd";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";

import NavButton from "./NavButton";
import urls from "../utility/urls";
import { LoginContext } from "../wrappers/LoginContext";

export default function Drawer(props) {
  const [visible, setVisible] = useState(false);
  const user = useSelector((state) => state.user);
  const { setLoginVisible } = useContext(LoginContext);

  const overlay = useSpring({
    opacity: visible ? 1 : 0,
    display: visible ? "flex" : "none",
  });
  const drawer = useSpring({ right: visible ? "0%" : "-100%" });

  return (
    <>
      <MenuOutlined
        onClick={() => setVisible(true)}
        style={{ marginTop: "auto", marginBottom: "auto", fontSize: "24px" }}
        className="nav-mobile text-color"
      />
      <animated.div
        onClick={() => setVisible(false)}
        className="drawer-overlay"
        style={overlay}
      />
      <animated.div className="drawer background" style={drawer}>
        <CloseOutlined
          className="main-text"
          onClick={() => setVisible(false)}
          style={{
            top: "0px",
            right: "0px",
            margin: "20px",
            position: "absolute",
            fontSize: "24px",
          }}
        />
        <Space
          direction="vertical"
          size={16}
          style={{ flexDirection: "column", marginTop: "30%" }}
        >
          <Link to="/artworks" onClick={() => setVisible(false)}>
            <NavButton name="Artwork" routes={["/artworks"]} />
          </Link>
          <Link to="/artists" onClick={() => setVisible(false)}>
            <NavButton name="Artist" routes={["/artists"]} />
          </Link>
          <Link to="/gallery" onClick={() => setVisible(false)}>
            <NavButton name="Gallery" routes={["/gallery"]} />
          </Link>
          {user ? (
            <>
              {user.admin && (
                <Link
                  to="/admin"
                  onClick={() => window.location.replace(urls.admin)}
                >
                  <NavButton name="Admin" />
                </Link>
              )}
              <Link to="/myaccount" onClick={() => setVisible(false)}>
                <NavButton name="My Account" routes={["/myaccount"]} />
              </Link>
              <Badge
                count={props.notificationCount}
                size="small"
                offset={[10, 0]}
              >
                <Link to="/notifications" onClick={() => setVisible(false)}>
                  <NavButton name="Notifications" routes={["/notifications"]} />
                </Link>
              </Badge>
              <Link to="/create" onClick={() => setVisible(false)}>
                <NavButton name="Upload" routes={["/create"]} />
              </Link>
              <NavButton
                onClick={() => {
                  props.logout();
                  setVisible(false);
                }}
                name="Logout"
              />
            </>
          ) : (
            <>
              <NavButton
                onClick={() => {
                  setLoginVisible("signup");
                  setVisible(false);
                }}
                name="Sign Up"
              />
              <NavButton
                colored
                onClick={() => {
                  setLoginVisible(true);
                  setVisible(false);
                }}
                name="Login"
              />
            </>
          )}
        </Space>
      </animated.div>
    </>
  );
}
