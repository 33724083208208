import React from "react";

export default function Terms() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "50px",
      }}
    >
      <div className="header-text">MyArtDrive Terms of Service</div>
      <br />
      <div className="sub-description">
        These Terms of Service ("ToS") represent the terms of a binding
        agreement (the "Agreement") between you and NRS Systems, LLC. ("NRS
        SYSTEMS" or "We" and related pronouns) and governs your use of and
        access to your account at XSPONSE.com and any related sites (the "Site")
        and our Software, Firmware, Documentation and Content (all as defined
        below and collectively, the "Services") (the Site and the Services are
        referred to collectively as the "NRS SYSTEMS Properties"). Please review
        these ToS carefully before using the Services. <br /> <br />
        By using the NRS SYSTEMS Properties, completing the registration
        process, browsing the Site and/or downloading any of our Software,
        Firmware or Documentation, you represent that (1) you have read,
        understand and agree to be bound by these ToS, (2) you are of legal age
        to form a binding contract with NRS SYSTEMS, (3) you have the authority
        to enter into and bind to the ToS personally or on behalf of the company
        or other organization named as the user. In the event that you are
        agreeing to these ToS on behalf of a company or organization, "you" and
        "your" will refer to the entity being represented. <br /> <br /> If you
        register for or are granted access to a free trial or promotional period
        for our Services, these ToS will also govern that free trial or
        promotional period. <br /> <br /> You may not access the Services if you
        are a direct competitor of NRS SYSTEMS, except with our prior written
        consent. The Services may not be accessed for the purposes of monitoring
        their performance, functionality or availability, or for any other
        competitive purposes. NRS SYSTEMS reserves the right, at its sole
        discretion, to revoke access for any user who it believes is accessing
        the Services for competitive purposes. <br /> <br /> NRS SYSTEMS
        reserves the right, at its sole discretion, at any time and from time to
        time, to replace, modify, add to or remove any and all portions of these
        ToS. It is your responsibility and obligation to review the most recent
        version of these ToS from time to time as posted on the Site to ensure
        continued acceptance of the ToS. Continued use of NRS SYSTEMS Properties
        after publication of ToS revisions will conclusively establish your
        acceptance of the revised ToS. <br /> <br /> 1. SERVICES <br /> <br />{" "}
        1.1 NRS SYSTEMS may discontinue the Services. NRS SYSTEMS may choose to
        modify or discontinue the Services, including any portions of the
        Services as we update our features and offerings. We may stop, suspend
        or modify the Services at any time without prior notice to you. <br />{" "}
        <br /> 1.2 Privacy. The NRS SYSTEMS Privacy Policy
        (http://XSPONSE.com/PrivacyPolicy.htm) governs any of the personal
        information provided to NRS SYSTEMS during the use of the Services.{" "}
        <br /> <br /> 1.3 Your Account. In registering for the Services, you
        agree to (1) provide accurate, complete and current information about
        yourself and your company or organization as required by our
        registration form ("Registration Data"); and (2) maintain and promptly
        update the Registration Data to keep it accurate, complete and current.
        You represent that you are not barred from using NRS SYSTEMS Properties
        under the laws of the United States or any other applicable
        jurisdiction. You agree not to create an account under a false identity.
        It is your responsibility to safeguard the username and password used to
        access the Services and you agree not to share the login information
        with any third party, including sharing the user account amongst two or
        more users. Users may belong to the same Account but must access the
        Services using unique user profiles. You are responsible for any
        activity originating from your Account, regardless of whether such
        activity is authorized by you. Notify NRS SYSTEMS immediately if you are
        aware of or suspect any unauthorized use of your Account. <br /> <br />{" "}
        1.4 Use of NRS SYSTEMS Properties. Subject to the compliance with these
        ToS, NRS SYSTEMS grants you a limited, non-exclusive, non-transferrable,
        non-sublicensable right to: (1) access and use the NRS SYSTEMS
        Properties for your internal business purposes; (2) use the
        Documentation in support of such access and use of the NRS SYSTEMS
        Properties. Unless otherwise specified by NRS SYSTEMS in a separate
        license, your right to use any NRS SYSTEMS Properties is subject to the
        ToS. <br /> <br /> 1.5 Updates. You understand that the Site and other
        NRS SYSTEMS Properties are regularly being updated. As a result, NRS
        SYSTEMS may require you to update the firmware or software on any and
        all NRS SYSTEMS Properties at any time and without prior notice to
        continue using the Services. <br /> <br /> 1.6 Usage Restrictions. The
        rights granted in these ToS are subject to the following restrictions:
        You may not (1) copy, modify, host or sublicense the Services; (2) frame
        or utilize framing techniques to enclose any trademark, logo or other
        NRS SYSTEMS Properties (including images, page content, page layout or
        forms) of NRS SYSTEMS; (3) use metatags or other hidden text using NRS
        SYSTEMS's name or trademarks; (4) modify, translate, adapt, merge, make
        derivative works of, disassemble, decompile, reverse compile or reverse
        engineer any part of the NRS SYSTEMS Properties except to the extent the
        foregoing restrictions are expressly prohibited by applicable law; (5)
        use any manual or automated software devices or other processes to
        "scrape" or download data from any web pages in the Site, except for
        operators of public search engines solely for the extent needed for
        creating publicly accessible searchable indices of materials; (6) access
        the NRS SYSTEMS Properties to build a similar or competitive service,
        product or website; (7) remove or destroy any copyright or trademark
        notices contained on or in the NRS SYSTEMS Properties; (8) modify or
        adapt the Services or modify another website or services to falsely
        imply that it is associated with the Services, NRS SYSTEMS or any other
        NRS SYSTEMS Properties; (9) attempt to disable, impair or destroy the
        Services or disrupt any other person or entity from using the Service;
        (10) reproduce, duplicate, copy, sell, resell or exploit any portion of
        the Services or other NRS SYSTEMS Properties without the express written
        permission of NRS SYSTEMS; or (11) use the NRS SYSTEMS Properties to
        violate or in violation of any applicable law. <br /> <br /> 1.7
        Availability of Services. Services or certain portions of the Services
        may not be available in all countries. It is your responsibility to
        ensure that use of the Services is legal in the country where you
        reside. The Services may not be available in all languages. <br />{" "}
        <br /> 1.8 Data Usage. You shall be solely responsible for ensuring that
        any processing or storage of data by NRS SYSTEMS and/or you by use of
        the Services does not violate any applicable laws. You shall not process
        or submit to the Services any data that contains any: (1) sensitive
        personal data as defined under Directive 95/46/EC or General Data
        Protection Regulation ("GDPR") of the European Parliament ("EU
        Directive") and any national laws adopted pursuant to the EU Directive
        about any member country of the European Union; (2) medical or personal
        health information as defined under the Health Insurance Portability and
        Accountability Act (HIPAA), unless you enter into a separate agreement
        with NRS SYSTEMS relating to the processing and storage of such data.{" "}
        <br /> <br /> 1.9 Data Storage. NRS SYSTEMS stores all data for the
        Services in the United States. NRS SYSTEMS provides the Services from
        the United States. By using and accessing the Services, you agree to the
        storage and processing of the data and any other information provided in
        the United States. <br /> <br /> 1.10 NRS SYSTEMS Proprietary Rights.
        You agree that NRS SYSTEMS and our suppliers own all rights, title and
        interest in the NRS SYSTEMS Properties and Content. These terms do not
        grant you any right, title or interest in any of the NRS SYSTEMS
        Properties. These terms do not grant you any rights to use the NRS
        SYSTEMS logos, domain names, trademarks or other proprietary features.{" "}
        <br /> <br /> 2. USAGE <br /> <br /> 2.1 Commercial Services. NRS
        SYSTEMS offers several types of paid Services ("Commercial Services").{" "}
        <br /> <br /> 2.2 Support. Technical support is only provided to users
        of Commercial Services based on your specific purchased Service. If you
        are using the Service via a Lite or Base Account, NRS SYSTEMS provides
        support via access to NRS SYSTEMS installation guides and other
        documentation related to the Service at: http://NRS SYSTEMS.com/Support.{" "}
        <br /> <br /> 2.3 Unauthorized Use. You will prevent unauthorized use of
        the Services by your users and terminate any unauthorized use of or
        access to the Services. You will promptly notify NRS SYSTEMS of any
        unauthorized use or access to the Services. <br /> <br /> 3. SERVICE IS
        AVAILABLE "AS-IS" AND WITHOUT WARRANTY. NRS SYSTEMS provides the NRS
        SYSTEMS Properties "AS IS" and on an "AS AVAILABLE" basis. <br /> <br />{" "}
        3.1 Your use of the NRS SYSTEMS Properties is at your own risk. NRS
        SYSTEMS provides the NRS SYSTEMS Properties without express or implied
        warranty or condition of any kind. NRS SYSTEMS disclaims any warranties
        or conditions of merchantability, fitness for a particular purpose or
        non-infringement. NRS SYSTEMS makes no warranty or condition that use of
        the Service or NRS SYSTEMS Properties will be free from errors,
        uninterrupted, timely or secure. NRS SYSTEMS makes no warranty that the
        data obtained from the use of NRS SYSTEMS Properties will be accurate or
        reliable or that any errors will be corrected. <br /> <br /> 3.2 NRS
        SYSTEMS specifically disclaims any and all liability and warranties,
        express or implied, for uses requiring fail-safe or mission critical
        performance for uses that require real-time, immediate data where
        failure could lead to (1) personal injury or death, (2) property damage
        or (3) environmental damage. For example, this disclaimer applies to,
        but is not limited to, solutions involving life support, medical
        devices, nuclear applications or critical response monitoring. These
        services are not designed for and should not be used in any of these or
        other fail-safe applications. If you have questions about whether your
        use qualifies for this disclaimer, please contact NRS SYSTEMS. <br />{" "}
        <br /> 3.3 You acknowledge that a TCP/IP connection is required between
        a device and the Service in order to make use of the Services. Due to
        technical, geographic, environmental or political limitations or
        circumstances, you or your devices may not be able to communicate with
        the Services. You are solely responsible for determining whether you
        and/or your devices are able to connect to the Services at a connection
        quality sufficient to meet your needs. <br /> <br /> 3.4 NRS SYSTEMS may
        use Third-Party Suppliers for data transport and storage. You
        acknowledge that storage technologies provided by Third-Party Suppliers
        may utilize a multi-tenancy architecture, which may allow parties not
        associated with NRS SYSTEMS or a Third-Party Supplier to store or
        transmit data within the same framework used to store and transmit data
        for the Services. <br /> <br /> 3.5 You acknowledge that transmission
        and storage of the data may result in the data crossing through or
        residing in multiple political jurisdictions. Each of these
        jurisdictions may have unique provisions relating to the privacy of the
        data and information. <br /> <br /> 4. INDEMNIFICATION. You agree to
        indemnify and hold NRS SYSTEMS, its parents, subsidiaries, affiliates,
        officers, agents, employees, resellers or other partners and licensors
        harmless from any claim, demand, loss or damages, including reasonable
        attorneys' fees, arising out of or related to your use of the Services,
        including but not limited to allegations that the storage or processing
        of the data by NRS SYSTEMS and/or you under these ToS violates any
        applicable law or regulation, or infringes on the privacy or
        intellectual property rights of a third party. <br /> <br /> 5.
        LIMITATION OF LIABILITY. In no event shall NRS SYSTEMS, nor its
        directors, employees, partners, agents, suppliers, or affiliates, be
        liable for any indirect, incidental, special, consequential or punitive
        damages, including without limitation, loss of profits, data, use,
        goodwill, or other intangible losses, resulting from (i) your access to
        or use of or inability to access or use the Service; (ii) any conduct or
        content of any third party on the Service; (iii) any content obtained
        from the Service; and (iv) unauthorized access, use or alteration of
        your transmissions or content, whether based on warranty, contract, tort
        (including negligence) or any other legal theory, whether or not we have
        been informed of the possibility of such damage, and even if a remedy
        set forth herein is found to have failed of its essential purpose.{" "}
        <br /> <br /> 6. TERMINATION. NRS SYSTEMS reserves the right to suspend
        or cease providing the NRS SYSTEMS Properties or any portion of the NRS
        SYSTEMS Properties, at any time, with or without cause, and with or
        without notice. Your use of the Service or NRS SYSTEMS Properties may be
        suspended or terminated if your Commercial Services account is
        delinquent or you are in violation of these ToS. <br /> <br /> 7. DATA
        RETENTION. Data stored for a Commercial Service will be maintained for
        the period applicable to your Commercial Service terms. If your account
        is terminated, the data will no longer be accessible. Upon termination
        of your account, NRS SYSTEMS may, but is not required to, delete the
        data associated with your account. <br /> <br /> 8. PURCHASES. If you
        wish to purchase any product or service made available through the
        Service("Purchase"), you may be asked to supply certain information
        relevant to your Purchase including, without limitation, your credit
        card number, the expiration date of your credit card, your billing
        address, and your shipping information. You represent and warrant that:
        (i) you have the legal right to use any credit card(s) or other payment
        method(s) in connection with any Purchase; and that (ii) the information
        you supply to us is true, correct and complete. The service may employ
        the use of third party services for the purpose of facilitating payment
        and the completion of Purchases. By submitting your information, you
        grant us the right to provide the information to these third parties
        subject to our Privacy Policy. <br /> <br /> 9. INTELLECTUAL PROPERTY.
        The Service and its original content, features and functionality are and
        will remain the exclusive property of NRS SYSTEMS and its licensors. The
        Service is protected by copyright, trademark, and other laws of both the
        United States and foreign countries. Our trademarks and trade dress may
        not be used in connection with any product or service without the prior
        written consent of NRS SYSTEMS. <br /> <br /> 10. LINKS TO OTHER WEB
        SITES. Our Service may contain links to third party web sites or
        services that are not owned or controlled by NRS SYSTEMS. NRS SYSTEMS
        has no control over, and assumes no responsibility for the content,
        privacy policies, or practices of any third-party web sites or services.
        We do not warrant the offerings of any of these entities/individuals or
        their websites. You acknowledge and agree that NRS SYSTEMS shall not be
        responsible or liable, directly or indirectly, for any damage or loss
        caused or alleged to be caused by or in connection with use of or
        reliance on any such content, goods or services available on or through
        any such third-party web sites or services. We strongly advise you to
        read the terms and conditions and privacy policies of any third-party
        web sites or services that you visit. <br /> <br /> 11. GOVERNING LAW.
        These Terms shall be governed and construed in accordance with the laws
        of Washington, United States, without regard to its conflict of law
        provisions. Our failure to enforce any right or provision of these Terms
        will not be considered a waiver of those rights. If any provision of
        these Terms is held to be invalid or unenforceable by a court, the
        remaining provisions of these Terms will remain in effect. These Terms
        constitute the entire agreement between us regarding our Service and
        supersede and replace any prior agreements we might have had between us
        regarding the Service. <br /> <br /> 12. NOTICE. You are responsible for
        providing NRS SYSTEMS with a current email address. Our transmission of
        email messages to the email address provided will constitute effective
        notice, whether or not delivery to the provided email address is
        possible. The communications between you and NRS SYSTEMS may use
        electronic means, and you consent to receive communications from NRS
        SYSTEMS in electronic form. You agree that all terms, agreements,
        notices, disclosures and other communications provided to you
        electronically satisfy any legal requirement that such communications
        would satisfy if it were provided in writing. <br /> <br /> 13. EXPORT
        CONTROL. You may not use, export, import or transfer the NRS SYSTEMS
        Properties except as authorized by United States law, the laws of the
        jurisdiction in which NRS SYSTEMS Properties are accessed, and any other
        applicable laws. NRS SYSTEMS Properties may not be exported or
        re-exported (1) into any United States embargoed countries, or (2) to
        anyone on the U.S. Treasury Department's list of Specially Designated
        Nationals or the U.S. Department of Commerce's Denied Person's List or
        Entity List. You acknowledge and accept that the NRS SYSTEMS Properties
        are subject to the export control laws and regulations of the United
        States. <br /> <br /> 14. GOVERNING LAW. These ToS and any action
        related thereto will be governed and interpreted by and under the law of
        the State of Rhode Island and Providence Plantations. <br /> <br /> 15.
        GENERAL TERMS. These ToS constitute the entire and exclusive agreement
        between NRS SYSTEMS and you with respect to the Services and supersede
        and replace any other agreements, terms and conditions applicable to the
        Services. <br /> <br /> 15.1 Purchase orders placed by you shall be for
        the sole purpose of specifying the Commercial Services that you wish to
        purchase. Any other terms stated in a purchase order delivered to NRS
        SYSTEMS shall have no effect. <br /> <br /> 15.2 NRS SYSTEMS's failure
        to enforce a provision is not a waiver of its right to do so later. If a
        provision is found unenforceable, the remaining provisions of the
        Agreement will remain in full effect. <br /> <br /> 15.3 No agency,
        partnership, joint venture, or employment is created as a result of
        these ToS or use of the Services. You do not have authority to bind NRS
        SYSTEMS in any respect.
      </div>
    </div>
  );
}
