import React from "react";
import ArtistCard from "../components/cards/ArtistCard";
import ArtistDetail from "../components/details/ArtistDetail";

import ArtMasonry from "../components/layout/ArtMasonry";
import urls from "../utility/urls";

export default function ArtistSearch(props) {
  return (
    <div>
      <ArtMasonry
        title="All Artists"
        url={urls.artist(null, { ordering: "popular", status: "published" })}
        objectType={ArtistCard}
        modalType={ArtistDetail}
        filter={{ model: "Artist" }}
      />
    </div>
  );
}
