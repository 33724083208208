import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import ImageCard from "../components/cards/ImageCard";
import ArtworkDetail from "../components/details/ArtworkDetail";
import ArtMasonry from "../components/layout/ArtMasonry";
import api from "../utility/api";
import urls from "../utility/urls";

export default function Nationality() {
  const [title, setTitle] = useState("");
  let { id } = useParams();

  useEffect(() => {
    api.get(urls.nationality(id)).then((response) => {
      if (response) {
        setTitle(response.name);
      }
    });
  }, [id]);

  return (
    <div>
      <ArtMasonry
        title={title}
        url={urls.artwork(null, { nationality: id })}
        objectType={ImageCard}
        modalType={ArtworkDetail}
        filter={{ model: "Artwork" }}
        lightbox
      />
    </div>
  )
}
