import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Result } from "antd";
import Icon from "@ant-design/icons";

import ArtistDetail from "../components/details/ArtistDetail";
import { ReactComponent as Pallete } from "../static/img/pallete.svg";
import api from "../utility/api";
import ArtistFormModal from "../components/modals/ArtistFormModal";

export default function MyArtwork() {
  const user = useSelector((state) => state.user);
  const [signUp, setSignUp] = useState(false);

  const PalleteIcon = (props) => (
    <Icon style={{ fontSize: "128px" }} component={Pallete} {...props} />
  );

  return (
    <div
      style={{
        marginTop: "15px",
        display: "block",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
      }}
    >
      {user.artist ? (
        <ArtistDetail
          id={user.artist}
          addObject={() => {}}
          removeObject={() => {}}
        />
      ) : signUp ? (
        <div>
          <div className="sub-text">
            Something went wrong reloading the page
          </div>
          <Button type="primary" onClick={api.refresh}>
            Reload
          </Button>
        </div>
      ) : (
        <Result
          icon={<PalleteIcon />}
          title="Create an Artist Profile"
          subTitle="Upload your own artwork"
          extra={
            <ArtistFormModal
              title="Get Started"
              addValue={() => {
                api.refresh();
                setSignUp(true);
              }}
              user={user.id}
            />
          }
        />
      )}
    </div>
  );
}
