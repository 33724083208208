import React from 'react'
import { useParams } from 'react-router-dom';

import GalleryDetail from '../components/details/GalleryDetail'

export default function Gallery() {
    let { id } = useParams();

    return (
        <GalleryDetail id={id} addObject={() => {}} removeObject={() => {}} />
    )
}
