import React, { useState, useEffect } from "react";
import { Button, Modal, List } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import ArtList from "./ArtList";
import formatter from "../../utility/formatter";
import ArtistFormModal from "../modals/ArtistFormModal";

const selected = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  background: "#fa3c3c",
  color: "white",
  borderRadius: "10px",
  padding: "3px",
  marginTop: "5px",
};

const close = {
  color: "white",
  fontSize: "12px",
  cursor: "pointer",
  marginRight: "5px",
};

export default function ArtSelect(props) {
  const getInitial = () => {
    if (props.initial) {
      if (props.many) {
        return props.initial;
      } else {
        return [props.initial];
      }
    } else {
      return [];
    }
  };

  const [value, setValue] = useState(getInitial);
  const [visible, setVisible] = useState(false);

  const addValue = (val) => {
    if (props.many) {
      var temp = [...value];
      temp.push(val);
      setValue(temp);
      props.updateValue(props.name, temp);
    } else {
      setValue([val]);
      props.updateValue(props.name, val);
      setVisible(false);
    }
  };

  const removeValue = (id) => {
    if (props.many) {
      var temp = [...value];
      temp = temp.filter((obj) => obj.id !== id);
      setValue(temp);
      props.updateValue(props.name, temp);
    } else {
      setValue([]);
      props.updateValue(props.name, null);
    }
  };

  const getDisplay = (item) => {
    if (Object.keys(item).length === 2) {
      return item.name;
    } else if (props.display === "location") {
      return formatter.location(item);
    } else {
      return item[props.display];
    }
  };

  useEffect(() => {
    if (props.initial) {
      setValue(getInitial());
    }
    // eslint-disable-next-line
  }, [props.initial]);

  function Display(props2) {
    return (
      <List
        dataSource={props2.objects}
        loading={props2.loading && props2.objects.length === 0}
        renderItem={(item) => (
          <List.Item
            actions={
              value.find((x) => item.id === x.id)
                ? [
                    <Button onClick={() => removeValue(item.id)} type="primary">
                      Remove
                    </Button>,
                  ]
                : [<Button onClick={() => addValue(item)}>Add</Button>]
            }
          >
            <List.Item.Meta title={getDisplay(item)} />
          </List.Item>
        )}
      />
    );
  }

  return (
    <>
      <Button type="link" onClick={() => setVisible(true)}>
        {`${value.length > 0 ? "Edit" : "Select"} ${props.title}`}
      </Button>
      {value.map((item) => (
        <div key={item.id} className="sub-description light" style={selected}>
          {getDisplay(item)}
          <CloseOutlined onClick={() => removeValue(item.id)} style={close} />
        </div>
      ))}
      <Modal
        visible={visible}
        title={`Select ${props.title}`}
        onCancel={() => setVisible(false)}
        wrapClassName={`${props.title}-Select`}
        bodyStyle={{ paddingTop: "0px" }}
        footer={null}
      >
        {props.create !== undefined && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "15px",
            }}
          >
            <ArtistFormModal title="Create Artist" addValue={addValue} />
            <div
              className="footer-border"
              style={{ width: "100%", marginTop: "15px" }}
            />
          </div>
        )}
        <ArtList
          component={Display}
          url={props.url}
          customScroll={`${props.title}-Select`}
          search={props.search}
        />
      </Modal>
    </>
  );
}
