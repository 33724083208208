import React from "react";
import { List, Image, Dropdown, Menu, message, Button } from "antd";
import { InboxOutlined } from "@ant-design/icons";

import api from "../../utility/api";
import urls from "../../utility/urls";
import formatter from "../../utility/formatter";
import MessageModal from "../modals/MessageModal";
import ArtworkFormModal from "../modals/ArtworkFormModal";
import ArtistFormModal from "../modals/ArtistFormModal";

export default function ReportItems(props) {
  const updateField = (item, key, value, dest) => {
    var values = {};
    values[key] = value;

    if (dest === "Artwork") {
      api.patch(urls.artwork(item.object_data.id), values).then((response) => {
        if (response) {
          values = { object_data: Object.assign({}, item.object_data, values) };
          props.updateObject(Object.assign({}, item, values));
          message.success("Artwork Updated");
        }
      });
    } else if (dest === "Artist") {
      api.patch(urls.artist(item.object_data.id), values).then((response) => {
        if (response) {
          values = { object_data: Object.assign({}, item.object_data, values) };
          props.updateObject(Object.assign({}, item, values));
          message.success("Artist Updated");
        }
      });
    } else if (dest === "report") {
      api.patch(urls.report(item.id), values).then((response) => {
        if (response) {
          props.updateObject(response);
          message.success("Report Updated");

          if (response.status !== item.status) {
            props.removeObject(item.id);
          }
        }
      });
    }
  };

  const getStatus = (item) => {
    var status = item.status;
    if (status === "S") {
      return ["Submitted", "red"];
    } else if (status === "I") {
      return ["In-Review", "gold"];
    } else if (status === "R") {
      return ["Reviewed", "green"];
    }
  };

  const viewObject = (id, model) => {
    if (model === "Artwork") {
      urls.openInNewTab(urls.getLink("/artworks", id));
    } else if (model === "Artist") {
      urls.openInNewTab(urls.getLink("/artists", id));
    }
  };

  return (
    <List
      itemLayout="vertical"
      dataSource={props.objects}
      loading={props.loading && props.objects.length === 0}
      renderItem={(item) => (
        <List.Item
          key={item.id}
          actions={[
            <MessageModal
              messages={item.messages}
              updateField={(val) => updateField(item, "message", val, "report")}
            />,
            item.model === "Artwork" && (
              <span
                style={{
                  color: item.object_data.nudity ? "red" : "green",
                  cursor: "pointer",
                }}
                onClick={() =>
                  updateField(
                    item,
                    "nudity",
                    !item.object_data.nudity,
                    item.model
                  )
                }
              >
                NSFW
              </span>
            ),
            <InboxOutlined
              style={{
                color: item.object_data.published ? "green" : "red",
                cursor: "pointer",
              }}
              onClick={() =>
                updateField(
                  item,
                  "published",
                  !item.object_data.published,
                  item.model
                )
              }
            />,
            <Dropdown
              placement="bottomCenter"
              overlay={
                <Menu style={{ padding: 0 }}>
                  <Menu.Item
                    className="sub-description"
                    style={{ background: "red" }}
                    onClick={() => updateField(item, "status", "S", "report")}
                  >
                    Submitted
                  </Menu.Item>
                  <Menu.Item
                    className="sub-description"
                    style={{ background: "gold" }}
                    onClick={() => updateField(item, "status", "I", "report")}
                  >
                    In-Review
                  </Menu.Item>
                  <Menu.Item
                    className="sub-description"
                    style={{ background: "green" }}
                    onClick={() => updateField(item, "status", "R", "report")}
                  >
                    Reviewed
                  </Menu.Item>
                </Menu>
              }
            >
              <span style={{ color: getStatus(item)[1], cursor: "pointer" }}>
                {getStatus(item)[0]}
              </span>
            </Dropdown>,
            <>
              {item.model === "Artwork" ? (
                <ArtworkFormModal
                  initial={item.object_data}
                  updateArtwork={(values) => {
                    values = {
                      object_data: Object.assign({}, item.object_data, values),
                    };
                    props.updateObject(Object.assign({}, item, values));
                  }}
                />
              ) : (
                <ArtistFormModal
                  title="Edit Artist"
                  initial={item.object_data}
                  updateObject={(values) => {
                    values = {
                      object_data: Object.assign({}, item.object_data, values),
                    };
                    props.updateObject(Object.assign({}, item, values));
                  }}
                />
              )}
            </>,
            <Button
              style={{ padding: 0 }}
              type="link"
              onClick={() => viewObject(item.object_data.id, item.model)}
            >
              View
            </Button>,
          ].filter(Boolean)}
          extra={
            item.model === "Artwork" ? (
              <Image
                height={100}
                width={100}
                alt={item.object_data.title}
                src={formatter.selectImage(
                  item.object_data.images,
                  "thumbnail"
                )}
              />
            ) : null
          }
        >
          <List.Item.Meta
            title={item.reason}
            description={item.reporter.display_name}
          />
        </List.Item>
      )}
    />
  );
}
