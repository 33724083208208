import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Button, Form, Input, message } from "antd";
import { LockOutlined } from "@ant-design/icons";

import api from "../utility/api";
import urls from "../utility/urls";

export default function PasswordReset() {
  let location = useLocation();
  let history = useHistory();
  const [form] = Form.useForm();

  const onFinish = (values) => {
    if (urls.getParams(location.search).token) {
      api
        .put(urls.password, {
          password: values.password,
          token: urls.getParams(location.search).token,
        })
        .then((response) => {
          if (response) {
            message.success('Password changed successfully')
            history.push('/myaccount')
          }
        });
    } else {
      api.post(urls.password, { email: values.email }).then((response) => {
        if (response) {
          message.success("Email Sent");
          history.push('/')
        }
      });
    }
  };

  useEffect(() => {
    var token = urls.getParams(location.search).token;
    if (token) {
      api.get(urls.password + location.search).then((response) => {
        if (!response) {
          console.log("clear inputs");
        }
      });
    }
  }, [location.search]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <div className="header-text" style={{ marginTop: "50px" }}>
        Password Reset
      </div>
      <Form
        form={form}
        onFinish={onFinish}
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "25px",
          border: "1px solid #fa3c3c",
          padding: "25px",
        }}
      >
        {urls.getParams(location.search).token ? (
          <>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
              hasFeedback
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject();
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="Confirm Password"
              />
            </Form.Item>
            <Form.Item style={{ marginLeft: "auto", marginRight: "auto" }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item
              name="email"
              label="E-mail"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item style={{ marginLeft: "auto", marginRight: "auto" }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </>
        )}
      </Form>
    </div>
  );
}
