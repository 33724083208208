const DOMAIN = `${window.location.protocol}//${window.location.hostname}${
  window.location.port === "3000" ? ":8000" : ""
}`;

const formatId = (url, id) => {
  if (id !== null) {
    return `${url}${id}/`;
  } else {
    return url;
  }
};

const addQuery = (url, params) => {
  if (params) {
    for (const [key, value] of Object.entries(params)) {
      url.searchParams.append(key, value);
    }
  }
  return url;
};

const urls = {
  getParams: (paramStr) => {
    var params = new URLSearchParams(paramStr);
    var paramObject = {};
    for (var pair of params.entries()) {
      paramObject[pair[0]] = pair[1];
    }
    return paramObject;
  },
  getUrl: (url, params) => {
    if (params && Object.keys(params).length > 0) {
      var query = new URLSearchParams();
      for (var param of Object.entries(params)) {
        query.set(param[0], param[1]);
      }
      if (url.href.includes("?")) {
        return `${url}&${query.toString()}`;
      } else {
        return `${url}?${query.toString()}`;
      }
    } else {
      return url;
    }
  },
  getLink: (path, id = null, params = null) => {
    var url = new URL(
      formatId(`${DOMAIN.replace("8000", "3000")}${path}/`, id)
    );
    return addQuery(url, params);
  },
  getFile: (url) => {
    if (process.env.NODE_ENV === "development") {
      return DOMAIN + url;
    } else {
      return url;
    }
  },
  openInNewTab: (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  },
  artwork: (id = null, params = null) => {
    var url = new URL(formatId(`${DOMAIN}/api/artworks/`, id));
    return addQuery(url, params);
  },
  gallery: (id = null, params = null) => {
    var url = new URL(formatId(`${DOMAIN}/api/galleries/`, id));
    return addQuery(url, params);
  },
  artist: (id = null, params = null) => {
    var url = new URL(formatId(`${DOMAIN}/api/artists/`, id));
    return addQuery(url, params);
  },
  favorite: (id = null, params = null) => {
    var url = new URL(formatId(`${DOMAIN}/api/favorites/`, id));
    return addQuery(url, params);
  },
  galleryImages: (id) => {
    return new URL(`${DOMAIN}/api/artworks/?gallery=${id}`);
  },
  tag: (id = null, params = null) => {
    var url = new URL(formatId(`${DOMAIN}/api/tags/`, id));
    return addQuery(url, params);
  },
  source: (id = null, params = null) => {
    var url = new URL(formatId(`${DOMAIN}/api/sources/`, id));
    return addQuery(url, params);
  },
  nationality: (id = null, params = null) => {
    var url = new URL(formatId(`${DOMAIN}/api/nationalities/`, id));
    return addQuery(url, params);
  },
  location: (id = null, params = null) => {
    var url = new URL(formatId(`${DOMAIN}/api/locations/`, id));
    return addQuery(url, params);
  },
  user: (id = null, params = null) => {
    var url = new URL(formatId(`${DOMAIN}/api/users/`, id));
    return addQuery(url, params);
  },
  report: (id = null, params = null) => {
    var url = new URL(formatId(`${DOMAIN}/api/reports/`, id));
    return addQuery(url, params);
  },
  notification: (id = null, params = null) => {
    var url = new URL(formatId(`${DOMAIN}/api/notifications/`, id));
    return addQuery(url, params);
  },
  password: `${DOMAIN}/api/password-reset/`,
  tracker: `${DOMAIN}/api/tracker/`,
  count: `${DOMAIN}/api/count/`,
  login: `${DOMAIN}/api/login/`,
  signup: `${DOMAIN}/api/signup/`,
  admin: `${DOMAIN}/admin/`,
  duplicate: `${DOMAIN}/api/duplicate-finder/`,
};

export default urls;
