import React from "react";

import LoginModal from '../components/modals/LoginModal'
import useLoginModal from "../hooks/useLoginModal";

let LoginContext;
let { Provider } = (LoginContext = React.createContext());

let LoginProvider = ({ children }) => {
  let { loginVisible, setLoginVisible } = useLoginModal();
  return (
    <Provider value={{ loginVisible, setLoginVisible }}>
      <LoginModal />
      {children}
    </Provider>
  );
};

export { LoginContext, LoginProvider }
