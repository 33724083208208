import React from "react";
import { useLocation } from "react-router-dom";

import ImageCard from "../components/cards/ImageCard";
import ArtworkDetail from "../components/details/ArtworkDetail";
import ArtMasonry from "../components/layout/ArtMasonry";
import urls from "../utility/urls";

export default function ArtworkSearch() {
  let location = useLocation();

  return (
    <div>
      <ArtMasonry
        title="All Artworks"
        url={urls.artwork()}
        search={urls.getParams(location.search)}
        objectType={ImageCard}
        modalType={ArtworkDetail}
        filter={{ model: "Artwork" }}
        lightbox
      />
    </div>
  );
}
