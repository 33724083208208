import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import {
  Typography,
  Space,
  Button,
  Tag,
  Statistic,
  Skeleton,
  message,
  Image,
} from "antd";
import Icon, {
  StarOutlined,
  AppstoreAddOutlined,
  FacebookOutlined,
  TwitterOutlined,
  EyeOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

import api from "../../utility/api";
import formatter from "../../utility/formatter";
import urls from "../../utility/urls";
import { GalleryContext } from "../../wrappers/GalleryContext";
import { LoginContext } from "../../wrappers/LoginContext";
import ReportModal from "../modals/ReportModal";
import ArtworkFormModal from "../modals/ArtworkFormModal";

import { ReactComponent as Pinterest } from "../../static/img/pinterest.svg";

const { Text, Paragraph } = Typography;
const axios = require("axios");

const generateTags = (tags) => {
  if (tags.length > 0) {
    var data = {};

    for (var tag of tags) {
      if (data[tag.typename]) {
        data[tag.typename].push({ id: tag.id, description: tag.description });
      } else {
        data[tag.typename] = [{ id: tag.id, description: tag.description }];
      }
    }

    return (
      <div className="artwork-detail">
        {Object.keys(data).map((type, i) => {
          return (
            <Text className="main-description" key={i}>
              {formatter.typename(type)}:
              {data[type].map((tag, j) => (
                <Tag
                  style={{ cursor: "pointer", marginBottom: "5px" }}
                  onClick={() =>
                    urls.openInNewTab(urls.getLink("/tags", tag.id))
                  }
                  key={j}
                >
                  {tag.description}
                </Tag>
              ))}
            </Text>
          );
        })}
      </div>
    );
  } else {
    return null;
  }
};

export default function ArtworkDetail(props) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [imageSrc, setImageSrc] = useState(null);
  const user = useSelector((state) => state.user);
  let { setGalleryModal } = useContext(GalleryContext);
  let { setLoginVisible } = useContext(LoginContext);
  const [favoriteHover, setFavoriteHover] = useState(false);

  const PinterestIcon = (props) => <Icon component={Pinterest} {...props} />;

  useEffect(() => {
    if (props.id) {
      api.get(urls.artwork(props.id)).then((response) => {
        if (response) {
          setData(response);
          setLoading(false);
        }
      });
      api.post(urls.tracker, {
        object_id: props.id,
        model: "Artwork",
        tracker_type: "V",
      });
    }
  }, [props.id, user]);

  useEffect(() => {
    setImageSrc(formatter.selectImage(data.images, "widget"));
  }, [data.images]);

  const handleFavorite = () => {
    if (user) {
      if (data.favorite) {
        api.delete(urls.favorite(data.favorite)).then((response) => {
          if (response != null) {
            message.success("Artwork Removed from Favorites");
            var temp = Object.assign({}, data);
            temp.favorite = null;
            temp.total_favorites -= 1;
            setData(temp);
            if (props.options !== "gallery") {
              props.removeObject(data.id);
            }
          }
        });
      } else {
        api
          .post(urls.favorite(), { model: "Artwork", object_id: data.id })
          .then((response) => {
            if (response) {
              message.success("Artwork Added to Favorites");
              var temp = Object.assign({}, data);
              temp.favorite = response.id;
              temp.total_favorites += 1;
              setData(temp);
              if (props.options !== "gallery") {
                props.addObject(response.object_data);
              }
            }
          });
      }
    } else {
      setLoginVisible(true);
    }
  };

  const getFileName = (img) => {
    var url = img.url || img.file;
    if (url.includes("-display")) {
      return "Source";
    } else if (url.includes("-thumbnail")) {
      return "Thumbnail";
    } else {
      return "Display";
    }
  };

  const download = (img) => {
    var url = img.url || img.file;
    axios({
      url: url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      if (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${data.title}-${getFileName(img)}.jpg`);
        document.body.appendChild(link);
        link.click();
      }
    });

    api.post(urls.tracker, {
      object_id: props.id,
      model: "Artwork",
      tracker_type: "D",
    });
  };

  const updateArtwork = (data) => {
    setData(data);
    props.updateObject(data);
  };

  return (
    <Skeleton
      active
      paragraph={{ rows: 10 }}
      loading={loading}
      style={{ display: "flex", flexDirection: "column", width: "100%" }}
    >
      <div style={{ height: "20px" }} />
      {!loading && (
        <div className="artwork-display">
          <div className="artwork-display-image">
            <Image id="image-preview" src={imageSrc} height={0} width={0} />
            <img
              alt={formatter.title(data.title, data.year)}
              src={imageSrc}
              onError={() => setImageSrc(formatter.selectImage(data.images))}
              style={{
                maxWidth: "100%",
                maxHeight: "70vh",
                cursor: "pointer",
              }}
              onClick={() => document.getElementById("image-preview").click()}
            />
          </div>
          <div className="artwork-display-text">
            <Text className="main-text">
              {formatter.title(data.title, data.year)}{" "}
              {user &&
                (user.admin ||
                  (data.artist && user.artist === data.artist.id) ||
                  (data.source && user.source === data.source.id)) && (
                  <ArtworkFormModal
                    initial={data}
                    updateArtwork={updateArtwork}
                  />
                )}
            </Text>
            <Text className="sub-text">
              {formatter.artist(data.artist)}{" "}
              <Button
                style={{ padding: 0, marginLeft: "10px" }}
                onClick={() =>
                  urls.openInNewTab(urls.getLink("/artists", data.artist.id))
                }
                type="link"
              >
                View
              </Button>
            </Text>
            <Space style={{ marginTop: "25px" }} size={35}>
              <Statistic
                className="sub-text"
                prefix={<EyeOutlined className="stat-icon" />}
                title="Views"
                value={data.total_views}
              />
              <Statistic
                className="sub-text"
                prefix={<StarOutlined className="stat-icon" />}
                title="Favorites"
                value={data.total_favorites}
              />
              <Statistic
                className="sub-text"
                prefix={<DownloadOutlined className="stat-icon" />}
                title="Downloads"
                value={data.total_downloads}
              />
            </Space>
            <div
              className="flex-row-collapse"
              style={{ marginTop: "10px", marginBottom: "25px" }}
            >
              <div>
                <Button
                  className="flex-row-item"
                  onClick={handleFavorite}
                  onMouseEnter={() => setFavoriteHover(true)}
                  onMouseLeave={() => setFavoriteHover(false)}
                  icon={
                    <StarOutlined
                      style={
                        data.favorite || favoriteHover
                          ? { color: "#ffd700" }
                          : {}
                      }
                    />
                  }
                >
                  Favorite
                </Button>
                <Button
                  className="flex-row-item"
                  icon={<AppstoreAddOutlined />}
                  onClick={() => setGalleryModal(props.id)}
                >
                  Gallery
                </Button>
              </div>
              <div>
                <Button
                  className="flex-row-item"
                  icon={<FacebookOutlined style={{ color: "#4267B2" }} />}
                  onClick={() =>
                    urls.openInNewTab(
                      `https://www.facebook.com/sharer/sharer.php?u=https://myartdrive.com/artworks/${data.id}/`
                    )
                  }
                ></Button>
                <Button
                  className="flex-row-item"
                  icon={<TwitterOutlined style={{ color: "#1DA1F2" }} />}
                  onClick={() =>
                    urls.openInNewTab(
                      `https://twitter.com/share/?url=https://myartdrive.com/artworks/${data.id}/`
                    )
                  }
                ></Button>
                <Button
                  className="flex-row-item"
                  icon={<PinterestIcon />}
                  onClick={() =>
                    urls.openInNewTab(
                      `https://pinterest.com/pin/create/button/?url=https://myartdrive.com/artworks/${data.id}/&media=${imageSrc}`
                    )
                  }
                ></Button>
              </div>
            </div>
            <div className="artwork-detail">
              {data.location && (
                <Text className="main-description">
                  Location:{" "}
                  <Text className="sub-description">
                    {formatter.location(data.location)}
                  </Text>
                </Text>
              )}
              {data.dimensions && (
                <Text className="main-description">
                  Dimensions:{" "}
                  <Text className="sub-description">{data.dimensions}</Text>
                </Text>
              )}
              {data.nudity && (
                <Text className="main-description">
                  Nudity:{" "}
                  <Text className="sub-description">Contains Nudity</Text>
                </Text>
              )}
              {data.source && (
                <Text className="main-description">
                  Source:{" "}
                  <Text className="sub-description">{data.source.name}</Text>
                </Text>
              )}
              <Text className="main-description">
                License:{" "}
                <Text className="sub-description">
                  {formatter.license(data.license_type)}
                </Text>
              </Text>
              {data.description && (
                <Paragraph
                  className="sub-description"
                  ellipsis={{ rows: 3, expandable: true, symbol: "more" }}
                  style={{ margin: 0 }}
                >
                  <Text className="main-description">Description:</Text>{" "}
                  {data.description}
                </Paragraph>
              )}
            </div>
            <div style={{ height: "20px" }} />
            {generateTags(data.tags)}
            <div style={{ height: "20px" }} />
            {data.images.length > 0 && (
              <>
                <Space direction="vertical" className="artwork-detail">
                  {data.images
                    .filter((img) => img.height !== null)
                    .map((img, i) => (
                      <Space key={i}>
                        <Text className="main-description">{`${getFileName(
                          img
                        )}:`}</Text>
                        <Button type="primary" onClick={() => download(img)}>
                          Download
                        </Button>
                      </Space>
                    ))}
                </Space>
                <div style={{ height: "20px" }} />
              </>
            )}
            {user && user.admin && (
              <>
                <div className="artwork-detail">
                  <Text className="main-description">
                    Id: <Text className="sub-description">{data.id}</Text>
                  </Text>
                  <Text className="main-description">
                    Uuid: <Text className="sub-description">{data.uuid}</Text>
                  </Text>
                  <Text className="main-description">
                    Published:{" "}
                    <Text className="sub-description">
                      {data.published ? "True" : "False"}
                    </Text>
                  </Text>
                  <Text className="main-description">
                    Public:{" "}
                    <Text className="sub-description">
                      {data.public ? "True" : "False"}
                    </Text>
                  </Text>
                  <Text className="main-description">
                    Upload Date:{" "}
                    <Text className="sub-description">
                      {new Date(data.upload_date).toLocaleString([], {
                        timeStyle: "short",
                        dateStyle: "short",
                      })}
                    </Text>
                  </Text>
                  <Text className="main-description">
                    Status:{" "}
                    <Text className="sub-description">
                      {formatter.status(data.status)}
                    </Text>
                  </Text>
                </div>
                <div style={{ height: "20px" }} />
              </>
            )}
            <ReportModal model="Artwork" object_id={data.id} />
          </div>
        </div>
      )}
      <div style={{ height: "20px" }} />
    </Skeleton>
  );
}
