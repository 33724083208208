import React, { useState, useEffect } from "react";
import { animated, useSpring } from "react-spring";
import {
  Button,
  Modal,
  Form,
  Input,
  Tag,
  Typography,
  message,
  Checkbox,
} from "antd";
import { EditOutlined, FilterOutlined, LeftOutlined } from "@ant-design/icons";
import _ from "lodash";

import api from "../../utility/api";
import urls from "../../utility/urls";
import FilterDropdown from "../layout/FilterDropdown";

const { Text } = Typography;

export default function GalleryEditModal(props) {
  const [visible, setVisible] = useState(false);
  const [filters, setFilters] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (props.data && !_.isEqual(props.data, {}) && !filters) {
      setFilters(props.data.filters);
    }
    // eslint-disable-next-line
  }, [props.data]);

  const overlay = useSpring({
    opacity: drawerVisible ? 1 : 0,
    display: drawerVisible ? "flex" : "none",
  });
  const drawer = useSpring({
    opacity: drawerVisible ? 1 : 0,
    left: drawerVisible ? "0%" : "-100%",
  });

  const getFilterObject = (filterArr) => {
    var obj = {};
    for (var param of filterArr) {
      var name = param.name.replace(/\d/g, "");
      if (name in obj) {
        obj[name] = obj[name] + `,${param.value}`;
      } else {
        obj[name] = param.value;
      }
    }
    return obj;
  };

  const removeFilter = (name) => {
    var tempFilter = filters.filter((obj) => obj.name !== name);
    setFilters(tempFilter);
  };

  const updateFilter = (name, value, display) => {
    var tempFilter;
    if (value === null) {
      // filter has been removed from dropdown
      tempFilter = filters.filter((obj) => obj.name !== name);
      setFilters(tempFilter);
    } else {
      // check if an existing filter exists
      tempFilter = [...filters];
      var filterExists = false;
      for (var obj of tempFilter) {
        if (obj.name === name) {
          // update existing filter
          obj.value = value;
          obj.display = display;
          filterExists = true;
        }
      }
      if (!filterExists) {
        // create a new filter
        tempFilter.push({ name: name, value: value, display: display });
      }
      setFilters(tempFilter);
    }
  };

  const onFinish = () => {
    form.validateFields().then((values) => {
      api
        .patch(urls.gallery(props.data.id), {
          name: values.name,
          public: values.public,
          filters: getFilterObject(filters),
        })
        .then((response) => {
          if (response) {
            message.success("Gallery Updated");
            setVisible(false);
          }
        });
    });
  };

  return (
    <div>
      <Button
        onClick={() => setVisible(true)}
        icon={
          <EditOutlined style={{ fontSize: "22px", verticalAlign: "middle" }} />
        }
      />
      <Modal
        visible={visible}
        onOk={onFinish}
        onCancel={() => setVisible(false)}
      >
        {props.data && (
          <Form
            form={form}
            initialValues={{ name: props.data.name, public: props.data.public }}
            style={{ marginTop: "25px" }}
          >
            <Form.Item name="name" required label="Gallery Name">
              <Input maxLength={128} />
            </Form.Item>
            <Form.Item label="Public" name="public" valuePropName="checked">
              <Checkbox style={{ marginTop: "5px" }} />
            </Form.Item>
          </Form>
        )}
        {props.data.artwork && props.data.artwork.length === 0 && (
          <div>
            <Button
              type="primary"
              style={{ marginBottom: "10px" }}
              onClick={() => setDrawerVisible(true)}
            >
              Edit Filters
            </Button>
            <div style={{ height: "20px" }} />
            {filters &&
              filters.length > 0 &&
              filters.map((obj, i) => (
                <Tag
                  visible={true}
                  key={i}
                  closable
                  onClose={() => removeFilter(obj.name)}
                  style={{ marginBottom: "5px" }}
                >
                  {obj.display}
                </Tag>
              ))}
            {filters && filters.length > 0 && (
              <Button type="link" onClick={() => setFilters([])}>
                Clear All
              </Button>
            )}
          </div>
        )}
        <animated.div
          onClick={() => setVisible(false)}
          className="drawer-overlay"
          style={overlay}
        />
        <animated.div className="drawer background" style={drawer}>
          <Button
            type="primary"
            onClick={() => setDrawerVisible(false)}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              height: "65px",
            }}
          >
            <FilterOutlined style={{ fontSize: "20px" }} />
            <Text className="special-text light">Filters</Text>
            <LeftOutlined style={{ fontSize: "22px" }} />
          </Button>
          <div style={{ overflowY: "auto", width: "100%", height: "100%" }}>
            <FilterDropdown
              title="Full-Text Search"
              name="search"
              type="text"
              default={null}
              filters={filters}
              updateFilter={updateFilter}
            />
            <FilterDropdown
              title="Title"
              name="title"
              type="text"
              default={null}
              filters={filters}
              updateFilter={updateFilter}
            />
            <FilterDropdown
              title="Artist"
              name="artist"
              type="list"
              url={urls.artist()}
              default={[]}
              filters={filters}
              updateFilter={updateFilter}
            />
            <FilterDropdown
              title="Nationality"
              name="nationality"
              type="list"
              url={urls.nationality()}
              default={[]}
              filters={filters}
              updateFilter={updateFilter}
            />
            <FilterDropdown
              title="Sorting"
              name="ordering"
              type="radio"
              default="default"
              options={[
                { title: "Best Match", value: "default" },
                { title: "Most Recent", value: "latest" },
                { title: "Most Popular", value: "popular" },
                { title: "Alphabetical", value: "title" },
              ]}
              filters={filters}
              updateFilter={updateFilter}
            />
            <FilterDropdown
              title="Year"
              name="year"
              type="slider"
              default="0"
              filters={filters}
              updateFilter={updateFilter}
            />
            <FilterDropdown
              title="Location"
              name="location"
              type="list"
              url={urls.location()}
              default={[]}
              filters={filters}
              updateFilter={updateFilter}
            />
            <FilterDropdown
              title="Tags"
              name="tag"
              type="list"
              url={urls.tag()}
              default={[]}
              filters={filters}
              updateFilter={updateFilter}
            />
            <FilterDropdown
              title="Source"
              name="source"
              type="list"
              url={urls.source()}
              default={[]}
              filters={filters}
              updateFilter={updateFilter}
            />
            <FilterDropdown
              title="Nudity"
              name="nudity"
              type="radio"
              default="default"
              options={[
                { title: "Allow Nudity", value: "default" },
                { title: "Exclude Nudity", value: "false" },
              ]}
              filters={filters}
              updateFilter={updateFilter}
            />
          </div>
        </animated.div>
      </Modal>
    </div>
  );
}
