import { BrowserRouter } from "react-router-dom";
import Base from "./router/Base";
import { Provider } from "react-redux";
import SimpleReactLightbox from "simple-react-lightbox";

import store from "./redux/store";

import "./static/css/App.css";
import "./static/css/antd-custom.css";
import { GalleryProvider } from "./wrappers/GalleryContext";
import { LoginProvider } from "./wrappers/LoginContext";

function App() {
  return (
    <Provider store={store}>
      <SimpleReactLightbox>
        <BrowserRouter>
          <LoginProvider>
            <GalleryProvider>
              <Base />
            </GalleryProvider>
          </LoginProvider>
        </BrowserRouter>
      </SimpleReactLightbox>
    </Provider>
  );
}

export default App;
