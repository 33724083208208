import React from "react";
import { useParams } from "react-router-dom";

import ArtistDetail from "../components/details/ArtistDetail";

export default function Artist() {
  let { id } = useParams();

  return <ArtistDetail id={id} addObject={() => {}} removeObject={() => {}} />;
}
