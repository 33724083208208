import React, { useState } from "react";
import { Radio } from "antd";

import ImageCard from "../components/cards/ImageCard";
import ArtistCard from "../components/cards/ArtistCard";
import GalleryCard from "../components/cards/GalleryCard";
import ArtworkDetail from "../components/details/ArtworkDetail";
import ArtistDetail from "../components/details/ArtistDetail";
import GalleryDetail from "../components/details/GalleryDetail";
import ArtMasonry from "../components/layout/ArtMasonry";
import urls from "../utility/urls";

export default function MyFavorites() {
  const [tab, setTab] = useState(1);

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "25px",
        }}
      >
        <Radio.Group
          size="large"
          buttonStyle="solid"
          defaultValue={1}
          onChange={(e) => setTab(e.target.value)}
        >
          <Radio.Button
            style={{ width: "25vw", textAlign: "center" }}
            value={1}
          >
            Artwork
          </Radio.Button>
          <Radio.Button
            style={{ width: "25vw", textAlign: "center" }}
            value={2}
          >
            Artist
          </Radio.Button>
          <Radio.Button
            style={{ width: "25vw", textAlign: "center" }}
            value={3}
          >
            Gallery
          </Radio.Button>
        </Radio.Group>
      </div>
      {tab === 1 && (
        <ArtMasonry
          title="My Favorite Artworks"
          url={urls.favorite(null, { model: "Artwork" })}
          results="object_data"
          objectType={ImageCard}
          modalType={ArtworkDetail}
          gridEdit
          lightbox
        />
      )}
      {tab === 2 && (
        <ArtMasonry
          title="My Favorite Artists"
          url={urls.favorite(null, { model: "Artist" })}
          results="object_data"
          objectType={ArtistCard}
          modalType={ArtistDetail}
          gridEdit
          lightbox
        />
      )}
      {tab === 3 && (
        <ArtMasonry
          title="My Favorite Galleries"
          url={urls.favorite(null, { model: "Gallery" })}
          results="object_data"
          objectType={GalleryCard}
          modalType={GalleryDetail}
          gridEdit
          lightbox
        />
      )}
    </div>
  );
}
