import React from "react";
import { useParams } from "react-router-dom";

import SourceDetail from "../components/details/SourceDetail";

export default function Source() {
  let { id } = useParams();

  return <SourceDetail id={id} addObject={() => {}} removeObject={() => {}} />;
}
