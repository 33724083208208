import React from "react";

const centeredStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
};

export default function TestComponent() {
  return <div style={centeredStyle}></div>;
}
