import React, { useState, useEffect } from "react";
import {
  Typography,
  Card,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Switch,
  Image,
  message,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import ArtSelect from "../layout/ArtSelect";
import urls from "../../utility/urls";

const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

export default function ImageUploadCard(props) {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState(props.file);
  const [edited, setEdited] = useState(false);
  const [form] = Form.useForm();

  const updateValue = (key, val) => {
    setData(Object.assign({}, data, { [key]: val }));
    setEdited(true);
  };

  const updateSelected = (selected) => {
    props.editFile(Object.assign({}, data, { selected: selected }));
  };

  const onFinish = () => {
    form.validateFields().then((values) => {
      props.editFile(Object.assign({}, data, values));
      setVisible(false);
      if (edited || form.isFieldsTouched()) {
        message.success("Artwork Updated");
      }
    });
  };

  useEffect(() => {
    setData(props.file);
  }, [props.file]);

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
    setEdited(false);
    // eslint-disable-next-line
  }, [visible]);

  return (
    <div
      style={data.selected ? { border: "3px solid #fa3c3c" } : null}
      className="card scaler"
    >
      <div
        style={{
          background: "black",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Image
          className="image"
          alt={props.file.title}
          src={props.file.preview}
        />
      </div>
      <Card
        actions={[
          <EditOutlined onClick={() => setVisible(true)} />,
          data.selected ? (
            <CloseOutlined onClick={() => updateSelected(false)} />
          ) : (
            <CheckOutlined onClick={() => updateSelected(true)} />
          ),
          <DeleteOutlined onClick={() => props.removeFile()} />,
        ]}
      >
        <div className="text-box">
          <Text className="main-text">{data.title}</Text>
          <Text className="sub-text">{data.year}</Text>
        </div>
      </Card>
      <Modal
        visible={visible}
        onCancel={onFinish}
        onOk={onFinish}
        title="Edit Artwork"
        footer={null}
      >
        <Form form={form} initialValues={data}>
          <Form.Item name="title" label="Title" required>
            <Input />
          </Form.Item>
          <Form.Item name="year" label="Year">
            <InputNumber />
          </Form.Item>
          <Form.Item label="Location">
            <ArtSelect
              name="location"
              title="Location"
              display="location"
              url={urls.location()}
              initial={data.location}
              search="name"
              updateValue={updateValue}
            />
          </Form.Item>
          {props.mode !== "artist" && (
            <Form.Item label="Artist">
              <ArtSelect
                name="artist"
                title="Artist"
                display="name"
                url={urls.artist(null, { status: "selectable" })}
                initial={data.artist}
                search="name"
                updateValue={updateValue}
                create
              />
            </Form.Item>
          )}
          {props.mode === "admin" && (
            <Form.Item label="Source">
              <ArtSelect
                name="source"
                title="Source"
                display="name"
                url={urls.source()}
                initial={data.source}
                search="name"
                updateValue={updateValue}
              />
            </Form.Item>
          )}
          <Form.Item name="dimensions" label="Dimensions">
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <TextArea />
          </Form.Item>
          {props.mode !== "basic" && (
            <Form.Item name="license_type" label="Licensing" required>
              <Select>
                <Option value="CC0">Creative Commons</Option>
                <Option value="RF">Royalty Free</Option>
                <Option value="P">Public Domain</Option>
                <Option value="E">Editorial Use</Option>
              </Select>
            </Form.Item>
          )}
          <Form.Item
            name="nudity"
            label="Nudity"
            valuePropName="checked"
            required
          >
            <Switch />
          </Form.Item>
          {props.mode !== "basic" && props.mode !== "admin" && (
            <Form.Item
              name="public"
              label="Public"
              valuePropName="checked"
              required
            >
              <Switch />
            </Form.Item>
          )}
          <Form.Item label="Tags">
            <ArtSelect
              name="tags"
              title="Tags"
              display="description"
              url={urls.tag()}
              initial={data.tags}
              search="name"
              updateValue={updateValue}
              many
            />
          </Form.Item>
          <Form.Item
            style={{ margin: 0, minHeight: 0 }}
            shouldUpdate={(prevValues, curValues) =>
              prevValues.public !== curValues.public
            }
          >
            {() => {
              return (
                <div>
                  {!form.getFieldValue("public") &&
                    null ===
                      setTimeout(() => {
                        message.warning(
                          "WARNING: Private Artworks are currently free, but will require payment in a future update",
                          5
                        );
                      }, 1)}
                </div>
              );
            }}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
