import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Radio } from "antd";

import ArtList from "../components/layout/ArtList";
import ReviewItems from "../components/layout/ReviewItems";
import urls from "../utility/urls";

export default function Review(props) {
  const user = useSelector((state) => state.user);
  const [status, setStatus] = useState("S");
  const [model, setModel] = useState(true);
  let history = useHistory();

  useEffect(() => {
    if (!user.admin) {
      history.push("/");
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "15px",
          marginTop: "25px",
        }}
      >
        <Radio.Group
          size="large"
          buttonStyle="solid"
          defaultValue={true}
          onChange={(e) => setModel(e.target.value)}
        >
          <Radio.Button
            style={{ width: "25vw", textAlign: "center" }}
            value={true}
          >
            Artwork
          </Radio.Button>
          <Radio.Button
            style={{ width: "25vw", textAlign: "center" }}
            value={false}
          >
            Artist
          </Radio.Button>
        </Radio.Group>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "15px",
        }}
      >
        <Radio.Group
          size="large"
          buttonStyle="solid"
          defaultValue="S"
          onChange={(e) => setStatus(e.target.value)}
        >
          <Radio.Button value="S">Submitted</Radio.Button>
          <Radio.Button value="I">In-Review</Radio.Button>
          <Radio.Button value="E">Edit Needed</Radio.Button>
          <Radio.Button value="C">Changed</Radio.Button>
          <Radio.Button value="R">Rejected</Radio.Button>
          <Radio.Button value="recent">Recent</Radio.Button>
          <Radio.Button value="unpublished">Unpublished</Radio.Button>
        </Radio.Group>
      </div>
      {model && (
        <ArtList
          url={urls.artwork(null, { status: status })}
          component={ReviewItems}
          title="Pending Reviews"
          options={model}
          results
          pagination
          settings
        />
      )}
      {!model && (
        <ArtList
        url={urls.artist(null, { status: status })}
        component={ReviewItems}
        title="Pending Reviews"
        options={model}
        results
        pagination
        settings
      />
      )}
    </div>
  );
}
