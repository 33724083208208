import React, {
  useState,
  useEffect,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from "react";
import Masonry from "react-masonry-component";
import InfiniteScroll from "react-infinite-scroller";
import { SRLWrapper, useLightbox } from "simple-react-lightbox";
import {
  Modal,
  Typography,
  Button,
  Space,
  Tooltip,
  Spin,
  Pagination,
} from "antd";
import { PlaySquareOutlined, AppstoreAddOutlined } from "@ant-design/icons";
import _ from "lodash";

import api from "../../utility/api";
import FilterDrawer from "./FilterDrawer";
import urls from "../../utility/urls";
import GalleryEditModal from "../modals/GalleryEditModal";
import Settings from "./Settings";

const { Text } = Typography;

const SRLOptions = {
  buttons: {
    showAutoplayButton: true,
    showNextButton: true,
    showPrevButton: true,
    showThumbnailsButton: true,
    showDownloadButton: false,
  },
  caption: {
    showCaption: true,
    captionFontFamily: "Montserrat",
  },
  thumbnails: {
    showThumbnails: true,
  },
};

/*
Props:
- search (obj) - an object of params passed to the filter
- collapseHero (func) - when a filter is applied this parent function is called
- url (Url) - the initial url to call
- results (str) - overrides where the results are stored in a response
- gridEdit (bool) - enable abilty to add/remove objects
- owner (any) - provides child with editing information
- customScroll (str) - the classname of the desired scroll container
- title (str) - page title
- subtitle (str) - page subtitle
- lightbox (bool) - activate lightbox slideshow
- filter (bool) - activate the filter button
- modalType (JSX) - The modal to be loaded when clicking an object
- objectType (JSX) - The object type loaded by masonry
- cardOptions (dict) - A prop that is passed to cards
*/
const ArtMasonry = forwardRef((props, ref) => {
  const [objects, setObjects] = useState([]);
  const [count, setCount] = useState(0);
  const [next, setNext] = useState(null);
  const [query, setQuery] = useState(props.search || {});
  const [galleryCreate, setGalleryCreate] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState(null);
  const [options, setOptions] = useState(null);
  const [masonry, setMasonry] = useState(null);
  const [loading, setLoading] = useState(false);
  const [lightboxFlag, setLightboxFlag] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const { openLightbox } = useLightbox();

  const openModal = (id, options = null) => {
    setSelected(id);
    setOptions(options);
    setVisible(true);
  };

  const updateQuery = (params) => {
    if (!_.isEqual(params, query)) {
      setQuery(params);
    }
  };

  useEffect(() => {
    if (props.search) {
      setQuery(Object.assign({}, query, props.search));
    }
    // eslint-disable-next-line
  }, [props.search]);

  useEffect(() => {
    if (query) {
      var params = new URLSearchParams();
      for (var search of Object.entries(query)) {
        params.set(search[0], search[1]);
      }
      setObjects([]);
      if (
        typeof props.collapseHero === "function" &&
        Object.keys(query).length > 0
      ) {
        props.collapseHero();
      }
      if (next) {
        var current = new URLSearchParams(new URL(next).search);
        current.delete("limit");
        current.delete("offset");
        try {
          current.sort();
          params.sort();
        } catch (error) {
          console.log(error);
        }
        if (current.toString() !== params.toString()) {
          setNext(urls.getUrl(props.url, urls.getParams(params)));
          setPage(1);
        }
      } else {
        setNext(urls.getUrl(props.url, urls.getParams(params)));
        setPage(1);
      }
    }
    // eslint-disable-next-line
  }, [query]);

  const add = () => {
    if (next ? true : false) {
      setLoading(true);
      setNext(null);
      api.get(next).then((response) => {
        if (response) {
          setCount(response.count);
          var temp = [...objects];
          var results = response.results;
          if (props.results) {
            results = results.map((result) => result[props.results]);
          }
          temp = temp.concat(results);
          const set = new Set();
          temp = temp.filter((el) => {
            const duplicate = set.has(el.id);
            set.add(el.id);
            return !duplicate;
          });
          setObjects(temp);
          setLoading(false);
          setNext(response.next);
        }
      });
    }
  };

  const singleAdd = (data) => {
    if (props.gridEdit) {
      var temp = [...objects];
      temp.push(data);
      const set = new Set();
      temp = temp.filter((el) => {
        const duplicate = set.has(el.id);
        set.add(el.id);
        return !duplicate;
      });
      setObjects(temp);
      setCount(count + 1);
    }
  };

  const remove = (id) => {
    if (props.gridEdit) {
      var temp = [...objects];
      setObjects(temp.filter((obj) => obj.id !== id));
      setCount(count - 1);
    }
  };

  const removeSelected = (ids) => {
    var temp = [...objects];
    setObjects(temp.filter((obj) => !ids.includes(obj.id)));
    setCount(count - ids.length);
  };

  const updateObject = (data) => {
    const id = data.id;
    let arr = [...objects];
    arr.find((obj, i) => {
      if (obj.id === id) {
        arr[i] = data;
        setObjects(arr);
        return true;
      }
      return false;
    });
  };

  const changePage = (pg, sz) => {
    var params;
    var search;

    if (pg !== page) {
      params = new URLSearchParams();
      for (search of Object.entries(query)) {
        params.set(search[0], search[1]);
      }
      params.set("offset", pageSize * (pg - 1));
      setPage(pg);
      setObjects([]);
      setNext(urls.getUrl(props.url, urls.getParams(params)));
    }

    if (sz !== pageSize) {
      if (pg === 1) {
        setPageSize(sz);
      } else {
        params = new URLSearchParams();
        for (search of Object.entries(query)) {
          params.set(search[0], search[1]);
        }
        params.set("offset", pageSize * (pg - 1));
        setPage(pg);
        setPageSize(sz);
        setObjects([]);
        setNext(urls.getUrl(props.url, urls.getParams(params)));
      }
    }
  };

  const callbacks = {
    onSlideChange: (obj) => {
      if (!obj.slides.next) {
        setLightboxFlag(true);
      }
    },
  };

  const cards = useMemo(() => {
    return objects.map((artData) => {
      return (
        <div key={artData.id} className="outer-card grid-sizer">
          <props.objectType
            openModal={(id, options) => openModal(id, options)}
            data={artData}
            removeObject={remove}
            updateGrid={() => masonry.performLayout()}
            updateObject={updateObject}
            owner={props.owner ? props.owner : false}
            options={props.cardOptions}
          />
        </div>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objects, props.cardOptions]);

  useImperativeHandle(ref, () => ({
    updateObject,
    removeSelected,
  }));

  useEffect(() => {
    if (lightboxFlag) {
      setLightboxFlag(false);
      add();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lightboxFlag]);

  return (
    <InfiniteScroll
      loadMore={add}
      hasMore={next && objects.slice(0, pageSize).length < pageSize && !loading}
      initialLoad={true}
      threshold={500}
      useWindow={props.customScroll ? false : true}
      getScrollParent={() =>
        document.getElementsByClassName(props.customScroll)[0]
      }
    >
      <SRLWrapper options={SRLOptions} callbacks={callbacks}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div className="text-box">
            {props.title && (
              <Text className="header-text">
                {"search" in query ? `"${query.search}"` : props.title}
              </Text>
            )}
            <Space
              className="flex-row-collapse"
              style={{ display: "flex", alignItems: "self-start" }}
            >
              {props.subtitle && (
                <Text className="sub-text">{props.subtitle}</Text>
              )}
              <Text className="sub-text">{`${count
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Results`}</Text>
            </Space>
          </div>
          <Space
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              float: "right",
              marginRight: "15px",
            }}
          >
            {Object.entries(query).length > 0 && (
              <Button
                onClick={() => setGalleryCreate(true)}
                icon={<AppstoreAddOutlined />}
              >
                Create Gallery from results
              </Button>
            )}
            {typeof props.owner === "object" && props.owner !== null && (
              <Tooltip title="Edit">
                <GalleryEditModal data={props.owner} />
              </Tooltip>
            )}
            {props.lightbox && (
              <Tooltip title="Slideshow">
                <Button
                  onClick={() => openLightbox()}
                  icon={
                    <PlaySquareOutlined
                      style={{ fontSize: "22px", verticalAlign: "middle" }}
                    />
                  }
                />
              </Tooltip>
            )}
            <Settings />
          </Space>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          {props.filter ? (
            <FilterDrawer
              query={query}
              updateQuery={updateQuery}
              galleryCreate={galleryCreate}
              setGalleryCreate={setGalleryCreate}
              options={props.filter}
            />
          ) : (
            <div style={{ height: "50px" }} />
          )}
          <div
            style={{
              width: props.filter ? "25%" : "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              paddingRight: "15px",
            }}
          >
            <Pagination
              total={Math.min(count, 10000)}
              current={page}
              pageSize={pageSize}
              simple
              hideOnSinglePage
              onChange={changePage}
            />
          </div>
        </div>
        <Masonry
          ref={(c) => setMasonry(c)}
          disableImagesLoaded={false}
          updateOnEachImageLoad={true}
          options={{ transitionDuration: "0.2s", percentPosition: true }}
        >
          <div className="grid-sizer"></div>
          {cards.slice(0, pageSize)}
        </Masonry>
        {loading && (
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <Spin size="large" />
          </div>
        )}
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            marginBottom: "25px",
            marginTop: "25px",
          }}
        >
          <Pagination
            total={Math.min(count, 10000)}
            hideOnSinglePage
            pageSize={pageSize}
            current={page}
            onChange={changePage}
          />
        </div>
      </SRLWrapper>
      {visible ? (
        <Modal
          width="85%"
          onCancel={() => setVisible(false)}
          visible={true}
          footer={null}
          bodyStyle={{ padding: 0, paddingTop: "15px" }}
        >
          <props.modalType
            id={selected}
            options={options}
            removeObject={remove}
            addObject={singleAdd}
            updateObject={updateObject}
            customScroll="ant-modal-wrap"
          />
        </Modal>
      ) : null}
    </InfiniteScroll>
  );
});

export default ArtMasonry;
